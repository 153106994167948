import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';

import {
  CampaignAgentDto,
  useCampaignUpdateControllerCampaignUpdateMutation,
} from '@shared/services/apiService/apiService';
import CustomAlert from '@shared/components/alert/alert';
import { AgentListProps, ResponseMsg, SortingAgentColumns } from '@shared/types/admin/agent';

import { useStyles } from '../styles';
import classNames from 'classnames';

import { ReactComponent as AgentIcon } from '@shared/assets/images/agent/agent.svg';

import { ReactComponent as CallIcon } from '@shared/assets/images/agent/call-icon.svg';
import { ReactComponent as CampaignIcon } from '@shared/assets/images/agent/campaign-icon.svg';
import { ReactComponent as DeleteAgentIcon } from '@shared/assets/images/agent/delete-agent-icon.svg';
import { ReactComponent as EditAgentIcon } from '@shared/assets/images/agent/edit-icon.svg';
import { ManageAgent } from './ManageAgent';
import { TestingAgentLivekitPopup } from '@widgets/Popup/TestingAgentLivekitPopup/TestingAgentLivekitPopup';
import { StyledErrorText } from '@shared/components/errorText';
import { CreateCampaignFormValue } from '@features/Admin/Campaign/model/type';
import { useFormContext } from 'react-hook-form';

export const AgentList: React.FC<AgentListProps> = ({
  setLoading,
  onRefresh,
  agentList,
}: AgentListProps) => {
  const [useEditCampaign] = useCampaignUpdateControllerCampaignUpdateMutation();

  const { t } = useTranslation();
  const { classes } = useStyles();
  const theme = useTheme();
  const {
    formState: { errors: formStateErrors },
    setValue,
  } = useFormContext<CreateCampaignFormValue>();

  const [responseMsg, setResponseMsg] = useState<ResponseMsg>({
    message: '',
    type: 'success',
  });

  const [sortConfig, setSortConfig] = useState({
    name: undefined,
    model: undefined,
    'voice.name': undefined,
    'voice.gender': undefined,
    'voice.accent': undefined,
    'mission.humanName': undefined,
    profile: undefined,
  });

  const [agents, setAgents] = useState<CampaignAgentDto[]>(agentList);
  const [showDialog, setShowDialog] = useState(false);
  const [showTestAgent, setShowTestAgent] = useState(false);

  const [deleteId, setDeleteId] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [showManageAgent, setShowManageAgent] = useState('');
  const [selectedAgent, setSelectedAgent] = useState<CampaignAgentDto | null>(null);
  const { campaignId } = useParams();

  useEffect(() => {
    const sortedAgents = [...(agentList || [])].sort((a, b) => {
      const dateA = a.updatedAt ? new Date(a.updatedAt) : new Date(a.createdAt);
      const dateB = b.updatedAt ? new Date(b.updatedAt) : new Date(b.createdAt);
      return dateB.getTime() - dateA.getTime();
    });

    setAgents(sortedAgents);

    if (agents.length) {
      setValue('agents', agents);
    }
  }, [agentList]);

  const handleSort = (field: SortingAgentColumns) => {
    setSortConfig((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));

    const sortedAgents = sortArray(agents, field, sortConfig[field]);
    setAgents(sortedAgents);
  };

  const sortArray = (arr: CampaignAgentDto[], field: SortingAgentColumns, ascending = true) => {
    return [...arr].sort((a: CampaignAgentDto, b: CampaignAgentDto): number => {
      if (field === 'voice.name') {
        if (a.voice!.name! < b.voice!.name!) return ascending ? -1 : 1;
        if (a.voice!.name! > b.voice!.name!) return ascending ? 1 : -1;
        return 0;
      }

      if (field === 'voice.gender') {
        if (a.voice!.gender! < b.voice!.gender!) return ascending ? -1 : 1;
        if (a.voice!.gender! > b.voice!.gender!) return ascending ? 1 : -1;
        return 0;
      }

      if (field === 'voice.accent') {
        if (a.voice!.accent! < b.voice!.accent!) return ascending ? -1 : 1;
        if (a.voice!.accent! > b.voice!.accent!) return ascending ? 1 : -1;
        return 0;
      }

      if (field === 'mission.humanName') {
        if (a.voice!.name! < b.voice!.name!) return ascending ? -1 : 1;
        if (a.voice!.name! > b.voice!.name!) return ascending ? 1 : -1;
        return 0;
      }

      switch (field) {
        case 'name':
          if (a.name < b.name) return ascending ? -1 : 1;
          if (a.name > b.name) return ascending ? 1 : -1;
          return 0;
        case 'profile':
          if ((a?.profile ?? '') < (b?.profile ?? '')) return ascending ? -1 : 1;
          if ((a?.profile ?? '') > (b?.profile ?? '')) return ascending ? 1 : -1;
          return 0;
        default:
          return 0;
      }
    });
  };

  const handleDeleteBtn = (event: React.MouseEvent) => {
    event.stopPropagation();

    setShowDialog(true);

    if (selectedRowId) {
      setDeleteId(selectedRowId);
    }
    setAnchorEl(null);
  };

  const deleteAgent = async () => {
    if (deleteId) {
      setShowDialog(false);
      setLoading(true);
      const response = await useEditCampaign({
        id: campaignId || '',
        campaignUpdateRequestDto: {
          agents: [
            ...agents
              .map((agent) => ({
                ...agent,
                languages: [agent.voice?.accent === 'arabic' ? 'arb' : 'eng'],
              }))
              .filter(({ id }) => id !== deleteId),
          ],
        },
      });
      if ('error' in response) {
        setResponseMsg({
          message: 'Failed to delete agent!',
          type: 'error',
        });
      } else {
        setResponseMsg({
          message: 'Deleted agent successfully.',
          type: 'success',
        });
      }
    }
    setDeleteId('');
    setLoading(false);
  };

  const handleMoreBtn = (event: any, rowId: string) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);

    setSelectedRowId(rowId);
  };

  const handlePopoverAction = (event: React.MouseEvent) => {
    event.stopPropagation();

    handleClosePopover();
  };

  const handleEditAgent = (agent: CampaignAgentDto) => () => {
    setShowManageAgent('edit');
    setSelectedAgent(agent);
    handleClosePopover();
  };

  const handleMakeTestCall = (agent: CampaignAgentDto) => () => {
    setSelectedAgent(agent);
    handleClosePopover();
    setShowTestAgent(true);
  };

  const handleCloseTestAgentModal = () => {
    setShowTestAgent(false);
    setSelectedAgent(null);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box className={classes.agent_header}>
        <Box className={classes.agent_header_title}>
          <Typography fontSize="18px">{t('adminMainPage.agents.title')}</Typography>
          <Typography fontSize="18px" color={theme.palette.text.secondary}>
            ({agents.length})
          </Typography>
        </Box>
        <Box display="flex" gap={1}>
          <Tooltip title={t('adminMainPage.operation.addAgent')} arrow>
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              className={classes.agent_create_button}
              onClick={() => setShowManageAgent('create')}>
              {t('adminMainPage.operation.addAgent')}
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <TableContainer className={classes.agent_table}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: theme.palette.extendedGrey?.lighter,
              height: '48px',
              borderTop: `1px solid ${theme.palette.divider}`,
            }}>
            <TableRow>
              <TableCell onClick={() => handleSort('mission.humanName')}>
                <Box className={classes.agent_table_sort_cell}>
                  <Typography
                    color={theme.palette.text.secondary}
                    variant="h6"
                    className={classNames(
                      classes.campagin_table_th,
                      classes.agent_table_first_cell,
                    )}>
                    {t('adminMainPage.agents.humanName')}
                  </Typography>
                  {sortConfig['mission.humanName'] !== undefined ? (
                    sortConfig['mission.humanName'] ? (
                      <IconArrowDown size="12" />
                    ) : (
                      <IconArrowUp size="12" />
                    )
                  ) : null}
                </Box>
              </TableCell>
              <TableCell onClick={() => handleSort('voice.gender')}>
                <Box className={classes.agent_table_sort_cell}>
                  <Typography
                    color={theme.palette.text.secondary}
                    variant="h6"
                    className={classes.campagin_table_th}>
                    {t('adminMainPage.agents.sex')}
                  </Typography>
                  {sortConfig['voice.gender'] !== undefined ? (
                    sortConfig['voice.gender'] ? (
                      <IconArrowDown size="12" />
                    ) : (
                      <IconArrowUp size="12" />
                    )
                  ) : null}
                </Box>
              </TableCell>
              <TableCell onClick={() => handleSort('name')}>
                <Box className={classes.agent_table_sort_cell}>
                  <Typography
                    color={theme.palette.text.secondary}
                    className={classes.campagin_table_th}
                    variant="h6">
                    {t('adminMainPage.agents.voice')}
                  </Typography>
                  {sortConfig['name'] !== undefined ? (
                    sortConfig['name'] ? (
                      <IconArrowDown size="12" />
                    ) : (
                      <IconArrowUp size="12" />
                    )
                  ) : null}
                </Box>
              </TableCell>
              <TableCell onClick={() => handleSort('voice.accent')}>
                <Box className={classes.agent_table_sort_cell}>
                  <Typography
                    color={theme.palette.text.secondary}
                    className={classes.campagin_table_th}
                    variant="h6">
                    {t('adminMainPage.agents.language')}
                  </Typography>
                  {sortConfig['voice.accent'] !== undefined ? (
                    sortConfig['voice.accent'] ? (
                      <IconArrowDown size="12" />
                    ) : (
                      <IconArrowUp size="12" />
                    )
                  ) : null}
                </Box>
              </TableCell>
              <TableCell onClick={() => handleSort('profile')}>
                <Box className={classes.agent_table_sort_cell}>
                  <Typography
                    color={theme.palette.text.secondary}
                    className={classes.campagin_table_th}
                    variant="h6">
                    {t('adminMainPage.agents.role')}
                  </Typography>
                  {sortConfig['profile'] !== undefined ? (
                    sortConfig['profile'] ? (
                      <IconArrowDown size="12" />
                    ) : (
                      <IconArrowUp size="12" />
                    )
                  ) : null}
                </Box>
              </TableCell>

              <TableCell>
                <Typography
                  variant="h6"
                  color={theme.palette.text.secondary}
                  className={classes.campagin_table_th}
                  align="center">
                  {t('adminMainPage.operation.settings')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {agents?.map((row) => {
              return selectedAgent?.id === row.id && showManageAgent == 'edit' ? (
                <TableRow
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  className={classes.agent_table_row}>
                  <ManageAgent
                    agents={agents}
                    agent={selectedAgent}
                    onClose={() => {
                      setSelectedRowId(null);
                      setShowManageAgent('');
                      onRefresh();
                      setSelectedAgent(null);
                    }}
                  />
                </TableRow>
              ) : (
                <TableRow className={classes.agent_table_row} key={row.id} hover>
                  <TableCell align="left" className={classes.agent_table_first_cell}>
                    <Box display="flex" padding={1} alignItems="center" gap={2}>
                      <AgentIcon />
                      <Typography variant="subtitle1">{row.name}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1">{row?.voice?.gender}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1">{row.voice!.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1">{row.voice?.accent}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1">
                      {row.profile?.name as unknown as string}
                    </Typography>
                  </TableCell>

                  <TableCell className={classes.agent_table_th_advanced_settings} align="center">
                    <Tooltip title="More actions">
                      <IconButton
                        aria-describedby={row.id.toString()}
                        onClick={(event) => handleMoreBtn(event, row.id)}>
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  {selectedRowId === row.id && (
                    <Popover
                      id={row.id.toString()}
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handleClosePopover}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}>
                      <Box className={classes.agent_table_popover}>
                        <IconButton
                          className={classes.agent_table_popover_button}
                          onClick={handleEditAgent(row)}>
                          <EditAgentIcon />
                          <Typography>{t('adminMainPage.operation.edit')}</Typography>
                        </IconButton>

                        <IconButton
                          className={classes.agent_table_popover_button}
                          onClick={handleMakeTestCall(row)}>
                          <CallIcon />
                          <Typography>{t('adminMainPage.operation.makeTestCall')}</Typography>
                        </IconButton>

                        <IconButton
                          className={classes.agent_table_popover_button}
                          onClick={handlePopoverAction}
                          disabled>
                          <CampaignIcon />
                          <Typography>{t('adminMainPage.operation.advancedSettings')}</Typography>
                        </IconButton>

                        <IconButton
                          className={classes.agent_table_popover_button}
                          onClick={handleDeleteBtn}>
                          <DeleteAgentIcon />
                          <Typography color="#FA896B">
                            {t('adminMainPage.operation.delete')}
                          </Typography>
                        </IconButton>
                      </Box>
                    </Popover>
                  )}
                </TableRow>
              );
            })}
            {showManageAgent === 'create' && (
              <TableRow>
                <ManageAgent
                  agents={agents}
                  agent={selectedAgent}
                  onClose={() => {
                    setSelectedRowId(null);
                    setShowManageAgent('');
                    onRefresh();
                    setSelectedAgent(null);
                  }}
                />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {formStateErrors.agents && showManageAgent === '' && (
        <Box sx={{ width: '100%', textAlign: 'center', p: '18px 16px' }}>
          <StyledErrorText sx={{ fontSize: '14px' }}>
            {t('adminMainPage.errors.atLeastOneAgent')}
          </StyledErrorText>
        </Box>
      )}
      <div>
        {responseMsg.message ? (
          <CustomAlert message={responseMsg.message} type={responseMsg.type} />
        ) : (
          ''
        )}
      </div>
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{t('dialog.title3')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t('dialog.content')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setShowDialog(false)}>
            {t('adminMainPage.operation.cancel')}
          </Button>
          <Button onClick={deleteAgent} autoFocus>
            {t('adminMainPage.operation.delete')}
          </Button>
        </DialogActions>
      </Dialog>

      {selectedAgent && (
        <TestingAgentLivekitPopup
          agent={selectedAgent}
          profileId={selectedAgent.profileId || ''}
          showDialogFlag={showTestAgent}
          closeDialog={handleCloseTestAgentModal}
        />
      )}
    </>
  );
};
