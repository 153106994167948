import { tss } from 'tss-react/mui';

export const useStyles = tss.create(({ theme }) => ({
  gridContainer: {
    marginTop: '15px',
    display: 'grid',
    gridGap: '8px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(112px, 1fr))',
    gridAutoRows: '40px',
  },
  dropArea: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      height: '100%',
      backgroundColor: '#ebebeb',
    },
  },
  header_title: {
    display: 'flex',
    alignItems: 'center',
    placeItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
  },
  tableheahder: {
    backgroundColor: theme.palette.extendedGrey?.lighter,
    height: '48px',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  table_row: {
    cursor: 'pointer',
  },
  table_first_cell: {
    fontWeight: 'bold',
    paddingLeft: '16px',
  },
  table_last_cell: {
    color: theme.palette.extendedGrey?.neutral,
    paddingRight: '16px',
  },
  table_sort_cell: {
    color: theme.palette.extendedGrey?.neutral,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 3,
  },
  table_th: {
    flexBasis: 'min-content',
    textWrap: 'nowrap',
    fontSize: 11,
    fontWeight: 600,
  },
  table_popover: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
  },
  table_popover_button: {
    gap: '11px',
    justifyContent: 'start',
  },
}));
