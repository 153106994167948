import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  ListItemAvatar,
  Stack,
  Typography,
} from '@mui/material';
import PhonePausedOutlinedIcon from '@mui/icons-material/PhonePausedOutlined';
import { useTranslation } from 'react-i18next';
import { useRef, useEffect, useState } from 'react';
import { useTerminateConversation } from '@features/Admin/Campaign/hooks/useTerminateConversation';
import { useStyles } from './styles';
import { getCallDuration } from '@pages/Admin/Campaign/ui/utils';
import { RecipientConversationDto } from '@shared/services/apiService/apiService';
import Timer from '@shared/components/timer/timer';
import { STOP_AUTOSCROLL_THRESHOLD_PX } from './constants';
import { useWebSocketConversation } from '@features/Admin/Campaign/hooks/useWebSocketConversation';

interface MonitorConversationListProps {
  recipient: Omit<Partial<RecipientConversationDto>, 'id'> & { id: string };
  closeDialog?: () => void;
  startCallTime?: Date;
  phoneNumber?: string;
  onError?: <T>(error: T) => void;
}

const initialState = {
  answered: false,
  callDuration: '',
  callsStatus: '',
  callCreation: new Date() as Date | null,
};

export const MonitorConversationList: React.FC<MonitorConversationListProps> = ({
  phoneNumber = 'You',
  startCallTime,
  closeDialog,
  recipient,
  onError,
}: MonitorConversationListProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messagesBoxRef = useRef<HTMLDivElement | null>(null);

  const [callDetails, setCallDetails] = useState<typeof initialState>(initialState);

  const { answered, callDuration, callsStatus } = callDetails;

  const recipientId = recipient.id;

  const {
    initialLoading: loading,
    conversationData,
    resetConversationHistory,
  } = useWebSocketConversation(recipientId, onError);

  const { terminate: terminateCall } = useTerminateConversation(recipientId);

  useEffect(() => {
    return () => resetConversationHistory();
  }, [recipientId]);

  useEffect(() => {
    if (recipient) {
      const { status, answeredAt, terminatedAt, startedAt } = recipient;

      let callDuration =
        startedAt && answeredAt && terminatedAt ? getCallDuration(answeredAt, terminatedAt) : null;

      setCallDetails({
        answered: !!answeredAt,
        callDuration: callDuration ?? '',
        callCreation: startCallTime || null,
        callsStatus: status || '',
      });
    }
  }, [recipient, recipientId]);

  useEffect(() => {
    if (!messagesBoxRef.current || !messagesEndRef.current) return;

    const { clientHeight, scrollTop, scrollHeight } = messagesBoxRef.current;
    const isNearBottom = scrollHeight - STOP_AUTOSCROLL_THRESHOLD_PX <= clientHeight + scrollTop;

    if (isNearBottom) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversationData]);

  useEffect(() => {
    if (!messagesEndRef.current) return;

    if (!loading) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loading]);

  const onClickTerminateCall = () => {
    terminateCall();
    closeDialog?.();
  };

  const getDuration = (callDuration: string | React.ReactNode) => (
    <Stack direction="row" gap={1}>
      <Typography color="#7C8FAC" fontWeight="600" fontSize="14px">
        {t('campaignPage.duration')}:
      </Typography>
      <Typography color="#7C8FAC" fontWeight="600" fontSize="14px">
        {callDuration}
      </Typography>
    </Stack>
  );

  return (
    <Grid container className={classes.dialogGridContent} id="dialog-grid-content">
      <Grid item xs={12} mt="20px">
        {callDuration && getDuration(callDuration)}
        {startCallTime &&
          callDetails.callsStatus &&
          getDuration(<Timer startTimestamp={startCallTime} />)}
      </Grid>

      <Grid item xs={12} mt="10px">
        {!loading && conversationData.data.length !== 0 ? (
          <Box className={classes.chatBox}>
            <Box ref={messagesBoxRef} className={classes.chatBoxMessages}>
              {conversationData.data.map((messageItem, index) =>
                messageItem.actor === 'agent' ? (
                  <Box
                    key={index}
                    mb={1}
                    display="flex"
                    alignItems="flex-end"
                    flexDirection="row-reverse">
                    <Box alignItems="flex-end" display="flex" flexDirection={'column'}>
                      <Typography variant="body2" color="grey.400" mb={1}>
                        Agent, {timeAgoCalculation(messageItem.createdAt)}
                      </Typography>
                      <Box
                        mb={1}
                        sx={{
                          p: 1,
                          backgroundColor: 'white',
                          ml: 'auto',
                          maxWidth: '320px',
                        }}>
                        {messageItem.message}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box display="flex" key={index}>
                    <ListItemAvatar>
                      <Avatar
                        src="/src/shared/assets/images/profile/user-1.jpg"
                        sx={{ width: 40, height: 40 }}
                      />
                    </ListItemAvatar>
                    <Box>
                      <Typography variant="body2" color="grey.400" mb={1}>
                        {`${phoneNumber}, ${timeAgoCalculation(messageItem.createdAt)}`}
                      </Typography>

                      <Box
                        mb={2}
                        sx={{
                          p: 1,
                          backgroundColor: 'white',
                          mr: 'auto',
                          maxWidth: '320px',
                        }}>
                        {messageItem.message}
                      </Box>
                    </Box>
                  </Box>
                ),
              )}
              <div ref={messagesEndRef} />
            </Box>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            {loading ? (
              <CircularProgress />
            ) : (
              <Typography sx={{ textAlign: 'center' }}>
                {t('campaignPage.emptyConversationHistory')}
              </Typography>
            )}
          </Box>
        )}
      </Grid>

      <Grid item xs={12} mt="20px">
        {['calling'].includes(callsStatus) && (
          <Button
            onClick={onClickTerminateCall}
            startIcon={<PhonePausedOutlinedIcon />}
            variant="text"
            color="error"
            disabled={!answered}
            fullWidth>
            {t('campaignPage.terminateACall')}
          </Button>
        )}

        {['completed'].includes(callsStatus) && (
          <Button
            onClick={closeDialog}
            startIcon={<PhonePausedOutlinedIcon />}
            variant="text"
            color="success"
            fullWidth>
            {t('campaignPage.callCompletedText')}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

const timeAgoCalculation = (createdAt: string) => {
  const pastTime = new Date(createdAt);
  const now = new Date();
  const timeDifference: number = now.getTime() - pastTime.getTime();

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days >= 1) {
    return `${days} day(s) ago`;
  } else if (hours > 1) {
    return `${hours} hours ago`;
  } else if (hours === 1) {
    return `1 hour ago`;
  } else if (minutes > 1) {
    return `${minutes} minutes ago`;
  } else if (minutes === 1) {
    return `1 minute ago`;
  } else {
    return `just now`;
  }
};
