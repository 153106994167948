import { CreateCampaignFormValue } from '@features/Admin/Campaign/model/type';
import { Grid } from '@mui/material';
import { CampaignFieldsError, FormFields } from '@shared/types/admin/campaign';
import { Controller, useFormContext } from 'react-hook-form';
import CustomFormLabelV2 from '../forms/theme-elements/CustomFormLabelV2';
import { t } from 'i18next';
import { CounterInput } from '../counterInput/counterInput';
import { StyledErrorText } from '../errorText';

interface CampaignRetryFields {
  fields: {
    name: string;
    label: string;
    placeholder: string;
    retryFieldError: string[];
    retryFieldValue: string;
    setRetryFieldValue: React.Dispatch<React.SetStateAction<string>>;
  }[];
  setCampaignFieldError: React.Dispatch<React.SetStateAction<CampaignFieldsError>>;
}

export interface ChangeRetryValueByButtonData {
  value: number;
  name: string;
}

export const CampaignRetryFields = ({ fields, setCampaignFieldError }: CampaignRetryFields) => {
  const {
    control,
    formState: { errors: formStateErrors },
    setValue,
  } = useFormContext<CreateCampaignFormValue>();

  const setValueToForm = (name: string, value: string) => {
    const parsedValue = parseFloat(value);
    const field = fields.find((fieldItem) => fieldItem.name === name);

    if (field) {
      field.setRetryFieldValue(value);
      setCampaignFieldError((prevState) => ({
        ...prevState,
        [name]: [],
      }));
      setValue(name as FormFields, parsedValue);
    }
  };

  const handleOnChangeRetryFieldInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const filteredValue = value.replace(/[^0-9.]/g, '');

    setValueToForm(name, filteredValue);
  };

  const handleOnChangeRetryValueByButton = (data: ChangeRetryValueByButtonData) => {
    const { name, value } = data;
    const filteredValue = value < 0 ? '0' : String(value).replace(/[^0-9.]/g, '');
    setValueToForm(name, filteredValue);
  };

  return (
    <>
      <Grid container spacing={2}>
        {fields.map(({ name, label }: { name: string; label: string }) => (
          <Grid item xs={3} sm={4} key={name}>
            <CustomFormLabelV2 htmlFor={`form-field-${name}`} required>
              {t(label)}
            </CustomFormLabelV2>
            <Controller
              control={control}
              name={name as FormFields}
              render={({ field }) => (
                <CounterInput
                  {...field}
                  id={`form-field-${name}`}
                  retryFieldValue={(field.value as string) || '0'}
                  fieldName={name}
                  control={control}
                  isErrored={Boolean(formStateErrors[name as FormFields])}
                  handleOnChangeRetryFieldInput={(e) => {
                    field.onChange(e.target.value);
                    handleOnChangeRetryFieldInput(e);
                  }}
                  handleOnChangeRetryValueByButton={(e) => {
                    field.onChange(e.value);
                    handleOnChangeRetryValueByButton(e);
                  }}
                />
              )}
            />
            {formStateErrors[name as FormFields] && (
              <StyledErrorText>{t('adminMainPage.errors.fillTheField')}</StyledErrorText>
            )}
          </Grid>
        ))}
      </Grid>
    </>
  );
};
