import { tss } from 'tss-react/mui';

export const useStyles = tss.create(() => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: '30px',
  },
  companies_header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
  },
  companies_table: {
    marginTop: '20px',
  },
  companies_table_row: {
    cursor: 'pointer',
  },
  companies_table_first_cell: {
    paddingLeft: '0px',
  },
  companies_table_sort_cell: {
    cursor: 'pointer',
    display: 'flex',
    gap: 4,
    alignItems: 'center',
  },
  companies_table_th: {
    flexBasis: 'min-content',
  },
  popover: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
  },
  popover_button: {
    gap: '11px',
    justifyContent: 'start',
  },
}));
