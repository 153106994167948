import { Box } from '@mui/material';
import { AgentPage } from '@pages/Admin/Main';
import { CampaignAgentDto } from '@shared/services/apiService/apiService';
import { FC } from 'react';
interface AgentTabProps {
  agents: CampaignAgentDto[];
  isLoading: boolean;
  refetch: () => void;
}

const AgentTab: FC<AgentTabProps> = ({ agents, isLoading, refetch }) => {
  return (
    <Box width="100%" padding={2}>
      <AgentPage agents={agents} isLoading={isLoading} refetch={refetch} />
    </Box>
  );
};

export default AgentTab;
