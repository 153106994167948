import { Box, Chip, Grid, styled, Typography, useTheme } from '@mui/material';
import { IconCircleArrowLeft, IconCircleArrowRight } from '@shared/assets/images/svgs';
import { CampaignTypeEnum } from '@shared/constants/campaign.constants';
import { CampaignType } from '@shared/services/apiService/apiService';
import { firstSymbolToUpperCase } from './utils';

interface BreadCrumbProps {
  subtitle?: string;
  title: string;
  showBackButton?: boolean;
  campaignType?: CampaignType | null;
}

const CampaignTypeChip = styled(Chip)(({ theme }) => ({
  height: '23px',
  marginLeft: '12px',
  fontSize: '12px',
  fontWeight: 600,
  '.MuiChip-label': {
    padding: '0 10px 0 6px',
  },
  '& svg': {
    height: '14px',
    width: '14px',
  },
}));

const BreadcrumbV2 = ({ subtitle, title, campaignType }: BreadCrumbProps) => {
  const theme = useTheme();

  const getCampaignChipLabel = (campaignType: CampaignType) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'nowrap',
          flexDirection: 'row',
        }}
        gap={'4px'}>
        {campaignType === CampaignTypeEnum.OUTBOUND ? (
          <IconCircleArrowLeft />
        ) : (
          <IconCircleArrowRight />
        )}
        {firstSymbolToUpperCase(campaignType)}
      </Box>
    );
  };
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={6}
        lg={8}
        mb={1}
        sx={{
          display: 'flex',
          margin: 0,
          alignItems: 'center',
        }}>
        <Typography
          variant="h4"
          sx={{
            fontSize: '21px',
            fontWeight: 500,
            paddingLeft: '15px',
            color: theme.palette.text.primary,
          }}>
          {title}
          {campaignType && (
            <CampaignTypeChip
              sx={{
                backgroundColor:
                  campaignType === CampaignTypeEnum.OUTBOUND
                    ? theme.palette.secondary.light
                    : theme.palette.error.light,
                color:
                  campaignType === CampaignTypeEnum.OUTBOUND
                    ? theme.palette.primary.main
                    : theme.palette.error.main,
              }}
              label={getCampaignChipLabel(campaignType)}
            />
          )}
        </Typography>
        {subtitle ? (
          <Typography color="textSecondary" variant="h6" fontWeight={400} mt={0.8} mb={0}>
            {subtitle}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default BreadcrumbV2;
