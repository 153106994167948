import { tss } from 'tss-react/mui';

export const useStyles = tss.create(() => ({
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 16px 12px 13px',
    gap: '6px',
    width: '125px',
    height: '40px',
    background: '#F2F6FA',
    borderRadius: '8px',
    flex: 'none',
    order: 1,
    flexGrow: 0,
    color: '#2A3547',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '19.6px',
    letterSpacing: '-2%',
    '&:hover': {
      background: '#E0E6ED',
    },
    '&:focus': {
      outline: '2px solid #A0AEC0',
    },
  },
}));
