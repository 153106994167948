import { createSlice } from '@reduxjs/toolkit';
import { CampaignType } from '@shared/services/apiService/apiService';

export interface BreadCrumbType {
  subtitle?: string;
  items?: any[];
  title: string;
  children?: JSX.Element;
}

type InitStateType = {
  pageTitle: string;
  campaignType?: CampaignType | null;
  titleValue: object;
  path: BreadCrumbType[];
  iShown: boolean;
};

const initialState: InitStateType = {
  pageTitle: 'campaignPage.header.campaign',
  titleValue: {},
  path: [],
  iShown: false,
};

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setBreadcrumb: (state, action) => {
      state.pageTitle = action.payload.pageTitle;
      state.titleValue = action.payload.titleValue;
      state.campaignType = action.payload.campaigntype;
    },
    setBreadcrumbPath: (state, action) => {
      state.path = action.payload.path;
    },
    setIsShownBreadcrumbs: (state, action) => {
      state.iShown = action.payload;
    },
  },
});

export const actions = {
  ...breadcrumbsSlice.actions,
};

export const { reducer } = breadcrumbsSlice;
