// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { useEffect, useState } from 'react';
import {
  Box,
  Menu,
  Avatar,
  Typography,
  Divider,
  Button,
  IconButton,
  Stack,
  BoxProps,
  styled,
  Grid,
  Tooltip,
} from '@mui/material';

import { IconCheck, IconMail } from '@tabler/icons-react';
import WbSunnyTwoToneIcon from '@mui/icons-material/WbSunnyTwoTone';
import DarkModeTwoToneIcon from '@mui/icons-material/DarkModeTwoTone';
import SwipeLeftAltTwoToneIcon from '@mui/icons-material/SwipeLeftAltTwoTone';
import SwipeRightAltTwoToneIcon from '@mui/icons-material/SwipeRightAltTwoTone';
import AspectRatioTwoToneIcon from '@mui/icons-material/AspectRatioTwoTone';
import CallToActionTwoToneIcon from '@mui/icons-material/CallToActionTwoTone';

import { RootState, useAppDispatch, useAppSelector } from '@shared/store';
import { actions } from '@shared/store/ducks';
import ProfileImg from '@shared/assets/images/profile/user-1.jpg';
import { useSignOut } from '@features/Auth/SignOut/hooks/useSignOut';
import { BorderOuter } from '@mui/icons-material';
import { useSignOutSuperAdmin } from '@features/Auth/SignOut/hooks/useSignOutSuperAdmin';
import { useLocation } from 'react-router-dom';
import {
  AdminDto,
  useAdminMeControllerAdminMeQuery,
  useGetCompanyControllerGetCompanyQuery,
  UserDto,
  useUserMeControllerUserMeQuery,
} from '@shared/services/apiService/apiService';
import { COMPANIES_BRANDING } from '@shared/constants/branding';
import { useTranslation } from 'react-i18next';

interface colors {
  id: number;
  bgColor: string;
  disp?: string;
}

const Profile = () => {
  const { pathname } = useLocation();
  const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf('/'));
  const isSuperAdminPage = pathWithoutLastPart.includes('super-admin');

  const { t } = useTranslation();

  const [anchorEl2, setAnchorEl2] = useState(null);

  const onAdminLogout = useSignOut();
  const onSuperAdminLogout = useSignOutSuperAdmin();

  const useMe = isSuperAdminPage
    ? useAdminMeControllerAdminMeQuery
    : useUserMeControllerUserMeQuery;

  const { data, refetch } = useMe();

  const {
    data: profileCompany,
    isLoading,
    isSuccess,
  } = useGetCompanyControllerGetCompanyQuery(
    { id: (data as unknown as UserDto)?.companyId },
    { skip: !(data as unknown as UserDto)?.companyId },
  );

  useEffect(() => {
    dispatch(actions.auth.setIsLoading(isLoading));
  }, [isLoading]);

  const updateCompanyBranding = () => {
    if (!isLoading && isSuccess) {
      const companyName = profileCompany?.name as keyof typeof COMPANIES_BRANDING;

      const formattedCompanyName = companyName
        ?.replace(/_/g, ' ')
        .replace(/\b\w/g, (char) => char.toUpperCase());

      dispatch(
        actions.auth.setAuthenticatedUserCompany({
          logo: profileCompany.logo,
          title: formattedCompanyName,
        }),
      );
    }
  };

  useEffect(() => {
    updateCompanyBranding();
  }, [profileCompany]);

  useEffect(() => {
    refetch();
  }, []);

  const handleClick2 = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const onLogout = isSuperAdminPage ? onSuperAdminLogout : onAdminLogout;

  const customizer = useAppSelector((state: RootState) => state.customizer);

  const dispatch = useAppDispatch();

  const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
    boxShadow: theme.shadows[8],
    padding: '20px',
    cursor: 'pointer',
    justifyContent: 'center',
    display: 'flex',
    transition: '0.1s ease-in',
    border: '1px solid rgba(145, 158, 171, 0.12)',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  }));

  const thColors: colors[] = [
    {
      id: 1,
      bgColor: '#5D87FF',
      disp: 'BLUE_THEME',
    },
    {
      id: 2,
      bgColor: '#0074BA',
      disp: 'AQUA_THEME',
    },
    {
      id: 3,
      bgColor: '#763EBD',
      disp: 'PURPLE_THEME',
    },
    {
      id: 4,
      bgColor: '#0A7EA4',
      disp: 'GREEN_THEME',
    },
    {
      id: 5,
      bgColor: '#01C0C8',
      disp: 'CYAN_THEME',
    },
    {
      id: 6,
      bgColor: '#FA896B',
      disp: 'ORANGE_THEME',
    },
  ];

  return (
    <Box>
      <IconButton
        size="large"
        aria-label="show 11 new notifications"
        color="inherit"
        aria-controls="msgs-menu"
        aria-haspopup="true"
        sx={{
          ...(typeof anchorEl2 === 'object' && {
            color: 'primary.main',
          }),
        }}
        onClick={handleClick2}>
        <Avatar
          src={ProfileImg}
          alt={ProfileImg}
          sx={{
            width: 35,
            height: 35,
          }}
        />
      </IconButton>
      {/* ------------------------------------------- */}
      {/* Message Dropdown */}
      {/* ------------------------------------------- */}
      <Menu
        id="msgs-menu"
        anchorEl={anchorEl2}
        keepMounted
        open={Boolean(anchorEl2)}
        onClose={handleClose2}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        sx={{
          '& .MuiMenu-paper': {
            width: '360px',
            p: 4,
          },
        }}>
        <Typography variant="h4">User Profile</Typography>
        <Box py={3}>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography variant="subtitle2" color="textPrimary" fontSize="16px" fontWeight={600}>
              {(data as UserDto | AdminDto)?.role === 'manager' ? (
                <>
                  <Typography variant="body1" color="textPrimary" fontSize="16px" fontWeight={600}>
                    {t('superAdminMainPage.profile.superAdmin')}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    display="flex"
                    alignItems="center"
                    gap={1}>
                    <IconMail width={15} height={15} />
                    {data?.email}
                  </Typography>
                </>
              ) : (
                <>
                  {(data as unknown as UserDto)?.firstName} {(data as unknown as UserDto)?.lastName}{' '}
                  <span>(Admin)</span>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    display="flex"
                    alignItems="center"
                    gap={1}>
                    <IconMail width={15} height={15} />
                    {data?.email}
                  </Typography>
                </>
              )}
            </Typography>
          </Stack>
        </Box>
        <Divider />
        <Typography mt={2} variant="h4">
          Settings
        </Typography>
        <Box pt={3}>
          {/* ------------------------------------------- */}
          {/* ------------ Dark light theme setting ------------- */}
          {/* ------------------------------------------- */}
          <Typography variant="h6" gutterBottom>
            Theme Option
          </Typography>
          <Stack direction={'row'} gap={2} my={2}>
            <StyledBox
              onClick={() => dispatch(actions.customizer.setDarkMode('light'))}
              display="flex"
              gap={1}>
              <WbSunnyTwoToneIcon
                color={customizer.activeMode === 'light' ? 'primary' : 'inherit'}
              />
              Light
            </StyledBox>
            <StyledBox
              onClick={() => dispatch(actions.customizer.setDarkMode('dark'))}
              display="flex"
              gap={1}>
              <DarkModeTwoToneIcon
                color={customizer.activeMode === 'dark' ? 'primary' : 'inherit'}
              />
              Dark
            </StyledBox>
          </Stack>
        </Box>
        <Box>
          {/* ------------------------------------------- */}
          {/* ------------ RTL theme setting -------------*/}
          {/* ------------------------------------------- */}
          <Typography variant="h6" gutterBottom>
            Theme Direction
          </Typography>
          <Stack direction={'row'} gap={2} my={2}>
            <StyledBox
              onClick={() => dispatch(actions.customizer.setDir('ltr'))}
              display="flex"
              gap={1}>
              <SwipeLeftAltTwoToneIcon
                color={customizer.activeDir === 'ltr' ? 'primary' : 'inherit'}
              />{' '}
              LTR
            </StyledBox>
            <StyledBox
              onClick={() => dispatch(actions.customizer.setDir('rtl'))}
              display="flex"
              gap={1}>
              <SwipeRightAltTwoToneIcon
                color={customizer.activeDir === 'rtl' ? 'primary' : 'inherit'}
              />{' '}
              RTL
            </StyledBox>
          </Stack>
        </Box>
        <Box>
          {/* ------------------------------------------- */}
          {/* ------------ Theme Color setting ------------- */}
          {/* ------------------------------------------- */}
          <Typography variant="h6" gutterBottom>
            Theme Colors
          </Typography>
          <Grid container spacing={2}>
            {thColors.map((thcolor) => (
              <Grid item xs={4} key={thcolor.id}>
                <StyledBox onClick={() => dispatch(actions.customizer.setTheme(thcolor.disp))}>
                  <Tooltip title={`${thcolor.disp}`} placement="top">
                    <Box
                      sx={{
                        backgroundColor: thcolor.bgColor,
                        width: '25px',
                        height: '25px',
                        borderRadius: '60px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        color: 'white',
                      }}
                      aria-label={`${thcolor.bgColor}`}>
                      {customizer.activeTheme === thcolor.disp ? <IconCheck width={13} /> : ''}
                    </Box>
                  </Tooltip>
                </StyledBox>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box pt={2}>
          {/* ------------------------------------------- */}
          {/* ------------ Layout Boxed / Full ------------- */}
          {/* ------------------------------------------- */}
          <Typography variant="h6" gutterBottom>
            Container Option
          </Typography>
          <Stack direction={'row'} gap={2} my={2}>
            <StyledBox
              onClick={() => dispatch(actions.customizer.toggleLayout('boxed'))}
              display="flex"
              gap={1}>
              <CallToActionTwoToneIcon
                color={customizer.isLayout === 'boxed' ? 'primary' : 'inherit'}
              />
              Boxed
            </StyledBox>
            <StyledBox
              onClick={() => dispatch(actions.customizer.toggleLayout('full'))}
              display="flex"
              gap={1}>
              <AspectRatioTwoToneIcon
                color={customizer.isLayout === 'full' ? 'primary' : 'inherit'}
              />
              Full
            </StyledBox>
          </Stack>
        </Box>
        <Box>
          <Typography variant="h6" gutterBottom>
            Card With
          </Typography>
          <Stack direction={'row'} gap={2} my={2}>
            <StyledBox
              onClick={() => dispatch(actions.customizer.setCardShadow(false))}
              display="flex"
              gap={1}>
              <BorderOuter color={!customizer.isCardShadow ? 'primary' : 'inherit'} />
              Border
            </StyledBox>
            <StyledBox
              onClick={() => dispatch(actions.customizer.setCardShadow(true))}
              display="flex"
              gap={1}>
              <CallToActionTwoToneIcon color={customizer.isCardShadow ? 'primary' : 'inherit'} />
              Shadow
            </StyledBox>
          </Stack>
        </Box>
        <Divider />
        <Box mt={2}>
          <Button variant="outlined" color="primary" onClick={onLogout} fullWidth>
            Logout
          </Button>
        </Box>
      </Menu>
    </Box>
  );
};

export default Profile;
