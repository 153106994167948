import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useGetUsageControllerGetUsageQuery } from '@shared/services/apiService/apiService';

const useStyles = makeStyles()((theme) => ({
  tableContainer: {
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[3],
    marginBottom: theme.spacing(4),
    overflow: 'hidden',
  },
  tableHeadCell: {
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tableCell: {
    color: theme.palette.text.secondary,
  },
}));

export const MonitoringTokensPage: React.FC = () => {
  const { classes } = useStyles();
  const { data, error, refetch, isLoading } = useGetUsageControllerGetUsageQuery();

  useEffect(() => {
    if (error && 'status' in error && error.status === 401) {
      refetch();
    }
  }, [error]);

  if (isLoading && !data) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!data) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">No data available</Typography>
      </Box>
    );
  }

  const { ai, stt, tts } = data;

  const renderTable = (title: string, dataset: any, period: { start: string; end: string }) => (
    <Box>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h4" color="textSecondary" gutterBottom>
        Period: {period.start} to {period.end}
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {Object.keys(dataset[0] || {}).map((key) => (
                <TableCell key={key} className={classes.tableHeadCell}>
                  {key.replace(/([A-Z])/g, ' $1').toUpperCase()}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataset.map((item: any, index: number) => (
              <TableRow key={index} className={classes.tableRow}>
                {Object.values(item).map((value, cellIndex) => (
                  <TableCell key={cellIndex} className={classes.tableCell}>
                    {typeof value === 'number' ? value.toLocaleString() : String(value)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <Box>
      {stt?.data && renderTable('Deepgram Statistics (STT)', stt.data, stt.period)}

      {ai?.data && renderTable('ChatGPT Statistics', ai.data, ai.period)}

      {tts?.data && (
        <Box>
          <Typography variant="h5" gutterBottom>
            Eleven Labs Statistics (TTS)
          </Typography>
          <Typography variant="h4" color="textSecondary" gutterBottom>
            Period: {tts.period.start} to {tts.period.end}
          </Typography>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(tts.data).map((key) => (
                    <TableCell key={key} className={classes.tableHeadCell}>
                      {key.replace(/([A-Z])/g, ' $1').toUpperCase()}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.tableRow}>
                  {Object.values(tts.data).map((value, index) => (
                    <TableCell key={index} className={classes.tableCell}>
                      {typeof value === 'number' ? value.toLocaleString() : value}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default MonitoringTokensPage;
