export const COLUMN_NAMES = {
  ID: 'ID',
  EMAIL: 'Email',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  ROLE: 'Role',
  CREATED_AT: 'Created At',
  UPDATED_AT: 'Updated At',
  ACTIONS: 'Actions',
};
