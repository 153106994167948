import { CampaignStatus } from '@pages/Admin/Main/Campaign/ui/CampaignStatusButton';
import { CallScheduleDto, CampaignDto } from '@shared/services/apiService/apiService';
import { useRef, useState } from 'react';
import { StartStopContext } from './useStartStop.context';

export const StartStopProvider = ({ children }: { children: React.ReactNode }) => {
  const startStopFunctionRef = useRef<
    | ((
        id: string,
        callSchedule: CallScheduleDto | undefined,
        campaignStatus: CampaignStatus,
      ) => Promise<void>)
    | null
  >(null);
  const [campaignContext, setCampaignContext] = useState<CampaignDto | null>(null);
  const [isDisabledCampaignRun, setIsDisabledCampaignRun] = useState<boolean>(false);

  const setStartStopFunction = (
    fn: (
      id: string,
      callSchedule: CallScheduleDto | undefined,
      campaignStatus: CampaignStatus,
    ) => Promise<void>,
  ) => {
    startStopFunctionRef.current = fn;
  };

  const callStartStopFunction = async (
    id: string,
    callSchedule: CallScheduleDto | undefined,
    campaignStatus: CampaignStatus,
  ) => {
    if (startStopFunctionRef.current) {
      await startStopFunctionRef.current(id, callSchedule, campaignStatus);
    }
  };

  return (
    <StartStopContext.Provider
      value={{
        setStartStopFunction,
        callStartStopFunction,
        setCampaignContext,
        campaignContext,
        isDisabledCampaignRun,
        setIsDisabledCampaignRun,
      }}>
      {children}
    </StartStopContext.Provider>
  );
};
