import { FC } from 'react';
import { styled, Container, Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from './vertical/header/Header';
import SidebarV2 from './vertical/sidebar/SidebarV2';
import Navigation from './horizontal/navbar/Navigation';
import HorizontalHeader from './horizontal/header/Header';
import { RootState, useAppSelector } from '@shared/store';
import { StartStopProvider } from '@features/Admin/Campaign/contexts/startstop.provider';
import { ToastContainer } from 'react-toastify';

const MainWrapper = styled('div')(() => ({
  display: 'flex',
  minHeight: '100vh',
  width: '100%',
}));

const PageWrapper = styled('div')(() => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  zIndex: 1,
  width: '100%',
  backgroundColor: 'transparent',
  overflow: 'auto',
  height: '100vh',
}));

export const FullLayout: FC = () => {
  const customizer = useAppSelector((state: RootState) => state.customizer);

  return (
    <StartStopProvider>
      <MainWrapper>
        {/* ------------------------------------------- */}
        {/* Sidebar */}
        {/* ------------------------------------------- */}
        {customizer.isHorizontal ? '' : <SidebarV2 />}
        {/* ------------------------------------------- */}
        {/* Main Wrapper */}
        {/* ------------------------------------------- */}
        <PageWrapper
          className="page-wrapper"
          sx={{
            ...(customizer.isCollapse && { ml: `${customizer.MiniSidebarWidth}px` }),
          }}>
          {/* ------------------------------------------- */}
          {/* Header */}
          {/* ------------------------------------------- */}
          {customizer.isHorizontal ? <HorizontalHeader /> : <Header />}
          {/* PageContent */}
          {customizer.isHorizontal ? <Navigation /> : ''}
          <Container
            sx={{
              height: '100%',
              maxWidth: customizer.isLayout === 'boxed' ? 'lg' : '100%!important',
            }}>
            {/* ------------------------------------------- */}
            {/* PageContent */}
            {/* ------------------------------------------- */}

            <Box
              sx={{
                minHeight: 'calc(100vh - 170px)',
                height: '100%',
                '> div': { height: '100%' },
              }}>
              <Outlet />
            </Box>

            {/* ------------------------------------------- */}
            {/* End Page */}
            {/* ------------------------------------------- */}
          </Container>
          {/* <Customizer /> */}
        </PageWrapper>
      </MainWrapper>
      <ToastContainer />
    </StartStopProvider>
  );
};
