export const SidebarTabNames = {
  EDIT_CAMPAIGN: 'editCampaign',
  GENERAL: 'general',
  AGENT: 'agent',
  PHONE_NUMBERS: 'phoneNumbers',
  END_CALL: 'endCall',
  ONGOING_CALLS: 'ongoingCalls',
};

export const SidebarSubTabFieldsMap: Record<string, string> = {
  title: SidebarTabNames.GENERAL,
  retryIntervalInMinutes: SidebarTabNames.GENERAL,
  retryMaxNumber: SidebarTabNames.GENERAL,
  retriesPerDay: SidebarTabNames.GENERAL,
  callSchedule: SidebarTabNames.GENERAL,
  agents: SidebarTabNames.AGENT,
  leadList: SidebarTabNames.PHONE_NUMBERS,
  followUpTemplate: SidebarTabNames.END_CALL,
};

// postponed time in ms before sending update campaign request
export const DEBOUNCED_SEND_INTERVAL = 2000;
