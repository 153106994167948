import React from 'react';
import { Typography, IconButton, Stack, useTheme } from '@mui/material';
import { CampaignDto } from '@shared/services/apiService/apiService';
import { IconActionPlay, IconActionStop } from '@shared/assets/images/svgs';
import { getCampaignStatus } from './utils';
import i18n from '@shared/utils/i18n';
import ActionTableButton from '@shared/components/actionTableButton/actionTableButton';

export enum CampaignStatus {
  NotStarted = 'not-started',
  Dialing = 'dialing',
  Stopping = 'stopping',
}

interface CampaignStatusButtonProps {
  campaign?: CampaignDto | null;
  disabled?: boolean;
  onClick?: (id: string, campaignStatus: CampaignStatus) => void;
  statusLabels?: Record<string, string>;
}

const DEFAULT_STATUS_MAPPINGS: Record<string, string> = {
  stopText: 'Stop',
  startText: i18n.t('adminMainPage.operation.start'),
  stop: i18n.t('adminMainPage.operation.stop'),
  stopping: i18n.t('adminMainPage.operation.stopping'),
};

const getCampaignStatusFragment = (
  campaignStatus: CampaignStatus,
  { stoppingText } = DEFAULT_STATUS_MAPPINGS,
  disabled: boolean = false,
) => {
  const theme = useTheme();
  if (campaignStatus === CampaignStatus.Dialing) {
    return (
      <ActionTableButton>
        <IconActionStop />
      </ActionTableButton>
    );
  } else if (campaignStatus === CampaignStatus.NotStarted) {
    return (
      <ActionTableButton>
        <IconActionPlay />
      </ActionTableButton>
    );
  } else if (campaignStatus === CampaignStatus.Stopping) {
    return <Typography fontWeight="bold">{stoppingText}</Typography>;
  }
};

const CampaignStatusButton: React.FC<CampaignStatusButtonProps> = ({
  campaign,
  disabled,
  onClick,
  statusLabels = {},
}) => {
  const campaignStatus = getCampaignStatus(campaign?.campaignRun);

  return (
    <IconButton
      size="small"
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(campaign?.id as string, campaignStatus);
      }}>
      <Stack alignItems="center" direction="row" gap={1}>
        {getCampaignStatusFragment(campaignStatus, statusLabels, disabled)}
      </Stack>
    </IconButton>
  );
};

export default CampaignStatusButton;
