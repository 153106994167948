import { CreateCampaignFormValue } from '@features/Admin/Campaign/model/type';
import { Box, styled, TextField, useTheme } from '@mui/material';
import { IconMinus, IconPlus } from '@shared/assets/images/svgs';
import { toNumber } from 'lodash';
import React from 'react';
import { Control } from 'react-hook-form';
import { ChangeRetryValueByButtonData } from '../campaignRetryFields/CampaignRetryFields';

interface CounterInputProps {
  id: string;
  retryFieldValue: string;
  fieldName: string;
  isErrored: boolean;
  control: Control<CreateCampaignFormValue, any>;
  handleOnChangeRetryFieldInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnChangeRetryValueByButton: (data: ChangeRetryValueByButtonData) => void;
}

const BoxStyled = styled(Box)(() => ({
  cursor: 'pointer',
  color: 'inherit',
  display: 'flex',
  flexDirection: 'row',
  border: '1px solid #DFE5EF',
}));

const WrappedIcon = styled(BoxStyled)(({ theme }) => ({
  backgroundColor: 'transparent',
  display: 'flex',
  alignItems: 'center',
  padding: '14px',
  border: 0,

  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    transition: '0.1s ease-in',
  },
}));

const TextFieldStyled = styled(TextField)(() => ({
  border: '0',
  input: {
    textAlign: 'center',
  },
  fieldset: {
    border: 0,
  },
}));

export const CounterInput: React.FC<CounterInputProps> = ({
  id,
  retryFieldValue,
  fieldName,
  isErrored,
  handleOnChangeRetryFieldInput,
  handleOnChangeRetryValueByButton,
}) => {
  const theme = useTheme();
  return (
    <BoxStyled
      sx={{
        ...(isErrored
          ? {
              borderColor: `${theme.palette.error.main} !important`,
            }
          : {}),
      }}>
      <WrappedIcon
        onClick={() => {
          handleOnChangeRetryValueByButton({
            value: toNumber(retryFieldValue) - 1,
            name: fieldName,
          });
        }}>
        <IconMinus />
      </WrappedIcon>
      <TextFieldStyled
        id={id}
        onChange={handleOnChangeRetryFieldInput}
        name={fieldName}
        type="number"
        value={retryFieldValue}
      />
      <WrappedIcon
        onClick={() => {
          handleOnChangeRetryValueByButton({
            value: toNumber(retryFieldValue) + 1,
            name: fieldName,
          });
        }}>
        <IconPlus />
      </WrappedIcon>
    </BoxStyled>
  );
};
