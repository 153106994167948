import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
  useTheme,
} from '@mui/material';
import { IconChevronDown16 } from '@shared/assets/images/svgs';
import CustomAlert from '@shared/components/alert/alert';
import { CampaignRetryFields } from '@shared/components/campaignRetryFields/CampaignRetryFields';
import CustomFormLabelV2 from '@shared/components/forms/theme-elements/CustomFormLabelV2';
import CustomTextField from '@shared/components/forms/theme-elements/CustomTextField';
import { initialSchedule } from '@shared/constants/initialSchedule';
import { CallScheduleRequestDto, CampaignDto } from '@shared/services/apiService/apiService';
import { CampaignFieldsError } from '@shared/types/admin/campaign';
import { ScheduleSelect } from '@widgets/Popup/Schedule/ScheduleSelect';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { StyledErrorText } from '@shared/components/errorText';
import { CreateCampaignFormValue } from '@features/Admin/Campaign/model/type';
import { CampaignTypeEnum } from '@shared/constants/campaign.constants';

interface GeneralTabProps {
  campaign?: CampaignDto | null;
}

const BorderedGrid = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.extendedGrey?.neutralLight}`,
  borderRadius: '8px',
  marginBottom: '20px',
  padding: '20px',
}));

const GeneralTab: React.FC<GeneralTabProps> = ({ campaign }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    control,
    formState: { errors: formStateErrors },
    setValue,
  } = useFormContext<CreateCampaignFormValue>();

  const [campaignFieldError, setCampaignFieldError] = useState<CampaignFieldsError>({
    retryIntervalInMinutes: [],
    retryMaxNumber: [],
    retriesPerDay: [],
    editErrorMessage: '',
  });

  const [schedule, setSchedule] = useState<CallScheduleRequestDto>(initialSchedule);
  const [retryMaxNumberValue, setRetryMaxNumberValue] = useState('');
  const [retriesPerDayValue, setRetriesPerDayValue] = useState('');
  const [retryIntervalInMinutesValue, setRetryIntervalInMinutesValue] = useState('');

  useEffect(() => {
    if (campaign && campaign.callSchedule) {
      setRetryMaxNumberValue(campaign.retryMaxNumber?.toString() || '0');
      setRetriesPerDayValue(campaign.retriesPerDay?.toString() || '0');
      setRetryIntervalInMinutesValue(campaign.retryIntervalInMinutes?.toString() || '0');
      setSchedule(campaign.callSchedule);
    }
  }, [campaign]);

  const setCallSchedule = (value: CallScheduleRequestDto) => {
    setSchedule(value);
    setValue('callSchedule', value);
  };

  let fields = [
    {
      name: 'retryMaxNumber',
      label: t('campaignPage.overallRetryAttempt'),
      placeholder: t('campaignPage.overallRetryAttemptPlaceholder'),
      retryFieldError: campaignFieldError.retryMaxNumber || [],
      retryFieldValue: retryMaxNumberValue,
      setRetryFieldValue: setRetryMaxNumberValue,
    },
    {
      name: 'retriesPerDay',
      label: t('campaignPage.retryPerDay'),
      placeholder: t('campaignPage.retryPerDayPlaceholder'),
      retryFieldError: campaignFieldError.retriesPerDay || [],
      retryFieldValue: retriesPerDayValue,
      setRetryFieldValue: setRetriesPerDayValue,
    },
    {
      name: 'retryIntervalInMinutes',
      label: t('campaignPage.retryDelay'),
      placeholder: t('campaignPage.retryDelayPlaceholder'),
      retryFieldError: campaignFieldError.retryIntervalInMinutes || [],
      retryFieldValue: retryIntervalInMinutesValue,
      setRetryFieldValue: setRetryIntervalInMinutesValue,
    },
  ];

  return (
    <>
      <form style={{ width: '100%' }}>
        <Grid
          container
          sx={{
            margin: '30px',
            flexDirection: 'column',
          }}>
          <BorderedGrid item xs={10} sm={8}>
            <CustomFormLabelV2
              sx={{
                mt: 0,
              }}
              htmlFor="campaign-title"
              required>
              {t('campaignPage.campaignName')}
            </CustomFormLabelV2>
            <Controller
              control={control}
              name="title"
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    field.onChange(e.target.value);
                  }}
                  id="campaign-title"
                  sx={{
                    ...(formStateErrors.title
                      ? {
                          '& fieldset': {
                            borderColor: `${theme.palette.error.main} !important`,
                          },
                        }
                      : {}),
                  }}
                  variant="outlined"
                  value={field.value}
                  required
                />
              )}
            />
            {formStateErrors.title && (
              <StyledErrorText>{t('adminMainPage.errors.fillTheField')}</StyledErrorText>
            )}
          </BorderedGrid>
          {campaign?.type === CampaignTypeEnum.OUTBOUND && (
            <BorderedGrid item xs={10} sm={8}>
              <CampaignRetryFields fields={fields} setCampaignFieldError={setCampaignFieldError} />
            </BorderedGrid>
          )}
          {campaign?.type === CampaignTypeEnum.OUTBOUND && (
            <Grid item xs={10} sm={8} sx={{ pb: 2 }}>
              <Accordion
                sx={{
                  boxShadow: 'unset',
                }}>
                <AccordionSummary
                  expandIcon={<IconChevronDown16 />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    padding: '0px',
                    justifyContent: 'flex-start',
                    '& .MuiAccordionSummary-content': {
                      flexGrow: 'unset',
                    },
                  }}>
                  <Typography
                    component="span"
                    sx={{
                      paddingRight: '6px',
                      color: theme.palette.primary.main,
                    }}>
                    {t('campaignPage.changeSchedule')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: '0px',
                    display: 'block',
                  }}>
                  <ScheduleSelect schedule={schedule} setSchedule={setCallSchedule} />
                  {formStateErrors.callSchedule && (
                    <StyledErrorText>{t('adminMainPage.errors.fillTheField')}</StyledErrorText>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
        </Grid>
      </form>
      {campaignFieldError.editErrorMessage ? (
        <CustomAlert message={campaignFieldError.editErrorMessage} type={'error'} />
      ) : (
        ''
      )}
    </>
  );
};

export default GeneralTab;
