import React, { useState } from 'react';

import { CampaignAgentDto } from '@shared/services/apiService/apiService';
import { AgentList } from './components/AgentList';
import PageContainer from '@shared/components/container/PageContainer';
import Spinner from '@shared/components/spinner/Spinner';

import { useAppSelector } from '@shared/store';
import AgentProductDetailsInput from './components/AgentProductDetailsInput';
import AddAgentFilesInput from './components/AddAgentFilesInput';
import { Box } from '@mui/material';

interface AgentPageProps {
  isLoading: boolean;
  refetch: () => void;
  agents: CampaignAgentDto[];
}

export const AgentPage: React.FC<AgentPageProps> = ({ agents: agentList, refetch, isLoading }) => {
  const currentCompany = useAppSelector((state) => state.auth.authenticatedUserCompany);

  const [loadingFlag, setLoadingFlag] = useState(false);

  const setLoading = async (flag: boolean) => {
    await refetch();
    setLoadingFlag(flag);
  };

  if (isLoading || loadingFlag) {
    return <Spinner />;
  }

  return (
    <>
      <PageContainer title={currentCompany?.title} description={currentCompany?.title}>
        <AgentList
          onRefresh={refetch}
          setLoading={setLoading}
          agentList={agentList ? agentList : []}
        />
        <Box mt={4}>
          <AgentProductDetailsInput />
          <AddAgentFilesInput />
        </Box>
      </PageContainer>
    </>
  );
};
