import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { Typography, Modal, Box, TextField, Button, Tabs, Tab } from '@mui/material';
import {
  useEditUserControllerEditUserMutation,
  useGetUsersListControllerGetUsersListQuery,
} from '@shared/services/apiService/apiService';
import { SuperAdminAuthenticatedPath } from '@shared/constants/routes.constants';
import { COLUMN_NAMES } from '../constant/constant';
import { UsersTabContent } from './tabs/UsersTabContent';
import { AgentProfilesTabContent } from './tabs/AgentProfilesTabContent';
import { useTranslation } from 'react-i18next';

const COLUMN_NAMES_WITH_PASSWORD = {
  ...COLUMN_NAMES,
  PASSWORD: 'Password',
};

const FIELD_NAMES = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  ROLE: 'role',
  PASSWORD: 'password',
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const EditCompanyPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [companyId, setCompanyId] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const {
    data: users,
    refetch: refetchUsers,
    isLoading,
  } = useGetUsersListControllerGetUsersListQuery({ id: companyId });

  const [useEditUser] = useEditUserControllerEditUserMutation();

  const { t } = useTranslation();

  useEffect(() => {
    const id = searchParams.get('id');
    const name = searchParams.get('name');

    if (id) {
      setCompanyId(id);
    }

    if (name) {
      setCompanyName(name);
    }
  }, [searchParams]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, user: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setIsModalOpen(true);
    handleMenuClose();
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedUser({
      ...selectedUser,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    const updatedUser = {
      id: selectedUser.id,
      firstName: selectedUser.firstName,
      lastName: selectedUser.lastName,
      email: selectedUser.email,
      role: selectedUser.role,
      password: selectedUser.password,
    };

    try {
      await useEditUser({ id: updatedUser.id, editUserRequestDto: updatedUser }).unwrap();
      setIsModalOpen(false);
      refetchUsers();
    } catch (error) {
      setErrors((error as any).data);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const usersTabContent = (
    <UsersTabContent
      users={users}
      isLoading={isLoading}
      anchorEl={anchorEl}
      handleMenuOpen={handleMenuOpen}
      handleMenuClose={handleMenuClose}
      handleEditClick={handleEditClick}
    />
  );

  return (
    <Box>
      <Box marginY={4}>
        <Typography variant="h6">
          <Link
            to={SuperAdminAuthenticatedPath.COMPANIES}
            style={{ textDecoration: 'none', color: 'inherit' }}>
            Companies
          </Link>
          {' > '}
          <span>Edit Company</span>
          {' > '}
          <span>{companyName}</span>
        </Typography>
      </Box>
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
        <Tab label={t('superAdminMainPage.companies.tabs.users')} {...a11yProps(0)} />
        <Tab label={t('superAdminMainPage.companies.tabs.agentProfiles')} {...a11yProps(0)} />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        {usersTabContent}
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        {companyId && <AgentProfilesTabContent companyId={companyId} />}
      </TabPanel>
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}>
          <Typography variant="h6" component="h2">
            Edit User
          </Typography>
          {Object.entries(FIELD_NAMES).map(([fieldKey, fieldName]) => (
            <TextField
              key={fieldKey}
              margin="normal"
              fullWidth
              label={
                COLUMN_NAMES_WITH_PASSWORD[
                  fieldKey.toUpperCase() as keyof typeof COLUMN_NAMES_WITH_PASSWORD
                ]
              }
              name={fieldName}
              type={fieldName === 'password' ? 'password' : 'text'}
              value={selectedUser?.[fieldName] || ''}
              onChange={handleInputChange}
              error={Boolean(errors?.[fieldName])}
              helperText={errors?.[fieldName]}
            />
          ))}
          <Button onClick={handleSave} variant="contained" color="primary" sx={{ mt: 2 }}>
            Save
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};
