// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as React from 'react';
import { styled } from '@mui/material';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

const BpIcon = styled('span', {
  shouldForwardProp: (prop) => prop !== 'highlighted',
})<{ highlighted?: boolean }>(({ theme, highlighted }) => ({
  borderRadius: 6,
  width: 19,
  height: 19,
  marginLeft: '4px',
  boxShadow:
    theme.palette.mode === 'dark'
      ? `0 0 0 1px ${highlighted ? theme.palette.error.main : theme.palette.grey[200]}`
      : `inset 0 0 0 1px ${highlighted ? theme.palette.error.main : theme.palette.grey[300]}`,
  backgroundColor: 'transparent',

  '.Mui-focusVisible &': {
    outline:
      theme.palette.mode === 'dark'
        ? `0px auto ${theme.palette.grey[200]}`
        : `0px auto  ${theme.palette.grey[300]}`,
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary : theme.palette.primary,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.grey[100],
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  boxShadow: 'none',
  width: 19,
  height: 19,
  '&:before': {
    display: 'block',
    width: 19,
    height: 19,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E" +
      "%3Cpath d='M8.33317 1L3.74984 5.58333L1.6665 3.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E" +
      '%3C/svg%3E")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    content: '""',
  },
});

// Inspired by blueprintjs
function CustomCheckbox({ highlighted, ...props }: CheckboxProps & { highlighted?: boolean }) {
  return (
    <Checkbox
      disableRipple
      color={props.color ? props.color : 'default'}
      checkedIcon={
        <BpCheckedIcon
          sx={{
            backgroundColor: props.color ? `${props.color}.main` : 'primary.main',
          }}
        />
      }
      icon={<BpIcon highlighted={highlighted} />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

export default CustomCheckbox;
