import React from 'react';

import { Box, styled } from '@mui/material';

type Props = {
  children: JSX.Element | JSX.Element[];
  disabled?: boolean;
  onClick?: () => void;
};
const StyledIconBox = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
  boxShadow: `0px 1px 4px ${theme.palette.extendedGrey?.light}`,
  backgroundColor: 'transparent',
  width: 30,
  height: 30,
  '&.IconBox-Disabled': {
    backgroundColor: theme.palette.extendedGrey?.light,
    '& path': {
      stroke: '#B3BFD0',
    },
  },
  '&:not(.IconBox-Disabled):hover': {
    backgroundColor: theme.palette.extendedGrey?.lightest,
    '& path': {
      stroke: theme.palette.primary.main,
    },
  },
}));

const ActionTableButton: React.FC<Props> = (props) => {
  const { children, onClick, disabled, ...other } = props;
  const handleOnClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };
  return (
    <StyledIconBox
      {...other}
      onClick={handleOnClick}
      className={disabled ? 'IconBox-Disabled' : ''}>
      {children}
    </StyledIconBox>
  );
};

export default ActionTableButton;
