import * as auth from './auth';
import * as customizer from './customizer';
import * as ecommerce from './ecommerce';
import * as breadcrumbs from './breadcrumbs';

import { combineReducers } from '@reduxjs/toolkit';
import { baseApi } from '../api/baseApi';
import { baseApi as conversationApi } from '../api/conversationApi';

export const combinedReducer = combineReducers({
  auth: auth.reducer,
  breadcrumbs: breadcrumbs.reducer,
  customizer: customizer.reducer,
  ecommerce: ecommerce.reducer,
  [baseApi.reducerPath]: baseApi.reducer,
  [conversationApi.reducerPath]: conversationApi.reducer,
});

export const selectors = {
  auth: auth.selectors,
  breadcrumbs: breadcrumbs.selectors,
  customizer: customizer.selectors,
  ecommerce: ecommerce.selectors,
};

export const actions = {
  auth: auth.actions,
  breadcrumbs: breadcrumbs.actions,
  customizer: customizer.actions,
  ecommerce: ecommerce.actions,
};

export type ReducerState = ReturnType<typeof combineReducers>;
