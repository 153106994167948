import { Avatar, Box, ListItemAvatar, Stack, Typography, useTheme } from '@mui/material';
import { IconHeadset } from '@shared/assets/images/svgs';
import { RecipientActors } from '@shared/constants/recipient-status.constants';
import { ConversationLogDto } from '@shared/services/conversationApiService/apiService';
import React from 'react';
import { timeAgoCalculation } from './utils';

interface ConversationListMessageProps {
  messageItem: ConversationLogDto;
  actorName?: string;
  isStacked?: boolean;
}

export const ConversationListMessage: React.FC<ConversationListMessageProps> = ({
  messageItem,
  actorName,
  isStacked,
}: ConversationListMessageProps) => {
  const theme = useTheme();
  const { actor } = messageItem;
  const isAgent: boolean = actor === RecipientActors.AGENT;

  const getMessageSenderInfo = (actor: string, sentAt: string) => (
    <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
      <Typography variant="body2" color={theme.palette.extendedGrey?.darker} mb={1}>
        {actor},&nbsp;
      </Typography>
      <Typography variant="body2" color={theme.palette.extendedGrey?.neutral} mb={1}>
        {timeAgoCalculation(sentAt)}
      </Typography>
    </Stack>
  );

  return (
    <Box
      display="flex"
      alignItems={isAgent ? 'flex-start' : 'flex-end'}
      flexDirection={isAgent ? 'row' : 'row-reverse'}
      sx={{
        margin: `${isStacked ? 0 : '20px'} 0 4px ${isAgent ? 0 : '56px'}`,
      }}>
      {isAgent && (
        <ListItemAvatar>
          <Avatar
            sx={{
              width: 40,
              height: 40,
              backgroundColor: theme.palette.secondary?.light,
            }}>
            <IconHeadset />
          </Avatar>
        </ListItemAvatar>
      )}
      <Box alignItems={isAgent ? 'flex-start' : 'flex-end'} display="flex" flexDirection="column">
        {!isStacked &&
          getMessageSenderInfo(actorName ? actorName : messageItem.actor, messageItem.createdAt)}
        <Box
          mb={!isStacked ? 0 : 1}
          sx={{
            p: 1,
            borderRadius: isAgent ? '0 12px 12px 12px' : '12px 0 12px 12px',
            backgroundColor:
              actor === 'callee' ? theme.palette.extendedGrey?.neutralLight : 'white',
            ml: isAgent ? 'unset' : 'auto',
            mr: isAgent ? 'auto' : 'unset',
            maxWidth: '320px',
          }}>
          {messageItem.message}
        </Box>
      </Box>
    </Box>
  );
};
