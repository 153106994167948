import {
  Box,
  Chip,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../styles';
import {
  getSentimentsColor,
  getTotalSentimentsScore,
  inboundCallsToRecipientsMapper,
  outboundCallsToRecipientsMapper,
} from '../utils';
import {
  CampaignRunDto,
  CampaignRunRecipientStatusType,
  ConversationSentimentDto,
  CampaignType,
} from '@shared/services/apiService/apiService';
import { RecipientData, SortConfig } from '../types';
import { useWebSocketGetOutboundConversations } from '@features/Admin/Campaign/hooks/useWebSocketGetOutboundConversations';
import { IconIndicatorFlag, IconMonitoring } from '@shared/assets/images/svgs';
import ActionTableButton from '@shared/components/actionTableButton/actionTableButton';
import { MonitorCampaignPopupV2 } from '@widgets/Popup/MonitorCampaignV2/MonitorCampaignV2';
import { RecipientStatus } from '@shared/constants/recipient-status.constants';
import { CampaignTypeEnum } from '@shared/constants/campaign.constants';
import { useWebSocketGetInboundConversations } from '@features/Admin/Campaign/hooks';

interface OngoingCallsTabProps {
  campaignId: string | null;
  campaignRunData: CampaignRunDto;
  campaignType?: CampaignType;
}

const OngoingCallsTab: React.FC<OngoingCallsTabProps> = ({
  campaignId,
  campaignRunData,
  campaignType = CampaignTypeEnum.OUTBOUND,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { classes } = useStyles();

  const [showMonitorDialogFlag, setShowMonitorDialogFlag] = useState(false);
  const [recipientId, setRecipientId] = useState<string>('');
  const [callsPhoneNumber, setCallsPhoneNumber] = useState('');
  const [calls, setCalls] = useState<RecipientData[]>([]);
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: 'asc' });

  const {
    isConnected: isOutboundSocketConnected,
    recipientRefetch: outboundRecipientsRefetch,
    recipients: outboundRecipients,
    subcribeToRecipientConversations: subcribeToOutboundRecipientConversations,
  } = useWebSocketGetOutboundConversations(campaignId as string);
  const {
    isConnected: isInboundSocketConnected,
    recipientRefetch: inboundRecipientsRefetch,
    recipients: inboundRecipients,
    subcribeToRecipientConversations: subcribeToInboundRecipientConversations,
  } = useWebSocketGetInboundConversations(campaignId as string);

  const subscribeToWSHandlers = {
    [CampaignTypeEnum.OUTBOUND]: () => {
      subcribeToOutboundRecipientConversations(campaignRunData.id);
      outboundRecipientsRefetch();
    },
    [CampaignTypeEnum.INBOUND]: () => {
      subcribeToInboundRecipientConversations(campaignRunData.id);
      inboundRecipientsRefetch();
    },
  };

  useEffect(() => {
    (subscribeToWSHandlers[campaignType] || subscribeToWSHandlers[CampaignTypeEnum.OUTBOUND])();
  }, [campaignRunData, isOutboundSocketConnected, isInboundSocketConnected]);

  useEffect(() => {
    let mappedOutboundRecipients: RecipientData[] = [];
    let mappedInboundRecipients: RecipientData[] = [];
    if (outboundRecipients) {
      mappedOutboundRecipients = outboundCallsToRecipientsMapper(outboundRecipients);
    }
    if (inboundRecipients) {
      mappedInboundRecipients = inboundCallsToRecipientsMapper(inboundRecipients);
    }
    setCalls([...mappedOutboundRecipients, ...mappedInboundRecipients]);
  }, [outboundRecipients]);

  const handleMonitorClick = (rowId: string, phone: string) => {
    setShowMonitorDialogFlag(true);
    setRecipientId(rowId);
    setCallsPhoneNumber(phone);
  };

  const closeDialog = () => {
    setShowMonitorDialogFlag(false);
  };

  const handleSort = (key: keyof RecipientData, fixedDirection?: 'asc' | 'desc') => {
    let direction: 'asc' | 'desc' = fixedDirection || 'asc';
    if (!fixedDirection && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    setSortConfig({ key, direction });
  };

  const transformToDDMMYYYY = (timestamp: string) => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })
      .format(date)
      .replace(/\//g, '.');
  };

  const getStatusColor = (status: CampaignRunRecipientStatusType) => {
    if (status === RecipientStatus['IN-PROGRESS']) return theme.palette.primary.main;

    return theme.palette.extendedGrey?.neutral;
  };

  const sortCalls = (calls: RecipientData[], sortConfig: SortConfig) => {
    if (!sortConfig.key) return calls;

    const sortedCalls = [...calls].sort((a, b) => {
      let aValue: number | string | ConversationSentimentDto[] = a[sortConfig.key!] ?? '';
      let bValue: number | string | ConversationSentimentDto[] = b[sortConfig.key!] ?? '';

      if (sortConfig.key === 'sentiments') {
        aValue = a.sentiments.length ? getTotalSentimentsScore(a.sentiments) : -Infinity;
        bValue = b.sentiments.length ? getTotalSentimentsScore(b.sentiments) : -Infinity;
      }

      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    return sortedCalls;
  };

  const handleSortPhoneNumber = () => handleSort('phoneNumber');
  const handleSortDate = () => handleSort('startDate');
  const handleSortDuration = () => handleSort('callDuration');
  const handleSortSentiments = (direction?: 'asc' | 'desc') => handleSort('sentiments', direction);

  const sortedCalls = sortCalls(calls, sortConfig);
  const currentRecipient =
    outboundRecipients?.find((recipient) => recipient.id === recipientId) ||
    inboundRecipients?.find((recipient) => recipient.id === recipientId);

  const StyledChip = styled(Chip)(({ theme }) => ({
    height: '23px',
    backgroundColor: theme.palette.primary.light,
  }));

  return (
    <>
      <Box
        sx={{
          padding: '30px',
          width: '100%',
        }}>
        <Box
          className={classes.header_title}
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}>
          <Typography fontSize="18px">{t('campaignPage.editCampaign.ongoingCalls')}</Typography>
          <Typography fontSize="18px" color={theme.palette.text.secondary}>
            ({sortedCalls.length})
          </Typography>
        </Box>
        {sortedCalls.length ? (
          <TableContainer sx={{ pt: 3 }}>
            <Table>
              <TableHead className={classes.tableheahder}>
                <TableRow>
                  <TableCell onClick={handleSortPhoneNumber} className={classes.table_first_cell}>
                    <Box className={classes.table_sort_cell}>
                      <Typography variant="h6" className={classes.table_th}>
                        {t('campaignPage.phoneNumber')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.table_sort_cell}>
                      <Typography variant="h6" className={classes.table_th}>
                        {t('campaignPage.status')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell onClick={handleSortDate}>
                    <Box className={classes.table_sort_cell}>
                      <Typography variant="h6" className={classes.table_th}>
                        {t('campaignPage.startDate')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell onClick={handleSortDuration}>
                    <Box className={classes.table_sort_cell}>
                      <Typography variant="h6" className={classes.table_th}>
                        {t('campaignPage.callDuration')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell onClick={() => handleSortSentiments()}>
                    <Box className={classes.table_sort_cell}>
                      <Typography variant="h6" className={classes.table_th}>
                        {t('campaignPage.sentimentIndi')}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.table_last_cell}>
                    <Typography variant="h6" className={classes.table_th} align="left">
                      {t('campaignPage.action')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedCalls.map((row) => {
                  return (
                    <TableRow className={classes.table_row} key={row.id} hover>
                      <TableCell align="left" className={classes.table_first_cell}>
                        <Typography variant="subtitle1">{row.phoneNumber}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <StyledChip
                          sx={{ color: getStatusColor(row.status) }}
                          label={capitalize(row.status)}
                        />
                      </TableCell>
                      <TableCell align="left">
                        {!row.startDate ? (
                          <StyledChip
                            sx={{ color: getStatusColor(row.status) }}
                            label={t('campaignPage.unavailable')}
                          />
                        ) : (
                          <Typography variant="subtitle1">
                            {transformToDDMMYYYY(row.startDate) || row.status}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" alignItems="center" spacing="10px">
                          {!row.callDuration && !row.startDate ? (
                            <StyledChip
                              sx={{ color: getStatusColor(row.status) }}
                              label={t('campaignPage.unavailable')}
                            />
                          ) : (
                            <Typography variant="subtitle1">
                              {row.callDuration ? (
                                <>{row.callDuration} </>
                              ) : (
                                <StyledChip
                                  sx={{ color: getStatusColor(row.status) }}
                                  label={t('campaignPage.unavailable')}
                                />
                              )}
                            </Typography>
                          )}
                        </Stack>
                      </TableCell>
                      <TableCell align="center">
                        {!row.sentiments.length ? (
                          <StyledChip
                            style={{}}
                            sx={{
                              color: getStatusColor(row.status),
                              position: 'relative',
                              right: 45,
                            }}
                            label={t('campaignPage.unavailable')}
                          />
                        ) : (
                          <Box
                            marginRight={10}
                            display={'flex'}
                            justifyContent="center"
                            sx={{ '& path': { stroke: getSentimentsColor(row.sentiments) } }}>
                            <IconIndicatorFlag />
                          </Box>
                        )}
                      </TableCell>
                      <TableCell sx={{ paddingRight: 0 }}>
                        <ActionTableButton
                          disabled={[
                            RecipientStatus.PENDING,
                            RecipientStatus.QUEUED,
                            RecipientStatus.RINGING,
                          ].includes(row.status)}
                          onClick={() => handleMonitorClick(row.mainId, row.phoneNumber)}>
                          <IconMonitoring />
                        </ActionTableButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography sx={{ textAlign: 'center' }}>
            {t('campaignPage.emptyOngoingCalls')}
          </Typography>
        )}
        <>
          {showMonitorDialogFlag && currentRecipient && (
            <MonitorCampaignPopupV2
              showDialogFlag={showMonitorDialogFlag}
              closeDialog={closeDialog}
              phoneNumber={callsPhoneNumber}
              recipient={currentRecipient}
            />
          )}
        </>
      </Box>
    </>
  );
};

export default OngoingCallsTab;
