import React, { useReducer, ChangeEvent, useState } from 'react';
import { Box, MenuItem, TableCell, Typography, useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { useTranslation } from 'react-i18next';
import CustomSelect from '@shared/components/forms/theme-elements/CustomSelect';
import CustomTextField from '@shared/components/forms/theme-elements/CustomTextField';
import { IconChevronDown } from '@shared/assets/images/svgs';
import { useStyles } from './styles';
import { IconVolume } from '@tabler/icons-react';
import {
  CampaignAgentDto,
  useAgentProfileListControllerAgentProfileListForUsersQuery,
  useGetAllVoicesControllerGetAllVoicesQuery,
} from '@shared/services/apiService/apiService';
import { useEffect } from 'react';
import { useCreateAgent } from '@features/Admin/Agent/hooks/useCreateAgent';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@shared/store';
import { selectors } from '@shared/store/ducks';

interface State {
  agent: string;
  sex: string;
  voice: string;
  language: string;
  profile: string;
}

type Action =
  | { type: 'SET_AGENT'; payload: string }
  | { type: 'SET_SEX'; payload: string }
  | { type: 'SET_VOICE'; payload: string }
  | { type: 'SET_LANGUAGE'; payload: string }
  | { type: 'SET_PROFILE'; payload: string }
  | { type: 'RESET' };

const initialState: State = {
  agent: '',
  sex: '',
  voice: '',
  language: '',
  profile: '',
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_AGENT':
      return { ...state, agent: action.payload };
    case 'SET_SEX':
      return { ...state, sex: action.payload };
    case 'SET_VOICE':
      return { ...state, voice: action.payload };
    case 'SET_LANGUAGE':
      return { ...state, language: action.payload };
    case 'SET_PROFILE':
      return { ...state, profile: action.payload };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

const CustomSelectField: React.FC<{
  control: any;
  labelId: string;
  name: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement | { value: unknown }>) => void;
  placeholder: string;
  options: { key: string; value: string; label: string }[];
  disabled?: boolean;
}> = ({ control, labelId, name, value, onChange, placeholder, options, disabled }) => {
  const theme = useTheme();
  const { classes } = useStyles();

  return (
    <CustomSelect
      control={control}
      labelId={labelId}
      name={name}
      onChange={onChange}
      id={`${labelId}-id`}
      disabled={disabled}
      value={value}
      IconComponent={IconChevronDown}
      className={classes.select}
      displayEmpty
      renderValue={(value: string) => {
        if (!value) {
          return <Typography color={theme.palette.extendedGrey?.neutral}>{placeholder}</Typography>;
        }
        return <>{value}</>;
      }}>
      {options.map((option) => (
        <MenuItem key={option.key} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </CustomSelect>
  );
};

interface ManageAgentProps {
  onClose: () => void;
  agents: CampaignAgentDto[];
  agent: CampaignAgentDto | null;
}

export const ManageAgent: React.FC<ManageAgentProps> = ({ onClose, agents, agent }) => {
  const [state, dispatch] = useReducer(
    reducer,
    agent
      ? ({
          agent: agent.name,
          sex: agent.voice?.gender,
          language: agent.voice?.accent,
          voice: agent.voice?.name,
          profile: agent.profileId,
        } as State)
      : initialState,
  );

  const user = useAppSelector(selectors.auth.getUser);

  if (!user?.companyId) {
    return <div>User not found...</div>;
  }

  const { classes } = useStyles();

  const { campaignId } = useParams();

  const {
    control,
    isLoading: isCreatingAgent,
    setValue,
    onSubmit,
  } = useCreateAgent(agent?.campaignId || campaignId || '', agents);
  const { t } = useTranslation();

  const { data: agentProfileTypes } = useAgentProfileListControllerAgentProfileListForUsersQuery({
    id: user.companyId,
  });

  const { data: voiceData } = useGetAllVoicesControllerGetAllVoicesQuery();

  const [isPlayingVoice, setIsPlayingVoice] = useState<boolean>(false);

  useEffect(() => {
    if (agent) {
      dispatch({ type: 'SET_AGENT', payload: agent.name });
      dispatch({ type: 'SET_SEX', payload: agent.voice?.gender || '' });
      dispatch({ type: 'SET_VOICE', payload: agent?.voice?.id || '' });
      dispatch({ type: 'SET_LANGUAGE', payload: agent.voice?.accent || '' });
      dispatch({
        type: 'SET_PROFILE',
        payload:
          agentProfileTypes?.find(
            (p) => (p.name as unknown as string) === (agent.profile?.name as unknown as string),
          )?.id || '',
      });
    } else {
      dispatch({ type: 'RESET' });
    }
  }, [agent, agentProfileTypes]);

  const handleInputChange =
    (type: Action['type']) => (event: ChangeEvent<HTMLInputElement | { value: unknown }>) => {
      if (type === 'SET_SEX') {
        dispatch({ type: 'SET_SEX', payload: event.target.value as string });
        dispatch({ type: 'SET_VOICE', payload: '' });
        return;
      }

      dispatch({ type, payload: event.target.value as string });
    };

  const handleSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setValue('mission.humanName', state.agent);
    setValue('name', state.agent);
    setValue('voiceId', state.voice);
    setValue('gender', state.sex);
    setValue('accent', state.language);
    setValue('profile', state.profile);

    if (agent) {
      setValue('id', agent?.id || '');
    }

    await onSubmit();
    dispatch({ type: 'RESET' });
    onClose();
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClose();
    dispatch({ type: 'RESET' });
  };

  const sexOptions = Array.from(new Set(voiceData?.map((voice) => voice.gender))).map((gender) => ({
    key: gender || '',
    value: gender || '',
    label: (gender ?? '').charAt(0).toUpperCase() + (gender ?? '').slice(1) || '',
  }));

  const voiceOptions = voiceData
    ?.filter((voice) => voice.gender === state.sex)
    .map(({ name, id }) => ({
      key: id || '',
      value: id || '',
      label: (name ?? '').charAt(0).toUpperCase() + (name ?? '').slice(1) || '',
    }));

  const accentOptions = Array.from(new Set(voiceData?.map((voice) => voice.accent))).map(
    (accent) => ({
      key: accent || '',
      value: accent || '',
      label: (accent ?? '').charAt(0).toUpperCase() + (accent ?? '').slice(1) || '',
    }),
  );

  const profileOptions = (agentProfileTypes?.map((profile) => {
    return {
      key: profile.id,
      value: profile.id,
      label: profile.name,
    };
  }) || []) as unknown as { key: string; value: string; label: string }[];

  const handelTestVoice = (previewUrl: string) => {
    if (isPlayingVoice) return;

    let audio = new Audio();

    audio.pause();
    audio = new Audio(previewUrl);
    audio.play();

    setIsPlayingVoice(true);

    audio.onended = () => {
      setIsPlayingVoice(false);
    };
  };

  const isSaveDisabled = isCreatingAgent || !Object.values(state).every((value) => value);

  return (
    <>
      <TableCell>
        <CustomTextField
          control={control}
          name="name"
          value={state.agent}
          onChange={handleInputChange('SET_AGENT')}
          id="agent-name"
          type="text"
          placeholder={t('campaignPage.placeholders.humanName')}
          varient="outlined"
          inputProps={{ maxLength: 56 }}
          fullWidth
          required
        />
      </TableCell>

      <TableCell>
        <CustomSelectField
          control={control}
          labelId="sex-type"
          name="sexType"
          value={state.sex}
          onChange={handleInputChange('SET_SEX')}
          placeholder={t('campaignPage.placeholders.sexType')}
          options={sexOptions}
        />
      </TableCell>

      <TableCell>
        <Box display="flex" alignItems="center" gap={1}>
          <Box
            onClick={() => {
              if (!state.sex) return;
              const previewUrl = voiceData?.find((voice) => voice.id === state.voice)?.previewUrl;
              handelTestVoice(previewUrl || '');
            }}
            sx={{
              backgroundColor: '#7C8FAC',
              borderRadius: '50%',
              padding: '4px',
              opacity: isPlayingVoice || !state.sex ? 0.5 : 1,
            }}>
            <IconVolume color="white" size={16} />
          </Box>
          <CustomSelectField
            control={control}
            labelId="voice-type"
            name="voiceType"
            disabled={!state.sex}
            value={voiceData?.find((voice) => voice.id === state.voice)?.name || ''}
            onChange={handleInputChange('SET_VOICE')}
            placeholder={t('campaignPage.placeholders.voiceType')}
            options={voiceOptions || []}
          />
        </Box>
      </TableCell>

      <TableCell>
        <CustomSelectField
          control={control}
          labelId="language-type"
          name="languageType"
          value={state.language}
          onChange={handleInputChange('SET_LANGUAGE')}
          placeholder={t('campaignPage.placeholders.languageType')}
          options={accentOptions}
        />
      </TableCell>

      <TableCell>
        <CustomSelectField
          control={control}
          labelId="profile-type"
          name="profileType"
          value={
            (agentProfileTypes?.find((profile) => profile.id === state.profile)
              ?.name as unknown as string) || ''
          }
          onChange={handleInputChange('SET_PROFILE')}
          placeholder={t('campaignPage.placeholders.roleType')}
          options={profileOptions}
        />
      </TableCell>

      <TableCell>
        <Box display="flex" justifyContent="flex-end" alignItems="center" gap={1}>
          <button className={classes.save_button} disabled={isSaveDisabled} onClick={handleSave}>
            <CheckIcon htmlColor="white" style={{ width: 16 }} />
          </button>

          <button className={classes.cancel_button} color="secondary" onClick={handleCancel}>
            <CloseIcon htmlColor="#7C8FAC" style={{ width: 16 }} />
          </button>
        </Box>
      </TableCell>
    </>
  );
};
