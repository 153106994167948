import { CampaignRunRecipientStatusType } from '@shared/services/apiService/apiService';
import { ConversationActorsType } from '@shared/services/conversationApiService/apiService';

export const RecipientStatus: Record<
  Uppercase<CampaignRunRecipientStatusType>,
  CampaignRunRecipientStatusType
> = {
  PENDING: 'pending',
  FAILED: 'failed',
  'IN-PROGRESS': 'in-progress',
  RINGING: 'ringing',
  QUEUED: 'queued',
  COMPLETED: 'completed',
  TERMINATED: 'terminated',
};

export const RecipientActors: Record<Uppercase<ConversationActorsType>, ConversationActorsType> = {
  AGENT: 'agent',
  CALLEE: 'callee',
};
