import { tss } from 'tss-react/mui';

export const useStyles = tss.create(({ theme }) => ({
  select: {
    width: '100%',
    [`> svg`]: {
      marginRight: '4px',
    },
  },
  cancel_button: {
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    width: '36px',
    padding: '10px',
    background: theme.palette.extendedGrey?.lightest,
    borderRadius: '8px',
  },
  save_button: {
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    width: '36px',
    padding: '10px',
    background: theme.palette.primary.dark,
    borderRadius: '8px',
    '&:disabled': {
      opacity: 0.4,
    },
  },
}));
