import { tss } from 'tss-react/mui';

export const useStyles = tss.create(({ theme }) => ({
  dialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '800px',
        maxHeight: '95vh',
        // overflowY: 'unset',
      },
    },
  },
  dialogTitle: {
    textAlign: 'center',
    padding: '30px 30px 20px 30px',
  },
  customizedButton: {
    position: 'absolute',
    right: '15px',
    top: '15px',
    padding: 0,
  },
  dialogContent: {
    padding: '0px 30px, 30px, 30px',
  },
  chatBox: {
    width: '100%',
    backgroundColor: '#F2F6FA',
  },
  tableV2: {
    border: `1px solid ${theme.palette.extendedGrey?.neutralLight}`,
    borderCollapse: 'unset',
    borderRadius: '8px',
  },
  table_header_cell: {
    padding: 0,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  table_header_cellV2: {
    padding: '16px',
    paddingLeft: 0,
  },
  table_row_cell: {
    paddingLeft: '0px',
    paddingRight: '10px',
  },
  table_row_last_cell: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  table_row_cellV2: {
    padding: '8px 0px 8px 16px',
  },
  table_row_middle_cellV2: {
    padding: '0px 0px 8px 0px',
    '& fieldset': {
      borderRadius: '8px 0px 0px 8px',
      borderRight: 'unset',
    },
  },
  table_row_last_cellV2: {
    padding: '0px 16px 8px 0px',
    '& fieldset': {
      borderRadius: '0px 8px 8px 0px',
    },
  },
  columnName: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#7C8FAC',
  },
  columnNameV2: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '19.6px',
    color: theme.palette.extendedGrey?.dark,
  },
  call_schedule_timezone_select: {
    width: '100%',
    [`> svg`]: {
      marginRight: '4px',
    },
  },
}));
