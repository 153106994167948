import { Grid } from '@mui/material';
import { CampaignFileField } from '@shared/components/campaignFileField/CampaignFileField';
import {
  CampaignDto,
  useDeleteLeadListControllerDeleteLeadListMutation,
} from '@shared/services/apiService/apiService';
import { CampaignFieldsError } from '@shared/types/admin/campaign';
import React, { useState } from 'react';

interface PhoneNumbersTabProps {
  campaign?: CampaignDto | null;
  setLeadListChanged: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => void;
}
const PhoneNumbersTab: React.FC<PhoneNumbersTabProps> = ({
  campaign,
  setLeadListChanged,
  refetch,
}) => {
  const [isFile, setIsFile] = useState(false);
  const [excelFieldError, setExcelFieldError] = useState('');
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [campaignFieldError, setCampaignFieldError] = useState<CampaignFieldsError>({
    retryIntervalInMinutes: [],
    retryMaxNumber: [],
    retriesPerDay: [],
    editErrorMessage: '',
  });

  const [useDeleteLeadList] = useDeleteLeadListControllerDeleteLeadListMutation();

  const onLeadListChanged = () => setLeadListChanged(true);

  const deleteLeadList = async (leadListId: string) => {
    if (leadListId) {
      setLoadingFlag(true);
      const response = await useDeleteLeadList({
        id: leadListId,
      });
      if ('error' in response) {
        throw response.error;
      }

      await refetch();
    }
    setLoadingFlag(false);
  };

  const isCampaignRunning = ['active', 'finishing'].includes(
    campaign?.campaignRun?.status as string,
  );

  return (
    <>
      <form>
        <Grid
          container
          sx={{
            margin: '30px',
            flexDirection: 'column',
          }}>
          <CampaignFileField
            setIsFile={setIsFile}
            campaignFieldError={campaignFieldError}
            setCampaignFieldError={setCampaignFieldError}
            setExcelFieldError={setExcelFieldError}
            excelFieldError={excelFieldError}
            onLeadListChanged={onLeadListChanged}
            onDeleteFile={deleteLeadList}
            isEditable={!isCampaignRunning}
            leadList={campaign ? campaign.leadList : undefined}
          />
        </Grid>
      </form>
    </>
  );
};

export default PhoneNumbersTab;
