import { createSlice } from '@reduxjs/toolkit';
import { UserDto } from '@shared/services/apiService/apiService';

export type CompanyType = {
  title: string;
  logo: string;
};

export type AuthStateType = {
  accessToken: string | null;
  refreshToken: string | null;
  superAdminAccessToken: string | null;
  superAdminRefreshToken: string | null;
  isLoading: boolean;
  authenticatedUserCompany?: CompanyType;
  authenticatedUser?: UserDto;
};

const storedAccessToken = localStorage.getItem('accessToken');
const storedRefreshToken = localStorage.getItem('refreshToken');
const storedSuperAdminRefreshToken = localStorage.getItem('superAdminRefreshToken');

const superAdminStoredToken = localStorage.getItem('superAdminAccessToken');

const initialState: AuthStateType = {
  authenticatedUserCompany: undefined,
  accessToken: storedAccessToken ? storedAccessToken : null,
  refreshToken: storedRefreshToken ? storedRefreshToken : null,
  superAdminRefreshToken: storedSuperAdminRefreshToken ? storedSuperAdminRefreshToken : null,
  superAdminAccessToken: superAdminStoredToken ? superAdminStoredToken : null,
  isLoading: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;

      state.authenticatedUserCompany = undefined;

      localStorage.setItem('accessToken', action.payload.accessToken);
      localStorage.setItem('refreshToken', action.payload.refreshToken);
    },
    signInSuperAdmin: (state, action) => {
      state.superAdminAccessToken = action.payload.accessToken;
      state.superAdminRefreshToken = action.payload.refreshToken;

      state.authenticatedUserCompany = undefined;

      localStorage.setItem('superAdminAccessToken', action.payload.accessToken);
      localStorage.setItem('superAdminRefreshToken', action.payload.refreshToken);
    },
    signOutSuperAdmin: (state) => {
      state.superAdminAccessToken = null;
      state.superAdminAccessToken = null;

      state.authenticatedUserCompany = undefined;
      localStorage.removeItem('superAdminAccessToken');
      localStorage.removeItem('superAdminRefreshToken');

      return state;
    },
    signOut: (state) => {
      state.accessToken = null;
      state.authenticatedUserCompany = undefined;
      localStorage.removeItem('accessToken');
      return state;
    },
    setAuthenticatedUserCompany: (state, action) => {
      state.authenticatedUserCompany = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setAuthenticatedUser: (state, action) => {
      state.authenticatedUser = action.payload;
    },
  },
});

export const actions = {
  ...authSlice.actions,
};

export const { reducer } = authSlice;
