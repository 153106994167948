import { Box, Button, Grid, styled, useTheme } from '@mui/material';
import CustomFormLabel from '../forms/theme-elements/CustomFormLabel';
import CustomTextField from '../forms/theme-elements/CustomTextField';
import Mustache from 'mustache';
import { t } from 'i18next';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateCampaignFormValue } from '@features/Admin/Campaign/model/type';
import { FormFields } from '@shared/types/admin/campaign';
import { fromPairs } from 'lodash';
import { InfoDialog } from '../infoDialog/infoDialog';
import { StyledErrorText } from '../errorText';

type mastecheValues = {
  agentName: string;
  templateTextFieldValue: string;
  campaignName: string;
  companyName?: string;
};

interface MastecheType {
  key: string;
  value?: string;
}

interface CampaignTextField {
  requireTemplateTextField?: boolean;
  mastecheValues: mastecheValues;
  setTemplateTextFieldValue: React.Dispatch<React.SetStateAction<string>>;
}

const StyledMastecheButton = styled(Button)(({ theme }) => ({
  marginRight: '10px',
  marginBottom: '10px',
  fontSize: '12px',
  color: theme.palette.extendedGrey?.dark,
  backgroundColor: theme.palette.extendedGrey?.lightest,
  '&:hover': {
    color: theme.palette.extendedGrey?.dark,
    backgroundColor: 'white',
    boxShadow: `0px 1px 3px 0px ${theme.palette.extendedGrey?.neutralLight}`,
  },
}));

export const CampaignTextField = ({
  requireTemplateTextField,
  mastecheValues,
  setTemplateTextFieldValue,
}: CampaignTextField) => {
  const theme = useTheme();
  const {
    control,
    formState: { errors: formStateErrors },
    setValue,
  } = useFormContext<CreateCampaignFormValue>();

  const [openModal, setOpenModal] = React.useState(false);
  const [renderedTextFieldValue, setRenderedTextFieldValue] = useState('');
  Mustache.tags = ['[[%', '%]]'];

  const mastecheList: MastecheType[] = [
    { key: 'agent_human_name', value: mastecheValues.agentName },
    { key: 'company_name', value: mastecheValues.companyName },
    { key: 'campaign_name', value: mastecheValues.campaignName },
  ];

  const mapMastecheValues = (array: MastecheType[]) =>
    fromPairs(array.map(({ key, value }) => [key, value]));
  const wrapMastecheTags = (text: string) => '[[%' + text + '%]]';

  const insertAtCursor = (input: HTMLInputElement, text: string) => {
    const cursorPos = getCursorPosition(input);
    const value = input.value;
    const wrappedText = wrapMastecheTags(text);
    input.value = value.slice(0, cursorPos) + wrappedText + value.slice(cursorPos);
    input.setSelectionRange(cursorPos + wrappedText.length, cursorPos + wrappedText.length);
    input.focus();
  };

  const handleAddMastecheValue = (key: string) => {
    const input = document.getElementById('campaign-template') as HTMLInputElement;
    if (input) {
      insertAtCursor(input, key);
      setTemplateTextFieldValue(input.value);
    }
  };

  const getCursorPosition = (input: HTMLInputElement) => {
    if ('selectionStart' in input) {
      return input.selectionStart || 0;
    }
    return 0;
  };

  const handleOnChangeTextAria = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTemplateTextFieldValue(value as string);
    setValue(name as FormFields, value);
  };

  // open/close modal
  const handleOpenModal = () => {
    const renderedText = Mustache.render(
      mastecheValues.templateTextFieldValue,
      mapMastecheValues(mastecheList),
    );
    setRenderedTextFieldValue(renderedText);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Grid item xs={12} lg={12}>
      <Box>
        <CustomFormLabel
          sx={{
            mt: '20px',
            fontWeight: 500,
          }}
          htmlFor="campaign-template">
          {t('campaignPage.template')}
        </CustomFormLabel>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'start',
            alignItems: 'start',
          }}>
          {mastecheList.map((value) => (
            <StyledMastecheButton
              key={value.key}
              onClick={() => handleAddMastecheValue(value.key)}
              color="primary"
              variant="text">
              {`add${wrapMastecheTags(value.key)}`}
            </StyledMastecheButton>
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ...(formStateErrors.followUpTemplate
              ? {
                  '& fieldset': {
                    borderColor: `${theme.palette.error.main} !important`,
                  },
                }
              : {}),
          }}>
          <Controller
            control={control}
            name="followUpTemplate"
            rules={{ required: requireTemplateTextField }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                sx={{ marginRight: '10px' }}
                value={field.value || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleOnChangeTextAria(e);
                  field.onChange(e.target.value);
                }}
                id="campaign-template"
                variant="outlined"
                fullWidth
                required
                multiline
                rows={5}
              />
            )}
          />
          {formStateErrors.followUpTemplate && (
            <StyledErrorText>{t('adminMainPage.errors.fillTheField')}</StyledErrorText>
          )}
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              sx={{ mt: '20px' }}>
              {t('campaignPage.preview')}
            </Button>
          </Box>
        </Box>
        <InfoDialog
          showDialogFlag={openModal}
          handleClose={handleCloseModal}
          dialogTitle={t('campaignPage.preview')}
          dialogContentText={renderedTextFieldValue}
          confirmDialog={handleCloseModal}
        />
      </Box>
    </Grid>
  );
};
