import dayjs from 'dayjs';

import {
  FormControlLabel,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import 'dayjs/locale/en';

import CustomCheckbox from '@shared/components/forms/theme-elements/CustomCheckbox';
import { useStyles } from './styles';
import { CallScheduleRequestDto } from '@shared/services/apiService/apiService';
import { useFormContext } from 'react-hook-form';
import { CreateCampaignFormValue } from '@features/Admin/Campaign/model/type';
import CustomSelect from '@shared/components/forms/theme-elements/CustomSelect';
import { IconChevronDown } from '@shared/assets/images/svgs';
import { t } from 'i18next';
import { keys } from 'lodash';
import { useEffect } from 'react';

interface ScheduleSelectProps {
  schedule: CallScheduleRequestDto;
  setSchedule: (value: CallScheduleRequestDto) => void;
}

export const ScheduleSelect: React.FC<ScheduleSelectProps> = ({
  schedule,
  setSchedule,
}: ScheduleSelectProps) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const {
    formState: { errors: formStateErrors },
    setError,
    clearErrors,
  } = useFormContext<CreateCampaignFormValue>();

  const week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const timezones = ['Asia/Dubai'];

  const getTimeFromSchedule = (day: string, type: 'Start' | 'End', extended: boolean = false) => {
    const dayKey = day.toLowerCase() as keyof CallScheduleRequestDto;
    const key = type === 'Start' ? `${dayKey}Start` : `${dayKey}End`;

    if (key in schedule) {
      return schedule[key as keyof CallScheduleRequestDto];
    }

    return extended ? '09:00' : '9:00:00';
  };

  const handleTimeChange = (day: string, period: 'Start' | 'End', newValue: any) => {
    setSchedule({
      ...schedule,
      [`${day.toLowerCase()}${period}` as keyof CallScheduleRequestDto]: newValue
        ? newValue.format('HH:mm:ss')
        : null,
    });
  };

  const handleChangeTimezone = (timezone: string) => {
    if (timezone) {
      setSchedule({
        ...schedule,
        timezone,
      });
    }
  };

  const validateSchedule = (schedule: CallScheduleRequestDto) => {
    const isScheduleValid = keys(schedule).some(
      (key) => key !== 'timezone' && schedule[key as keyof CallScheduleRequestDto] !== null,
    );
    if (!isScheduleValid) {
      setError('callSchedule', { type: 'required' });
    } else if (Boolean(formStateErrors.callSchedule)) {
      clearErrors('callSchedule');
    }
  };

  useEffect(() => {
    validateSchedule(schedule);
  }, [schedule]);

  return (
    <>
      <TableContainer>
        <Table
          className={classes.tableV2}
          sx={{
            ...(formStateErrors.callSchedule
              ? {
                  borderColor: `${theme.palette.error.main} !important`,
                }
              : {}),
          }}>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.table_header_cellV2}
                sx={{
                  paddingLeft: '16px!important',
                }}>
                <Typography className={classes.columnNameV2} variant="h6">
                  Week
                </Typography>
              </TableCell>
              <TableCell className={classes.table_header_cellV2}>
                <Typography className={classes.columnNameV2} variant="h6">
                  From
                </Typography>
              </TableCell>
              <TableCell className={classes.table_header_cellV2}>
                <Typography className={classes.columnNameV2} variant="h6">
                  To
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {week.map((day) => {
              return (
                <TableRow key={day}>
                  <TableCell className={classes.table_row_cellV2}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          checked={
                            Boolean(
                              schedule[`${day.toLowerCase()}Start` as keyof CallScheduleRequestDto],
                            ) &&
                            Boolean(
                              schedule[`${day.toLowerCase()}End` as keyof CallScheduleRequestDto],
                            )
                          }
                          highlighted={Boolean(formStateErrors.callSchedule)}
                          onChange={(event) => {
                            const isChecked = event.target.checked;
                            const newSchedule = {
                              ...schedule,
                              [`${day.toLowerCase()}Start`]: isChecked
                                ? getTimeFromSchedule(day, 'Start') || '09:00:00'
                                : null,
                              [`${day.toLowerCase()}End`]: isChecked
                                ? getTimeFromSchedule(day, 'End') || '18:00:00'
                                : null,
                            };
                            setSchedule(newSchedule);
                            validateSchedule(newSchedule);
                          }}
                        />
                      }
                      label={day}
                    />
                  </TableCell>
                  <TableCell className={classes.table_row_middle_cellV2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                      <DemoContainer components={['TimePicker']}>
                        <DesktopTimePicker
                          sx={{ width: '100%' }}
                          format="HH:mm"
                          value={dayjs(
                            `2022-04-17T${getTimeFromSchedule(day, 'Start') || '09:00'}`,
                          )}
                          onChange={(newValue) => {
                            return handleTimeChange(day, 'Start', newValue);
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </TableCell>
                  <TableCell className={classes.table_row_last_cellV2} sx={{}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                      <DemoContainer components={['TimePicker']}>
                        <DesktopTimePicker
                          sx={{ width: '100%' }}
                          format="HH:mm"
                          value={dayjs(`2022-04-17T${getTimeFromSchedule(day, 'End') || '18:00'}`)}
                          onChange={(newValue) => {
                            return handleTimeChange(day, 'End', newValue);
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell>
                <Typography fontWeight={600}>{t('campaignPage.timezone')}:</Typography>
              </TableCell>
              <TableCell colSpan={2} sx={{ paddingLeft: 0 }}>
                <CustomSelect
                  labelId="schedule-timezone"
                  name="scheduleTimezone"
                  onChange={handleChangeTimezone}
                  id="campaign-type-id"
                  disabled={false}
                  value={timezones[0]}
                  IconComponent={IconChevronDown}
                  className={classes.call_schedule_timezone_select}>
                  {timezones.map((timezone) => {
                    return (
                      <MenuItem key={timezone} value={timezone}>
                        {timezone}
                      </MenuItem>
                    );
                  })}
                </CustomSelect>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
