import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@shared/store';

import { SuperAdminAuthenticatedPath } from '@shared/constants/routes.constants';
import { useAdminSignInControllerSignInMutation } from '@shared/services/apiService/apiService';
import { actions } from '@shared/store/ducks';
import { SignInFormValues as SignSuperAdminInFormValues } from '@pages/SuperAdminSignIn/types';

export const useSuperAdminSignIn = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const [signInSuperAdmin] = useAdminSignInControllerSignInMutation();
  const { handleSubmit, setValue, ...formMethods } = useForm<SignSuperAdminInFormValues>({
    mode: 'onTouched',
    defaultValues: {
      login: '',
      password: '',
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    const res: any = await signInSuperAdmin({
      adminSignInRequestDto: {
        login: data.login,
        password: data.password,
      },
    });

    if (res.error) {
      if (res.error.data) {
        setErrorMsg(res.error.data.message);
      } else {
        setErrorMsg('An unknown error occurred.');
      }
    } else {
      dispatch(
        actions.auth.signInSuperAdmin({
          refreshToken: res.data.accessToken.refreshToken,
          accessToken: res.data.accessToken.accessToken,
        }),
      );
      navigate(SuperAdminAuthenticatedPath.COMPANIES);
    }
  });

  return {
    setValue,
    onSubmit,
    errorMsg,
    ...formMethods,
  };
};
