import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Grid, Box } from '@mui/material';

import PageContainer from '@shared/components/container/PageContainer';

import EditCampaignSidebar from './EditCampaignSidebar/EditCampaignSidebar';
import { DEBOUNCED_SEND_INTERVAL, SidebarTabNames } from './constants';
import { SidebarTabNamesType } from './types';
import GeneralTab from './SidebarTabs/GeneralTab';
import OngoingCallsTab from './SidebarTabs/OngoingCallsTab';
import {
  CallScheduleDto,
  CampaignDto,
  useCampaignRunControllerCampaignRunMutation,
  useCampaignStopControllerCampaignStopMutation,
  useCampaignControllerCampaignQuery,
  useGetCompanyControllerGetCompanyQuery,
  CampaignAgentDto,
} from '@shared/services/apiService/apiService';
import AgentTab from './SidebarTabs/AgentTab';
import PhoneNumbersTab from './SidebarTabs/PhoneNumbersTab';
import EndCallTab from './SidebarTabs/EndCallTab';
import { useAppDispatch } from '@shared/store';
import { actions } from '@shared/store/ducks';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CampaignFieldsError, FormFields } from '@shared/types/admin/campaign';
import { debounce, isEqual, keys, omit, pick, uniq } from 'lodash';
import { useEditCampaign } from '@features/Admin/Campaign/hooks/useEditCampaign';
import { CampaignStatus } from '@pages/Admin/Main/Campaign/ui/CampaignStatusButton';
import { getNextAvailableCallTimestamp, isOutOfSchedule } from '@shared/utils/DateUtil';
import { ConfirmDialog } from '@shared/components/confirmDialog/ConfirmDialog';
import { formatTime } from '@shared/utils/FormatTime';
import { getCampaignStatus } from '@pages/Admin/Main/Campaign/ui/CampaignStatusButton/utils';
import { useTranslation } from 'react-i18next';
import { useStartStopContext } from '@features/Admin/Campaign/contexts/useStartStop.context';
import { FormProvider, useFormState, useWatch } from 'react-hook-form';
import { getSidebarTabName } from './utils';
import { CampaignTypeEnum } from '@shared/constants/campaign.constants';

export const EditCampaignPage: React.FC = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams<{ campaignId: string }>();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setStartStopFunction, setCampaignContext, setIsDisabledCampaignRun } =
    useStartStopContext();

  const [activeTab, setActiveTab] = useState<SidebarTabNamesType>(SidebarTabNames.GENERAL);
  const [issuedTabs, setIssuedTabs] = useState<SidebarTabNamesType[]>([]);
  const [leadListChanged, setLeadListChanged] = useState(false);
  const [showScheduleWarningDilaogFlag, setShowScheduleWarningDialogFlag] = useState(false);
  const [timeToStart, setTimeToStart] = useState<number>(new Date().getTime());
  const [showCountDownFlag, setShowCountDownFlag] = useState(false);
  const [campaignFieldError, setCampaignFieldError] = useState<CampaignFieldsError>({
    retryIntervalInMinutes: [],
    retryMaxNumber: [],
    retriesPerDay: [],
    editErrorMessage: '',
  });

  const {
    data: campaignData,
    refetch,
    isLoading,
  } = useCampaignControllerCampaignQuery(
    { id: campaignId! },
    {
      skip: !campaignId,
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: company, isSuccess: isCompanyFetched } = useGetCompanyControllerGetCompanyQuery(
    { id: (campaignData as unknown as CampaignDto)?.companyId },
    { skip: !(campaignData as unknown as CampaignDto)?.companyId },
  );

  const leadListChangedRef = useRef(leadListChanged);

  useEffect(() => {
    if (leadListChangedRef) leadListChangedRef.current = leadListChanged;
  }, [leadListChanged]);

  const { onSubmit, formMethods } = useEditCampaign({
    setCampaignFieldError,
    leadListChanged: leadListChangedRef,
    leadList: campaignData?.leadList || [],
  });

  const { control, reset, setError, clearErrors, trigger } = formMethods;

  const { errors: formStateErrors } = useFormState({ control });

  const [useRunCampaign, { isLoading: isRunningLoading }] =
    useCampaignRunControllerCampaignRunMutation();

  const [useStopCampaign, { isLoading: isStoppingLoading }] =
    useCampaignStopControllerCampaignStopMutation();

  const formData = useWatch({ control });

  const debouncedSubmit = useCallback(
    debounce(async (values) => {
      await onSubmit().then((data) => {
        setLeadListChanged(false);
        refetch();
      });
    }, DEBOUNCED_SEND_INTERVAL),
    [],
  );

  const handleManagedStartStop = async (
    id: string,
    callSchedule: CallScheduleDto | undefined,
    campaignStatus: CampaignStatus,
  ) => {
    if (CampaignStatus.NotStarted === campaignStatus) {
      const canRun = callSchedule && !isOutOfSchedule(callSchedule);
      if (canRun) {
        await useRunCampaign({ id });
      } else {
        const nextAvailableCallTimestamp = getNextAvailableCallTimestamp(
          campaignData?.callSchedule,
        );
        if (nextAvailableCallTimestamp) {
          setTimeToStart(nextAvailableCallTimestamp);
          setShowScheduleWarningDialogFlag(true);
        }
      }
    } else if (CampaignStatus.Dialing === campaignStatus) {
      await useStopCampaign({ id });
      setShowCountDownFlag(false);
    }

    refetch();
  };

  const runCampaign = async () => {
    setShowCountDownFlag(true);
    closeDialog();
    if (campaignData?.id) {
      await useRunCampaign({ id: campaignData?.id });
    }

    refetch();
  };

  const forceValidate = () => {
    const isInbound = campaignData?.type === CampaignTypeEnum.INBOUND;

    const validations = {
      agents: (value?: CampaignAgentDto[] | null) => !value?.length,
      followUpTemplate: (value?: string | null) =>
        formData.sendFollowUpMessage ? !value?.length : false,
    };

    Object.entries(validations).forEach(([field, isInvalid]) => {
      if (isInvalid(formData[field as never])) {
        setError(field as FormFields, { type: 'required' });
      } else {
        clearErrors(field as FormFields);
      }
    });
  };

  const closeDialog = () => {
    setShowScheduleWarningDialogFlag(false);
  };

  useEffect(() => {
    setStartStopFunction(handleManagedStartStop);
  }, []);

  useEffect(() => {
    setIsDisabledCampaignRun(
      isRunningLoading || isLoading || isStoppingLoading || Boolean(keys(issuedTabs).length),
    );
  }, [isRunningLoading, isLoading, isStoppingLoading, issuedTabs]);

  useEffect(() => {
    const campaignStatus = getCampaignStatus(campaignData?.campaignRun);
    const nextAvailableCallTimestamp = getNextAvailableCallTimestamp(campaignData?.callSchedule);
    const showTimer =
      nextAvailableCallTimestamp &&
      CampaignStatus.NotStarted !== campaignStatus &&
      isOutOfSchedule(campaignData?.callSchedule!);

    if (showTimer) {
      setTimeToStart(nextAvailableCallTimestamp);
      setShowCountDownFlag(true);
    }

    if (campaignData) {
      setCampaignContext(campaignData);
    }

    return () => {
      setShowCountDownFlag(false);
    };
  }, [campaignData]);

  useEffect(() => {
    dispatch(actions.breadcrumbs.setIsShownBreadcrumbs(true));
    dispatch(
      actions.breadcrumbs.setBreadcrumb({
        pageTitle: 'campaignPage.header.campaignName',
        titleValue: { campaignName: campaignData?.title },
        campaigntype: campaignData?.type,
      }),
    );

    return () => {
      dispatch(actions.breadcrumbs.setIsShownBreadcrumbs(false));
    };
  }, [dispatch, campaignData]);

  useEffect(() => {
    dispatch(actions.customizer.toggleLayout('full'));
    dispatch(actions.customizer.toggleEditCampaignView(true));
    dispatch(actions.customizer.setCollapse(true));

    return () => {
      dispatch(actions.customizer.toggleLayout('boxed'));
      dispatch(actions.customizer.toggleEditCampaignView(false));
    };
  }, [dispatch]);

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      setActiveTab(tab);
    } else {
      navigate(`${location.pathname}?tab=${encodeURIComponent(SidebarTabNames.GENERAL)}`, {
        replace: true,
      });
    }
  }, [searchParams]);

  useEffect(() => {
    if (!campaignData) return;

    reset({
      id: campaignData.id || '',
      title: campaignData.title || '',
      agents: campaignData.agents || [],
      callSchedule: campaignData.callSchedule,
      retryIntervalInMinutes: campaignData.retryIntervalInMinutes || 0,
      retryMaxNumber: campaignData.retryMaxNumber || 0,
      retriesPerDay: campaignData.retriesPerDay || 0,
      followUpTemplate: campaignData.followUpTemplate,
      sendFollowUpMessage: campaignData.sendFollowUpMessage,
      productDetails: campaignData.productDetails,
      leadList: campaignData.leadList,
    });
  }, [campaignData, reset]);

  useEffect(() => {
    if (campaignData) {
      trigger().then(() => {
        forceValidate();
        const errorFields = Object.keys(formStateErrors);
        const formHasErrors = Boolean(errorFields.length);

        if (formHasErrors) {
          setIssuedTabs([]);
          errorFields.forEach((field) => {
            const tab = getSidebarTabName(field);
            setIssuedTabs((prev) => uniq([...prev, tab]));
          });
        } else {
          setIssuedTabs((prev) => (prev.length > 0 ? [] : prev));
        }

        const omittedFormData = omit(formData, [
          'id',
          'leadList',
          'updatedAt',
          'createdAt',
          'description',
          'campaignRun',
          'companyId',
        ]);
        if (leadListChanged) {
          omittedFormData.leadList = formData.leadList;
        }

        const changedFields = pick(campaignData, keys(omittedFormData));

        if (formData.id && !isEqual(changedFields, omittedFormData)) {
          debouncedSubmit(omittedFormData);
        }
      });
    }

    return () => debouncedSubmit.cancel();
  }, [formData]);

  const timeUntilStartCampaignRun = (timeToStart - new Date().getTime()) / 1000;

  return (
    <PageContainer>
      <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        <EditCampaignSidebar
          activeTab={activeTab}
          issuedTabs={issuedTabs}
          campaignType={campaignData?.type}
        />
        <Grid container>
          <FormProvider {...formMethods}>
            {activeTab === SidebarTabNames.GENERAL && <GeneralTab campaign={campaignData} />}
            {activeTab === SidebarTabNames.AGENT ? (
              <AgentTab
                isLoading={isLoading}
                refetch={refetch}
                agents={campaignData?.agents || []}
              />
            ) : null}
            {activeTab === SidebarTabNames.PHONE_NUMBERS &&
            campaignData?.type === CampaignTypeEnum.OUTBOUND ? (
              <PhoneNumbersTab
                campaign={campaignData}
                refetch={refetch}
                setLeadListChanged={setLeadListChanged}
              />
            ) : null}
            {activeTab === SidebarTabNames.END_CALL && campaignData?.title && isCompanyFetched ? (
              <EndCallTab
                defaultFollowUpTemplate={campaignData?.followUpTemplate}
                campaignName={campaignData?.title}
                companyName={company.name}
                agentName={'Agent'}
              />
            ) : null}
            {activeTab === SidebarTabNames.ONGOING_CALLS &&
            campaignData?.campaignRun &&
            campaignId ? (
              <OngoingCallsTab
                campaignId={campaignId}
                campaignRunData={campaignData?.campaignRun}
                campaignType={campaignData.type}
              />
            ) : null}
          </FormProvider>
        </Grid>
      </Box>
      <ConfirmDialog
        showDialogFlag={showScheduleWarningDilaogFlag}
        handleClose={closeDialog}
        dialogTitle={'dialog.title4'}
        dialogContentText={t('dialog.content4', {
          timeToStart: formatTime(timeUntilStartCampaignRun, false),
        })}
        showDialog={setShowScheduleWarningDialogFlag}
        confirmDialog={runCampaign}
      />
    </PageContainer>
  );
};
