// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

interface CustomFormLabelProps {
  htmlFor?: string;
  required?: boolean;
  children: React.ReactNode;
}

const CustomFormLabelV2 = styled(({ required, children, ...props }: CustomFormLabelProps) => (
  <Typography variant="subtitle1" fontWeight={500} component="label" {...props}>
    {children}
    {required && <span> *</span>}
  </Typography>
))(({ theme }) => ({
  marginBottom: '8px',
  display: 'block',
  '& span': {
    color: theme.palette.error.main,
  },
}));

export default CustomFormLabelV2;
