import React from 'react';
import { TextField, Typography, Box } from '@mui/material';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateCampaignFormValue } from '@features/Admin/Campaign/model/type';

const AgentProductDetailsInput: React.FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { control } = useFormContext<CreateCampaignFormValue>();

  return (
    <Box className={classes.main}>
      <Typography className={classes.label} variant="h6">
        Product details
      </Typography>
      <Controller
        control={control}
        name="productDetails"
        render={({ field }) => (
          <TextField
            {...field}
            id="product-details"
            label={t('campaignPage.placeholders.addProductDetails')}
            value={field.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              field.onChange(e.target.value);
            }}
            multiline
            rows={4}
            variant="outlined"
            fullWidth
          />
        )}
      />
    </Box>
  );
};

export default AgentProductDetailsInput;
