import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  CircularProgress,
  FormHelperText,
} from '@mui/material';
import {
  useAgentProfileCreateControllerAgentProfileCreateMutation,
  useAgentProfileDeleteControllerAgentProfileDeleteMutation,
  useAgentProfileEditControllerAgentProfileEditMutation,
  useAgentProfileListControllerAgentProfileListForAdminsQuery,
} from '@shared/services/apiService/apiService';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

interface AgentProfilesTabContentProps {
  companyId?: string;
}

interface AgentProfileFormData {
  name: string;
  prompt: string;
}

interface FormErrors {
  name?: string;
  prompt?: string;
}

export const AgentProfilesTabContent: React.FC<AgentProfilesTabContentProps> = ({ companyId }) => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [currentProfile, setCurrentProfile] = useState<any>(null);
  const [formData, setFormData] = useState<AgentProfileFormData>({
    name: '',
    prompt: '',
  });
  const [errors, setErrors] = useState<FormErrors>({});

  const { t } = useTranslation();

  const translations = {
    prompt: t('superAdminMainPage.companies.profiles.prompt'),
    profileName: t('superAdminMainPage.companies.profiles.profileName'),
    agentProfiles: t('superAdminMainPage.companies.profiles.agentProfiles'),
    createNewProfile: t('superAdminMainPage.companies.profiles.createNewProfile'),
    noAgentProfilesFound: t('superAdminMainPage.companies.profiles.noAgentProfilesFound'),
    name: t('superAdminMainPage.companies.profiles.name'),
    actions: t('superAdminMainPage.companies.profiles.actions'),
    createAgentProfile: t('superAdminMainPage.companies.profiles.createAgentProfile'),
    editAgentProfile: t('superAdminMainPage.companies.profiles.editAgentProfile'),
    deleteAgentProfile: t('superAdminMainPage.companies.profiles.deleteAgentProfile'),
    cancel: t('superAdminMainPage.companies.profiles.cancel'),
    create: t('superAdminMainPage.companies.profiles.create'),
    save: t('superAdminMainPage.companies.profiles.save'),
    delete: t('superAdminMainPage.companies.profiles.delete'),
    nameRequired: t('superAdminMainPage.companies.profiles.nameRequired'),
    promptRequired: t('superAdminMainPage.companies.profiles.promptRequired'),
    deleteConfirmation: t('superAdminMainPage.companies.profiles.deleteConfirmation'),
  };

  const {
    data: agentProfiles,
    isLoading,
    refetch,
  } = useAgentProfileListControllerAgentProfileListForAdminsQuery(
    { id: companyId || '' },
    { skip: !companyId },
  );

  const [createAgentProfile, { isLoading: isCreating }] =
    useAgentProfileCreateControllerAgentProfileCreateMutation();
  const [editAgentProfile, { isLoading: isEditing }] =
    useAgentProfileEditControllerAgentProfileEditMutation();
  const [deleteAgentProfile, { isLoading: isDeleting }] =
    useAgentProfileDeleteControllerAgentProfileDeleteMutation();

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = translations.nameRequired;
    }

    if (!formData.prompt.trim()) {
      newErrors.prompt = translations.promptRequired;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleOpenCreateDialog = () => {
    setFormData({ name: '', prompt: '' });
    setErrors({});
    setOpenCreateDialog(true);
  };

  const handleOpenEditDialog = (profile: any) => {
    setCurrentProfile(profile);
    setFormData({
      name: profile.name || '',
      prompt: profile.prompt || '',
    });
    setErrors({});
    setOpenEditDialog(true);
  };

  const handleOpenDeleteConfirm = (profile: any) => {
    setCurrentProfile(profile);
    setOpenDeleteConfirm(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    // Clear error when user types
    if (errors[name as keyof FormErrors]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleCreateProfile = async () => {
    if (!companyId || !validateForm()) return;

    try {
      await createAgentProfile({
        id: companyId,
        agentProfileCreateRequestDto: {
          name: formData.name,
          prompt: formData.prompt,
        },
      }).unwrap();
      setOpenCreateDialog(false);
      refetch();
    } catch (error) {
      console.error('Failed to create agent profile:', error);
    }
  };

  const handleEditProfile = async () => {
    if (!currentProfile?.id || !validateForm()) return;

    try {
      await editAgentProfile({
        id: companyId || '',
        profileId: currentProfile.id,
        agentProfileEditRequestDto: {
          name: formData.name,
          prompt: formData.prompt,
        },
      }).unwrap();
      setOpenEditDialog(false);
      refetch();
    } catch (error) {
      console.error('Failed to edit agent profile:', error);
    }
  };

  const handleDeleteProfile = async () => {
    if (!currentProfile?.id) return;

    try {
      await deleteAgentProfile({ id: companyId || '', profileId: currentProfile.id }).unwrap();
      setOpenDeleteConfirm(false);
      refetch();
    } catch (error) {
      console.error('Failed to delete agent profile:', error);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">{translations.agentProfiles}</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenCreateDialog}
          disabled={!companyId}>
          {translations.createNewProfile}
        </Button>
      </Box>

      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : !agentProfiles || agentProfiles.length === 0 ? (
        <Typography variant="body1" sx={{ textAlign: 'center', my: 4 }}>
          {translations.noAgentProfilesFound}
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translations.name}</TableCell>
                <TableCell>{translations.actions}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agentProfiles.map((profile) => (
                <TableRow key={profile.id}>
                  <TableCell>{profile.name}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenEditDialog(profile)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteConfirm(profile)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Create Dialog */}
      <Dialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        maxWidth="sm"
        fullWidth>
        <DialogTitle>{translations.createAgentProfile}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label={translations.profileName}
            type="text"
            fullWidth
            variant="outlined"
            value={formData.name}
            onChange={handleInputChange}
            error={!!errors.name}
            required
            sx={{ mb: errors.name ? 0.5 : 2 }}
          />
          {errors.name && <FormHelperText error>{errors.name}</FormHelperText>}
          <TextField
            margin="dense"
            name="prompt"
            label={translations.prompt}
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={30}
            value={formData.prompt}
            onChange={handleInputChange}
            error={!!errors.prompt}
            required
            sx={{ mt: 2 }}
          />
          {errors.prompt && <FormHelperText error>{errors.prompt}</FormHelperText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreateDialog(false)}>{translations.cancel}</Button>
          <Button
            onClick={handleCreateProfile}
            variant="contained"
            color="primary"
            disabled={isCreating}>
            {isCreating ? <CircularProgress size={24} /> : translations.create}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        maxWidth="md"
        fullWidth>
        <DialogTitle>{translations.editAgentProfile}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label={translations.profileName}
            type="text"
            fullWidth
            variant="outlined"
            value={formData.name}
            onChange={handleInputChange}
            error={!!errors.name}
            required
            sx={{ mb: errors.name ? 0.5 : 2 }}
          />
          {errors.name && <FormHelperText error>{errors.name}</FormHelperText>}
          <TextField
            margin="dense"
            name="prompt"
            label={translations.prompt}
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={30}
            value={formData.prompt}
            onChange={handleInputChange}
            error={!!errors.prompt}
            required
            sx={{ mt: 2 }}
          />
          {errors.prompt && <FormHelperText error>{errors.prompt}</FormHelperText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)}>{translations.cancel}</Button>
          <Button
            onClick={handleEditProfile}
            variant="contained"
            color="primary"
            disabled={isEditing}>
            {isEditing ? <CircularProgress size={24} /> : translations.save}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteConfirm} onClose={() => setOpenDeleteConfirm(false)}>
        <DialogTitle>{translations.deleteAgentProfile}</DialogTitle>
        <DialogContent>
          <Typography>
            {translations.deleteConfirmation.replace('{name}', currentProfile?.name || '')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteConfirm(false)}>{translations.cancel}</Button>
          <Button
            onClick={handleDeleteProfile}
            variant="contained"
            color="error"
            disabled={isDeleting}>
            {isDeleting ? <CircularProgress size={24} /> : translations.delete}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
