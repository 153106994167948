import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import {
  CampaignAgentDto,
  useCampaignUpdateControllerCampaignUpdateMutation,
} from '@shared/services/apiService/apiService';
import { CreateAgentFormValues } from '../model/types';
import { ErrorData, ResponseMsg } from '@shared/types/admin/agent';

export const useCreateAgent = (campaignId: string, agents: CampaignAgentDto[]) => {
  const [useEditCampaign, { isLoading: isEditLoading }] =
    useCampaignUpdateControllerCampaignUpdateMutation();
  const isLoading = isEditLoading;

  const [responseMsg, setResponseMsg] = useState<ResponseMsg>({
    message: '',
    type: 'success',
  });

  const { handleSubmit, setValue, reset, ...formMethods } = useForm<CreateAgentFormValues>({
    mode: 'all',
    defaultValues: {
      id: '',
      name: '',
      voiceId: '',
    },
  });

  function handleErrResponse(error: FetchBaseQueryError | SerializedError) {
    if ('data' in error && error.data) {
      const errorData = error.data as ErrorData;
      setResponseMsg({
        message: errorData.message,
        type: 'error',
      });
      return;
    }
  }

  const onSubmit = handleSubmit(async (data) => {
    setResponseMsg({
      message: '',
      type: 'success',
    });

    try {
      const agentData = {
        name: data.name,
        voiceId: data.voiceId,
        profileId: data.profile,
        languages: [data.accent === 'arabic' ? 'arb' : 'eng'],
      };

      const updatedAgents = agents.map((agent) => ({
        ...agent,
        languages: [agent.voice?.accent === 'arabic' ? 'arb' : 'eng'],
      }));

      let response;
      if (data.id) {
        response = await useEditCampaign({
          id: campaignId,
          campaignUpdateRequestDto: {
            agents: [
              ...updatedAgents.filter(({ id }) => id !== data.id),
              { ...agentData, id: data.id },
            ],
          },
        });
      } else {
        response = await useEditCampaign({
          id: campaignId,
          campaignUpdateRequestDto: {
            agents: [...updatedAgents, agentData],
          },
        });
      }

      if ('error' in response) {
        const { error } = response;
        if (error) {
          handleErrResponse(error);
        }
      } else if ('data' in response) {
        // setAgent(response.data);
        setResponseMsg({
          message: data.id ? 'Successfully Edited' : 'Successfully Saved',
          type: 'success',
        });
      }
    } catch (error) {
      console.log('Creating agent failed:', error);
      setResponseMsg({
        message: 'Something Went wrong',
        type: 'error',
      });
    }
  });

  return {
    setValue,
    reset,
    onSubmit,
    responseMsg,
    isLoading,
    ...formMethods,
  };
};
