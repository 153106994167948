import { Navigate, Route, Routes } from 'react-router-dom';
import { CampaignPage, CreateAgentPage, EditCampaignPage } from '@pages/Admin';
import { AuthenticatedPath } from '@shared/constants/routes.constants';
import { FullLayout } from '@shared/layouts';
import { UserLoader } from './UserLoader';

export const AuthorizedRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<FullLayout />}>
        <Route element={<UserLoader />}>
          <Route path="/" element={<CampaignPage />} />
          <Route path={AuthenticatedPath.CAMPAIGN} element={<CampaignPage />} />
          {/* <Route path={AuthenticatedPath.AGENT} element={<AgentPage />} /> */}
          {/* <Route path={AuthenticatedPath.CREATE_AGENT} element={<CreateAgentPage />} /> */}
          <Route path={AuthenticatedPath.EDIT_AGENT} element={<CreateAgentPage />} />
          <Route path={AuthenticatedPath.EDIT_CAMPAIGN_V2} element={<EditCampaignPage />} />
          <Route path="*" element={<Navigate to={AuthenticatedPath.CAMPAIGN} replace />} />
        </Route>
      </Route>
    </Routes>
  );
};
