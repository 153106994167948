import React, { useEffect, useState } from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';
import {
  CompanyDto,
  useEditCompanyControllerEditCompanyMutation,
} from '@shared/services/apiService/apiService';

interface EditCompanyPopupProps {
  company: CompanyDto;
  showDialogFlag: boolean;
  onCompanyEditSuccess: () => void;
  closeDialog: () => void;
}

export const EditCompanyPopup: React.FC<EditCompanyPopupProps> = ({
  company,
  onCompanyEditSuccess,
  showDialogFlag,
  closeDialog,
}) => {
  const [companyData, setCompanyData] = useState<any>(company);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [editCompany] = useEditCompanyControllerEditCompanyMutation();

  const companyId = company.id;

  useEffect(() => {
    setCompanyData(company);
  }, [company]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyData({
      ...companyData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    try {
      await editCompany({ id: companyId, editCompanyRequestDto: companyData }).unwrap();
      onCompanyEditSuccess?.();
      closeDialog();
    } catch (error) {
      setErrors((error as any).data);
    }
  };

  return (
    <Modal open={showDialogFlag} onClose={closeDialog}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
        <Typography variant="h6" component="h2">
          Edit Company
        </Typography>
        {companyData && (
          <>
            <TextField
              margin="normal"
              fullWidth
              label="Name"
              name="name"
              value={companyData.name || ''}
              onChange={handleInputChange}
              error={Boolean(errors?.name)}
              helperText={errors?.name}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Industry Type"
              name="industryType"
              value={companyData.industryType || ''}
              onChange={handleInputChange}
              error={Boolean(errors?.industryType)}
              helperText={errors?.industryType}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Description"
              name="description"
              value={companyData.description || ''}
              onChange={handleInputChange}
              error={Boolean(errors?.description)}
              helperText={errors?.description}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Address"
              name="address"
              value={companyData.address || ''}
              onChange={handleInputChange}
              error={Boolean(errors?.address)}
              helperText={errors?.address}
            />
            <Button onClick={handleSave} variant="contained" color="primary" sx={{ mt: 2 }}>
              Save
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};
