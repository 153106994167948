import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { t } from 'i18next';
import { Paper, styled, useTheme } from '@mui/material';

interface ConfirmDialogProps {
  showDialogFlag: boolean;
  handleClose: (value: React.SetStateAction<boolean>) => void;
  dialogTitle: string;
  dialogContentText: string;
  confirmDialog: () => void | Promise<void>;
}
const StyledDialogPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  padding: '30px',
}));

export const InfoDialog: React.FC<ConfirmDialogProps> = ({
  showDialogFlag,
  handleClose,
  dialogTitle,
  dialogContentText,
  confirmDialog,
}) => {
  const theme = useTheme();
  return (
    <Dialog
      open={showDialogFlag}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      PaperComponent={StyledDialogPaper}
      // TODO: "backdropFilter" may not work in firefox
      sx={{
        backdropFilter: 'blur(5px)',
      }}>
      <DialogTitle id="alert-dialog-title" sx={{ p: '0 0 20px 0' }}>
        {t(dialogTitle)}
      </DialogTitle>
      <DialogContent sx={{ p: '20px 0px', overflowWrap: 'break-word' }}>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ color: theme.palette.extendedGrey?.darker }}>
          {dialogContentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          p: 0,
          display: 'flex',
          flexDirection: 'row-reverse',
        }}>
        <Button onClick={confirmDialog} variant="contained" sx={{ minWidth: '100px' }}>
          {t('adminMainPage.operation.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
