import React from 'react';
import { Box, Button } from '@mui/material';
import { useStyles } from './styles';

import { ReactComponent as SpreadSheetTable } from '@shared/assets/images/agent/file-spreadsheet.svg';
import { useTranslation } from 'react-i18next';

const AddAgentFilesInput: React.FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Button
        variant="contained"
        className={classes.button}
        component="label"
        style={{ marginTop: '16px' }}>
        <SpreadSheetTable />
        {t('campaignPage.placeholders.addFiles')}
        <input type="file" hidden />
      </Button>
    </Box>
  );
};

export default AddAgentFilesInput;
