export const AuthenticatedPath = {
  HOME: '/home',
  AGENT: '/admin/agent',
  CAMPAIGN: '/admin/campaign',
  CREATE_AGENT: '/admin/agent/new',
  CREATE_CAMPAIGN: '/admin/campaign/new',
  EDIT_AGENT: '/admin/agent/edit',
  EDIT_CAMPAIGN: '/admin/campaign/edit',
  EDIT_CAMPAIGN_V2: '/admin/campaign/:campaignId/edit',
} as const;

export const UnauthenticatedPath = {
  SIGN_IN: '/sign-in',
  FORGOT: '/forgot-password',
  VERIFY: '/verify-code',
  RESET: '/reset-password',
} as const;

export const AuthPath = {
  NOT_FOUND: `/auth/404`,
  SIGN_IN: '/auth/sign-in',
} as const;

export const SuperAdminAuthenticatedPath = {
  HOME_PAGE: '/super-admin/home',
  COMPANIES: '/super-admin/companies',
  EDIT_COMPANY: '/super-admin/company/edit',
  MONITOR_CONVERSATION: '/super-admin/conversation/monitor',
  MONITOR_TOKENS: '/super-admin/tokens-monitoring',
  MANAGE_VOICE_SETTINGS: '/super-admin/voice-settings',
} as const;

export const SuperAdminUnauthenticatedPath = {
  SIGN_IN: '/super-admin/sign-in',
} as const;

export const SuperAdminAuthPath = {
  NOT_FOUND: `/admin/auth/404`,
  SIGN_IN: '/admin/sign-in',
} as const;
