import { useInboundConversationListControllerInboundConversationQuery } from '@shared/services/apiService/apiService';

export const useGetInboundRecipient = (campaignId?: string) => {
  const {
    data: inboundRecipientData,
    refetch: inboundRecipientRefetch,
    isLoading: inboundRecipientIsLoading,
    isError: isErroriInboundRecipient,
  } = useInboundConversationListControllerInboundConversationQuery(
    { id: campaignId! },
    { skip: !campaignId, refetchOnMountOrArgChange: true },
  );

  return {
    inboundRecipientData,
    isErrorRecipient: isErroriInboundRecipient,
    recipientRefetch: inboundRecipientRefetch,
    recipientIsLoading: inboundRecipientIsLoading,
  };
};
