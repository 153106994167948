import { uniqueId } from 'lodash';
import {
  IconAperture,
  IconMailAi,
  IconActivityHeartbeat,
  IconMicrophone,
} from '@tabler/icons-react';
import i18n from '@shared/utils/i18n';
import { IconCampaigns } from '@shared/assets/images/svgs';

interface MenuitemsType {
  [x: string]: any;
  id?: string;
  navlabel?: boolean;
  subheader?: string;
  title?: string;
  icon?: any;
  href?: string;
  children?: MenuitemsType[];
  chip?: string;
  chipColor?: string;
  variant?: string;
  external?: boolean;
}

export const AdminMenuItems: MenuitemsType[] = [
  // {
  //   id: uniqueId(),
  //   title: i18n.t('adminMainPage.menuItems.dashboard'),
  //   icon: IconHome,
  // },

  {
    id: uniqueId(),
    title: i18n.t('adminMainPage.menuItems.campaigns'),
    icon: IconCampaigns,
    href: '/admin/campaign',
  },
  // {
  //   id: uniqueId(),
  //   title: i18n.t('adminMainPage.menuItems.settings'),
  //   icon: IconSettings,
  // },
];

export const SuperAdminMenuItems: MenuitemsType[] = [
  {
    id: uniqueId(),
    title: i18n.t('superAdminMainPage.menuItems.companies'),
    icon: IconAperture,
    href: '/super-admin/companies',
  },
  {
    id: uniqueId(),
    title: i18n.t('superAdminMainPage.menuItems.monitorConversation'),
    icon: IconMailAi,
    href: '/super-admin/conversation/monitor',
  },
  {
    id: uniqueId(),
    title: i18n.t('superAdminMainPage.menuItems.tokensMonitoring'),
    icon: IconActivityHeartbeat,
    href: '/super-admin/tokens-monitoring',
  },
  {
    id: uniqueId(),
    title: i18n.t('superAdminMainPage.menuItems.voiceSettings'),
    icon: IconMicrophone,
    href: '/super-admin/voice-settings',
  },
];

export default AdminMenuItems;
