import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from './styles';
import { MonitorConversationListV2 } from './MonitorConversationListV2';
import {
  RecipientConversationDto,
  InboundConversationDto,
} from '@shared/services/apiService/apiService';
import { t } from 'i18next';
import { RecipientStatus } from '@shared/constants/recipient-status.constants';
import { useTerminateConversation } from '@features/Admin/Campaign/hooks/useTerminateConversation';
import { IconPhone, IconPhoneMissed } from '@shared/assets/images/svgs';

interface MonitorCampaignProps {
  showDialogFlag: boolean;
  phoneNumber: string;
  recipient: RecipientConversationDto | InboundConversationDto;
  closeDialog: () => void;
}

export const MonitorCampaignPopupV2: React.FC<MonitorCampaignProps> = ({
  showDialogFlag,
  closeDialog,
  phoneNumber,
  recipient,
}: MonitorCampaignProps) => {
  const { classes } = useStyles();
  const { id: recipientId, status } = recipient;
  const { terminate: terminateCall } = useTerminateConversation(recipientId);

  const onClickTerminateCall = () => {
    terminateCall();
    closeDialog?.();
  };

  return (
    <Dialog
      className={classes.dialog}
      open={showDialogFlag}
      onClose={() => closeDialog()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // TODO: "backdropFilter" may not work in firefox
      sx={{
        backdropFilter: 'blur(5px)',
      }}>
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle} variant="h5">
        {`${t('campaignPage.ongoingCallWith')} ${phoneNumber}`}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <MonitorConversationListV2 phoneNumber={phoneNumber} recipient={recipient} />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <IconButton
          className={classes.customizedButton}
          onClick={() => {
            closeDialog();
          }}>
          <CloseIcon />
        </IconButton>
        <Stack
          gap={2}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            margin: '24px 30px 30px 30px !important',
          }}>
          <Button
            onClick={closeDialog}
            startIcon={<IconPhone />}
            variant="contained"
            color="primary"
            disabled={true}
            fullWidth>
            {t('campaignPage.engage')}
          </Button>
          <Button
            onClick={onClickTerminateCall}
            startIcon={<IconPhoneMissed />}
            variant="contained"
            color="error"
            disabled={status != RecipientStatus['IN-PROGRESS']}
            fullWidth>
            {t('campaignPage.endCall')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
