import { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  useCampaignCreateControllerCampaignCreateMutation,
  useGetAllCampaignsControllerGetAllCampaignsQuery,
} from '@shared/services/apiService/apiService';
import { ResponseMsg } from '@shared/types/admin/agent';
import { CreateCampaignFormValue } from '../model/type';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedPath } from '@shared/constants/routes.constants';

export const useCreateCampaign = () => {
  const [useCreateCampaign, { isLoading }] = useCampaignCreateControllerCampaignCreateMutation();
  const [responseMsg, setResponseMsg] = useState<ResponseMsg>({
    message: '',
    type: 'success',
  });
  const [responseLoadingFlag, setResponseLoadingFlag] = useState(false);

  const { refetch } = useGetAllCampaignsControllerGetAllCampaignsQuery();

  const { handleSubmit, setValue, reset, ...formMethods } = useForm<CreateCampaignFormValue>({
    mode: 'all',
    defaultValues: {
      title: '',
    },
  });

  const navigate = useNavigate();

  const onSuccessfullyCreatedCampaign = (id: string) =>
    navigate(`${AuthenticatedPath.EDIT_CAMPAIGN_V2}?id=${id}`);

  const onSubmit = handleSubmit(async (data) => {
    try {
      useCreateCampaign({
        campaignCreateRequestDto: {
          title: data.title,
          type: data.type,
        },
      })
        .unwrap()
        .then(async (payload) => {
          setResponseLoadingFlag(true);
          await refetch();
          // onSuccessfullyCreatedCampaign(payload.id);
          setResponseMsg({
            message: 'Successfully created',
            type: 'success',
          });
        });
      setResponseLoadingFlag(false);
    } catch (error) {
      setResponseMsg({
        message: 'Something Went wrong',
        type: 'error',
      });
    }
  });

  return {
    setValue,
    reset,
    onSubmit,
    isLoading,
    responseMsg,
    setResponseMsg,
    responseLoadingFlag,
    ...formMethods,
  };
};
