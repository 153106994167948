import { Box, Grid, Stack, Typography, styled, useTheme } from '@mui/material';
import { useGetAllCampaignsControllerGetAllCampaignsQuery } from '@shared/services/apiService/apiService';
import { useMemo } from 'react';
import {
  inactiveCampaignsCount,
  ongoingCallCount,
  runningCampaignsCount,
} from './FilterCardCalculation';
import { t } from 'i18next';
import {
  IconFileStop,
  IconFilePlay,
  IconFileStack,
  IconPhoneCall,
} from '@shared/assets/images/svgs';

const BoxStyled = styled(Box)(() => ({
  padding: '16px 16px',
  transition: '0.1s ease-in',
  cursor: 'pointer',
  color: 'inherit',
  '&:hover': {
    transform: 'scale(1.03)',
  },
}));

const IconBox = styled(Box)(() => ({
  width: '42px',
  height: '42px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const FilterCard = () => {
  const { data: campaignList } = useGetAllCampaignsControllerGetAllCampaignsQuery();
  const theme = useTheme();

  let countCall = useMemo(() => ongoingCallCount(campaignList), [campaignList]);
  let activeCampaigns = useMemo(() => runningCampaignsCount(campaignList), [campaignList]);
  let inactiveCampaigns = useMemo(() => inactiveCampaignsCount(campaignList), [campaignList]);

  return (
    <Grid container spacing={3} textAlign="center" sx={{ marginTop: 0 }}>
      <Grid item lg={3} sm={6} xs={12}>
        <BoxStyled
          onClick={() => {}}
          sx={{
            border: `1px solid ${theme.palette.extendedGrey?.lighter}`,
          }}>
          <Stack direction="row" justifyContent="flex-start" spacing="20px" alignItems="center">
            <IconBox
              sx={{
                backgroundColor: `${theme.palette.primary.main}0D`,
              }}>
              <IconPhoneCall />
            </IconBox>
            <Box>
              <Typography variant="h3" fontSize="18px" textAlign="start">
                {countCall}
              </Typography>
              <Typography variant="subtitle1" fontSize="12px" color={theme.palette.text.secondary}>
                {t('adminMainPage.cardFilter.ongoingCalls')}
              </Typography>
            </Box>
          </Stack>
        </BoxStyled>
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <BoxStyled
          onClick={() => {}}
          sx={{
            border: `1px solid ${theme.palette.extendedGrey?.lighter}`,
          }}>
          <Stack direction="row" justifyContent="flex-start" spacing="20px" alignItems="center">
            <IconBox
              sx={{
                backgroundColor: `${theme.palette.primary.main}0D`,
              }}>
              <IconFileStack />
            </IconBox>
            <Box>
              <Typography variant="h3" fontSize="18px" textAlign="start">
                {campaignList?.length}
              </Typography>
              <Typography variant="subtitle1" fontSize="12px" color={theme.palette.text.secondary}>
                {t('adminMainPage.cardFilter.totalCampaign')}
              </Typography>
            </Box>
          </Stack>
        </BoxStyled>
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <BoxStyled
          onClick={() => {}}
          sx={{
            border: `1px solid ${theme.palette.extendedGrey?.lighter}`,
          }}>
          <Stack direction="row" justifyContent="flex-start" spacing="20px" alignItems="center">
            <IconBox
              sx={{
                backgroundColor: `${theme.palette.primary.main}0D`,
              }}>
              <IconFilePlay />
            </IconBox>
            <Box>
              <Typography variant="h3" fontSize="18px" textAlign="start">
                {activeCampaigns}
              </Typography>
              <Typography variant="subtitle1" fontSize="12px" color={theme.palette.text.secondary}>
                {t('adminMainPage.cardFilter.runningCampaign')}
              </Typography>
            </Box>
          </Stack>
        </BoxStyled>
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <BoxStyled
          onClick={() => {}}
          sx={{
            border: `1px solid ${theme.palette.extendedGrey?.lighter}`,
          }}>
          <Stack direction="row" justifyContent="flex-start" spacing="20px" alignItems="center">
            <IconBox
              sx={{
                backgroundColor: `${theme.palette.primary.main}0D`,
              }}>
              <IconFileStop />
            </IconBox>
            <Box>
              <Typography variant="h3" fontSize="18px" textAlign="start">
                {inactiveCampaigns}
              </Typography>
              <Typography variant="subtitle1" fontSize="12px" color={theme.palette.text.secondary}>
                {t('adminMainPage.cardFilter.inactiveCampaign')}
              </Typography>
            </Box>
          </Stack>
        </BoxStyled>
      </Grid>
    </Grid>
  );
};
