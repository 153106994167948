import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';

import 'dayjs/locale/en';

import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './styles';
import { useAppSelector } from '@shared/store';
import { selectors } from '@shared/store/ducks/auth';

// Modal for getting the access token
// For future, this modal can be used to get some other access (user_id, etc.)
export const AccessModal: React.FC = () => {
  const { classes } = useStyles();
  const accessToken = useAppSelector(
    (state) => selectors.getAccessToken(state) || selectors.getSuperAdminAccessToken(state),
  );
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    const processEvent = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.altKey && e.code === 'KeyA') {
        setShowDialog(true);
      }
    };

    document.addEventListener('keydown', processEvent);
    return () => document.removeEventListener('keydown', processEvent);
  }, []);

  const closeDialog = () => setShowDialog(false);

  return (
    <Dialog
      className={classes.dialog}
      open={showDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle} variant="h6">
        Get Access
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Button
          onClick={async () => {
            await navigator.clipboard.writeText(accessToken as string);
          }}
          variant="outlined">
          Copy Access Token to Clipboard
        </Button>
      </DialogContent>
      <DialogActions>
        <IconButton
          className={classes.customizedButton}
          onClick={() => {
            closeDialog();
          }}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};
