import { useMediaQuery, Box, Drawer, useTheme, IconButton } from '@mui/material';
import SidebarItems from './SidebarItems';
import Logo from '../../shared/logo/Logo';

import { Profile } from './SidebarProfile/Profile';

import { useAppDispatch, useAppSelector, RootState } from '@shared/store';
import { actions } from '@shared/store/ducks';
import Scrollbar from '@shared/components/custom-scroll/Scrollbar';
import { IconPanelLeft } from '@shared/assets/images/svgs';

const Sidebar = () => {
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));
  const customizer = useAppSelector((state: RootState) => state.customizer);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const toggleWidth =
    customizer.isCollapse && !customizer.isSidebarHover
      ? customizer.MiniSidebarWidth
      : customizer.SidebarWidth;

  const handleExpandSidebar = () => {
    if (customizer.isCollapse) {
      dispatch(actions.customizer.toggleSidebar());
    }
  };
  return (
    <Box
      sx={{
        width: toggleWidth,
        flexShrink: 0,
        ...(customizer.isCollapse && {
          position: 'absolute',
        }),
      }}>
      {/* ------------------------------------------- */}
      {/* Sidebar for desktop */}
      {/* ------------------------------------------- */}
      <Drawer
        anchor="left"
        open
        onClick={handleExpandSidebar}
        variant="permanent"
        PaperProps={{
          sx: {
            transition: theme.transitions.create('width', {
              duration: theme.transitions.duration.shortest,
            }),
            backgroundColor: theme.palette.extendedGrey?.lighter,
            width: toggleWidth,
            boxSizing: 'border-box',
          },
        }}>
        {/* ------------------------------------------- */}
        {/* Sidebar Box */}
        {/* ------------------------------------------- */}
        <Box
          sx={{
            height: '100%',
          }}>
          {/* ------------------------------------------- */}
          {/* Logo */}
          {/* ------------------------------------------- */}
          <Box
            px={3}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Logo />
            {!customizer.isCollapse && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(actions.customizer.toggleSidebar());
                }}>
                <IconPanelLeft />
              </IconButton>
            )}
          </Box>
          <Scrollbar sx={{ height: 'calc(100% - 190px)' }}>
            {/* ------------------------------------------- */}
            {/* Sidebar Items */}
            {/* ------------------------------------------- */}
            <SidebarItems />
          </Scrollbar>
          <Profile />
        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
