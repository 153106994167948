import { useUserMeControllerUserMeQuery } from '@shared/services/apiService/apiService';
import { useAppDispatch } from '@shared/store';
import { actions } from '@shared/store/ducks';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

export const UserLoader: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data: userData, isLoading, error } = useUserMeControllerUserMeQuery();

  useEffect(() => {
    if (userData) {
      dispatch(actions.auth.setAuthenticatedUser(userData));
    }
  }, [userData, dispatch]);

  if (isLoading) return <div>Loading user data...</div>;
  if (error) return <div>Error during loading user data.</div>;

  return <Outlet />;
};
