import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentCopy, MoreVertOutlined } from '@mui/icons-material';
import { useStyles } from '../styles'; // Use shared styles
import { companyColumns } from '../constants';
import {
  CompanyDto,
  useGetAllCompaniesControllerGetAllCompaniesQuery,
} from '@shared/services/apiService/apiService';
import { CreateCompanyPopup } from '@widgets/Popup/CreateCompany/CreateCompany';
import { copyToClipboard, truncateId } from '../utils';
import { useNavigate } from 'react-router-dom';
import { SuperAdminAuthenticatedPath } from '@shared/constants/routes.constants';
import { EditCompanyPopup } from '@widgets/Popup/EditCompanyPopup/EditCompanyPopup';

type Column = {
  id: string;
  label: string;
  isDate?: boolean;
  isTruncated?: boolean;
  isFullName?: boolean;
};

type Item = {
  [key: string]: any;
};

const renderCell = (item: Item, column: Column) => {
  if (column.isDate) {
    const date = item[column.id];
    return date ? new Date(date).toLocaleDateString() : '-';
  }

  if (column.isTruncated) {
    return (
      <Box display="flex" alignItems="center" gap="5px">
        {truncateId(item[column.id])}
        <Tooltip title="Copy ID">
          <IconButton size="small" onClick={() => copyToClipboard(item[column.id])}>
            <ContentCopy fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    );
  }

  return <Typography>{item[column.id] || '-'}</Typography>;
};

export const CompaniesPage = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigator = useNavigate();
  const [showCreateCompanyDialogFlag, setShowCreateCompanyDialogFlag] = useState(false);
  const [showEditCompanyDialogFlag, setShowEditCompanyDialogFlag] = useState(false);

  const { data, refetch: refetchCompanies } = useGetAllCompaniesControllerGetAllCompaniesQuery();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCompany, setSelectedCompany] = useState<CompanyDto | null>(null);
  const [openedCompanyId, setOpenedCompanyId] = useState<string | null>(null);

  const closeCreateCompanyDialog = () => {
    setShowCreateCompanyDialogFlag(false);
    refetchCompanies();
  };

  const closeEditCompanyDialog = () => {
    setShowEditCompanyDialogFlag(false);
  };

  const handleRowClick = (company: CompanyDto) => {
    navigator(`${SuperAdminAuthenticatedPath.EDIT_COMPANY}?id=${company.id}&name=${company.name}`);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, company: CompanyDto) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
    setOpenedCompanyId(company.id);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(null);
    setOpenedCompanyId(null);
  };

  const handleEditClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setSelectedCompany(data?.find((company) => company.id === openedCompanyId) || null);
    setShowEditCompanyDialogFlag(true);
    setAnchorEl(null);
    setOpenedCompanyId(null);
  };

  return (
    <Box className={classes.main}>
      <Box className={classes.companies_header}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowCreateCompanyDialogFlag(true)}>
          {t('superAdminMainPage.companies.buttons.createCompany')}
        </Button>
      </Box>
      <TableContainer className={classes.companies_table}>
        <Table>
          <TableHead>
            <TableRow>
              {companyColumns.map((column) => (
                <TableCell key={column.id}>
                  <Typography variant="h6">{column.label}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((company) => (
              <TableRow
                onClick={() => handleRowClick(company)}
                key={company.id}
                className={classes.companies_table_row}
                hover>
                {companyColumns.map((column) => (
                  <TableCell key={column.id}>{renderCell(company, column)}</TableCell>
                ))}
                <TableCell>
                  <IconButton onClick={(event) => handleMenuOpen(event, company)}>
                    <MoreVertOutlined />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={openedCompanyId === company.id} // Only open the menu for the selected company
                    onClose={handleMenuClose}>
                    <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateCompanyPopup
        showDialogFlag={showCreateCompanyDialogFlag}
        closeDialog={closeCreateCompanyDialog}
      />
      {selectedCompany && (
        <EditCompanyPopup
          company={selectedCompany}
          showDialogFlag={showEditCompanyDialogFlag}
          closeDialog={closeEditCompanyDialog}
          onCompanyEditSuccess={refetchCompanies}
        />
      )}
    </Box>
  );
};
