import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedPath } from '@shared/constants/routes.constants';
import { useSignInControllerSignInMutation } from '@shared/services/apiService/apiService';
import { useAppDispatch } from '@shared/store';
import { actions } from '@shared/store/ducks';
import { SignInFormValues } from '../model/types';

export const useSignIn = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const [signInUser] = useSignInControllerSignInMutation();
  const { handleSubmit, setValue, ...formMethods } = useForm<SignInFormValues>({
    mode: 'onTouched',
    defaultValues: {
      login: '',
      password: '',
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    const res: any = await signInUser({
      signInRequestDto: {
        login: data.login,
        password: data.password,
      },
    });

    if (res.error) {
      if (res.error.data) {
        setErrorMsg(res.error.data.message);
      } else {
        setErrorMsg('An unknown error occurred.');
      }
    } else {
      dispatch(
        actions.auth.signIn({
          accessToken: res.data.accessToken,
          refreshToken: res.data.refreshToken,
        }),
      );

      navigate(AuthenticatedPath.CAMPAIGN);
    }
  });

  return {
    setValue,
    onSubmit,
    errorMsg,
    ...formMethods,
  };
};
