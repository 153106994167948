import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  useEditVoiceControllerEditVoiceMutation,
  useGetAllVoicesControllerGetAllVoicesQuery,
  useGetUsageControllerGetUsageQuery,
  VoiceDto,
} from '@shared/services/apiService/apiService';
import { VoiceSettings } from './types';

const useStyles = makeStyles()((theme) => ({
  tableContainer: {
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[3],
    marginBottom: theme.spacing(4),
    overflow: 'hidden',
  },
  tableHeadCell: {
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tableCell: {
    color: theme.palette.text.secondary,
  },
}));

export const ManageVoiceSettings: React.FC<{}> = () => {
  const { classes } = useStyles();
  const { data, error, refetch, isLoading } = useGetUsageControllerGetUsageQuery();
  const {
    isLoading: isLoadingVoices,
    refetch: refreshVoices,
    data: voices,
  } = useGetAllVoicesControllerGetAllVoicesQuery();
  const [editVoice] = useEditVoiceControllerEditVoiceMutation();
  const [open, setOpen] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState<VoiceDto | null>(null);
  const [voiceSettings, setVoiceSettings] = useState<VoiceSettings | null>(null);
  const [voiceSettingsString, setVoiceSettingsString] = useState<string | null>();
  const [isJsonValid, setIsJsonValid] = useState(true);

  useEffect(() => {
    if (error && 'status' in error && error.status === 401) {
      refetch();
    }
  }, [error]);

  const handleEditVoice = (voice: VoiceDto) => {
    const voiceSettings = voice.voiceSettings as VoiceSettings;

    setSelectedVoice(voice);
    setVoiceSettingsString(JSON.stringify(voiceSettings, null, 2));

    if (voice.voiceSettings) {
      setVoiceSettings({ ...voiceSettings, name: voice.name });
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    if (voiceSettings && selectedVoice) {
      const { name, ...settings } = voiceSettings;

      await editVoice({
        id: selectedVoice.id,
        voiceRequestDto: { ...selectedVoice, name, voiceSettings: { ...settings } },
      });

      refreshVoices();

      setOpen(false);
    }
  };

  const handleJsonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    try {
      setVoiceSettingsString(value);

      const parsed = JSON.parse(value);
      setVoiceSettings((prev) => ({ ...parsed, name: prev?.name }));
      setIsJsonValid(true);
    } catch {
      setIsJsonValid(false);
    }
  };

  if ((isLoading || isLoadingVoices) && !data) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!data) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">No data available</Typography>
      </Box>
    );
  }

  if (voices && voices.length > 0) {
    return (
      <>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeadCell}>Name</TableCell>
                <TableCell className={classes.tableHeadCell}>Gender</TableCell>
                <TableCell className={classes.tableHeadCell}>Accent</TableCell>
                <TableCell className={classes.tableHeadCell}>Preview</TableCell>
                <TableCell className={classes.tableHeadCell}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {voices.map((voice) => (
                <TableRow key={voice.id} className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="body1" color="textPrimary">
                      {voice.name}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="body1" color="textPrimary">
                      {voice.gender} {voice.gender === 'male' ? '👨' : '👩'}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="body1" color="textPrimary">
                      {voice.accent}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <audio controls>
                      <source src={voice.previewUrl} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Button onClick={() => handleEditVoice(voice)}>Edit</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {voiceSettings && (
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit Voice Settings</DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                label="Name"
                fullWidth
                value={voiceSettings.name}
                onChange={(e) => setVoiceSettings({ ...voiceSettings, name: e.target.value })}
              />
              <TextField
                margin="dense"
                label="Voice Settings"
                fullWidth
                multiline
                rows={10}
                value={voiceSettingsString}
                onChange={handleJsonChange}
                error={!isJsonValid}
                helperText={!isJsonValid && 'Invalid JSON format'}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSave} color="primary" disabled={!isJsonValid}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }

  return null;
};

export default ManageVoiceSettings;
