import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  styled,
  Tab,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRef, useEffect, useState } from 'react';
import { useStyles } from './styles';
import { getCallDuration, getSentimentsColor } from '@pages/Admin/Campaign/ui/utils';
import {
  ConversationSentimentDto,
  InboundConversationDto,
  RecipientConversationDto,
} from '@shared/services/apiService/apiService';
import Timer from '@shared/components/timer/timer';
import { STOP_AUTOSCROLL_THRESHOLD_PX } from './constants';
import { useWebSocketConversation } from '@features/Admin/Campaign/hooks/useWebSocketConversation';
import { IconIndicatorFlag } from '@shared/assets/images/svgs';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { ConversationListMessage } from './ConversationListMessage';
import { RecipientActors } from '@shared/constants/recipient-status.constants';

type OutboundRecipientOmittedType = Omit<Partial<RecipientConversationDto>, 'id'> & { id: string };
type InboundRecipientOmittedType = Omit<Partial<InboundConversationDto>, 'id'> & { id: string };
interface MonitorConversationListProps {
  recipient: InboundRecipientOmittedType | OutboundRecipientOmittedType;
  startCallTime?: Date;
  phoneNumber?: string;
  onError?: <T>(error: T) => void;
}

const StyledCallInfoStack = styled(Stack)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const initialState = {
  answered: false,
  callDuration: '',
  callsStatus: '',
  callCreation: new Date() as Date | null,
};

const WrappedSentimentFlag = ({
  sentiments,
}: {
  sentiments: ConversationSentimentDto[] | undefined;
}) => {
  if (!sentiments) {
    return <></>;
  }
  return (
    <Box
      display={'flex'}
      justifyContent="center"
      sx={{ '& path': { stroke: getSentimentsColor(sentiments) } }}>
      <IconIndicatorFlag />
    </Box>
  );
};

export const MonitorConversationListV2: React.FC<MonitorConversationListProps> = ({
  phoneNumber = 'You',
  startCallTime,
  recipient,
  onError,
}: MonitorConversationListProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messagesBoxRef = useRef<HTMLDivElement | null>(null);
  const [activeTab, setActiveTab] = useState('1');

  const [callDetails, setCallDetails] = useState<typeof initialState>(initialState);

  const { callDuration } = callDetails;

  const { sentiments, id: recipientId } = recipient;

  const {
    initialLoading: loading,
    conversationData,
    resetConversationHistory,
  } = useWebSocketConversation(recipientId, onError);

  useEffect(() => {
    return () => resetConversationHistory();
  }, [recipientId]);

  useEffect(() => {
    if (recipient) {
      const { status, answeredAt, startedAt } = recipient;

      const terminatedAt = isOutboundRecipient(recipient)
        ? recipient.terminatedAt
        : recipient.finishedAt;

      let callDuration =
        startedAt && answeredAt && terminatedAt ? getCallDuration(answeredAt, terminatedAt) : null;

      setCallDetails({
        answered: !!answeredAt,
        callDuration: callDuration ?? '',
        callCreation: startCallTime || null,
        callsStatus: status || '',
      });
    }
  }, [recipient, recipientId]);

  useEffect(() => {
    if (!messagesBoxRef.current || !messagesEndRef.current) return;

    const { clientHeight, scrollTop, scrollHeight } = messagesBoxRef.current;
    const isNearBottom = scrollHeight - STOP_AUTOSCROLL_THRESHOLD_PX <= clientHeight + scrollTop;

    if (isNearBottom) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversationData]);

  useEffect(() => {
    if (!messagesEndRef.current) return;

    if (!loading) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loading]);

  // type guard for inbound/outbound recipients
  const isOutboundRecipient = (
    recipient: MonitorConversationListProps['recipient'],
  ): recipient is OutboundRecipientOmittedType => 'terminatedAt' in recipient;

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const getRecipientStatusLine = (title: string, value: string | React.ReactNode) => (
    <StyledCallInfoStack direction="row" gap={1}>
      <Typography color={theme.palette.extendedGrey?.darker} fontWeight="500" fontSize="12px">
        {title}:
      </Typography>
      <Box color={theme.palette.extendedGrey?.darker} fontWeight="500" fontSize="12px">
        {value}
      </Box>
    </StyledCallInfoStack>
  );

  return (
    <Grid container className={classes.dialogGridContent} id="dialog-grid-content">
      <Grid item xs={12}>
        {callDuration && getRecipientStatusLine(t('campaignPage.duration'), callDuration)}
        {startCallTime &&
          callDetails.callsStatus &&
          getRecipientStatusLine(
            t('campaignPage.duration'),
            <Timer startTimestamp={startCallTime} />,
          )}
      </Grid>
      <Grid item xs={12}>
        {callDuration &&
          getRecipientStatusLine(
            t('campaignPage.sentiment'),
            <WrappedSentimentFlag sentiments={sentiments} />,
          )}
        {startCallTime &&
          callDetails.callsStatus &&
          getRecipientStatusLine(
            // TODO: add last sentiment text when this feature will be done
            t('campaignPage.sentiment'),
            <WrappedSentimentFlag sentiments={sentiments} />,
          )}
      </Grid>
      <Grid item xs={12} mt="10px">
        <TabContext value={activeTab}>
          <TabList onChange={handleTabChange}>
            <Tab label={t('campaignPage.conversionLog')} value="1" />
            <Tab label={t('campaignPage.summary')} value="2" disabled />
          </TabList>
          <TabPanel value="1" sx={{ p: 0 }}>
            {!loading && conversationData.data.length !== 0 ? (
              <Box className={classes.chatBox}>
                <Box ref={messagesBoxRef} className={classes.chatBoxMessages}>
                  {conversationData.data.map((messageItem, index) => (
                    <ConversationListMessage
                      key={index}
                      actorName={
                        messageItem.actor === RecipientActors.AGENT ? undefined : phoneNumber
                      }
                      messageItem={messageItem}
                      isStacked={messageItem.actor === conversationData?.data[index - 1]?.actor}
                    />
                  ))}
                  <div ref={messagesEndRef} />
                </Box>
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography sx={{ textAlign: 'center' }}>
                    {t('campaignPage.emptyConversationHistory')}
                  </Typography>
                )}
              </Box>
            )}
          </TabPanel>
          <TabPanel value="2">Summary</TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
};
