import { tss } from 'tss-react/mui';

export const useStyles = tss.create(({ theme }) => ({
  dialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '450px',
        maxHeight: '1000px',
        overflowY: 'unset',
      },
    },
  },
  dialogTitle: {
    textAlign: 'center',
    color: theme.palette.extendedGrey?.darker,
    fontSize: '18px',
    padding: '30px 30px 15px 30px',
  },
  customizedButton: {
    position: 'absolute',
    right: '15px',
    top: '15px',
    padding: 0,
  },
  dialogContent: {
    padding: '0px 30px',
    overflowY: 'hidden',
  },
  dialogActions: {
    '.MuiButton-contained.Mui-disabled.MuiButton-containedPrimary': {
      background: '#1875FF4D',
      color: 'white',
    },
    '.MuiButton-contained.Mui-disabled.MuiButton-containedError': {
      background: theme.palette.error.light,
      color: 'white',
    },
  },
  chatBox: {
    width: '100%',
  },
  chatBoxMessages: {
    padding: '20px',
    width: '100%',
    height: '385px',
    overflow: 'scroll',
    borderRadius: '0 8px 8px 8px',
    backgroundColor: theme.palette.extendedGrey?.lighter,
  },
  dialogGridContent: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    alignContent: 'space-between',
    '.MuiTabs-scroller.MuiTabs-fixed': {
      '.MuiTabs-flexContainer': {
        '.MuiTab-root': {
          borderRadius: '8px 8px 0 0',
          '&.Mui-selected': {
            backgroundColor: theme.palette.extendedGrey?.lighter,
            color: theme.palette.extendedGrey?.darker,
          },
        },
      },
      '.MuiTabs-indicator': {
        backgroundColor: 'transparent',
      },
    },
  },
}));
