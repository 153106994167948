import { CampaignStatus } from '@pages/Admin/Main/Campaign/ui/CampaignStatusButton';
import { CallScheduleDto, CampaignDto } from '@shared/services/apiService/apiService';
import { createContext, useContext } from 'react';

interface StartStopContextType {
  setStartStopFunction: (
    fn: (
      id: string,
      callSchedule: CallScheduleDto | undefined,
      campaignStatus: CampaignStatus,
    ) => Promise<void>,
  ) => void;
  callStartStopFunction: (
    id: string,
    callSchedule: CallScheduleDto | undefined,
    campaignStatus: CampaignStatus,
  ) => void;
  campaignContext: CampaignDto | null;
  setCampaignContext: (data: CampaignDto) => void;
  isDisabledCampaignRun: boolean;
  setIsDisabledCampaignRun: (disabled: boolean) => void;
}

export const StartStopContext = createContext<StartStopContextType | undefined>(undefined);

export const useStartStopContext = () => {
  const context = useContext(StartStopContext);
  if (!context) {
    throw new Error('useStartStopContext must be used within StartStopProvider');
  }
  return context;
};
