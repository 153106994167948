import { useLocation } from 'react-router-dom';

import { Box, Button, styled, Typography } from '@mui/material';

import { RootState, useAppSelector } from '@shared/store';
import { useSignOut } from '@features/Auth/SignOut/hooks/useSignOut';
import { useSignOutSuperAdmin } from '@features/Auth/SignOut/hooks/useSignOutSuperAdmin';
import { useTranslation } from 'react-i18next';
import { IconLogout } from '@shared/assets/images/svgs';

const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  backgroundColor: 'transparent',
  color: theme.palette.extendedGrey?.neutral,
  minWidth: 'unset',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    '& path': {
      stroke: theme.palette.primary.main,
    },
  },
}));

export const Profile = () => {
  const customizer = useAppSelector((state: RootState) => state.customizer);
  const hideMenu = customizer.isCollapse && !customizer.isSidebarHover;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf('/'));
  const isSuperAdminPage = pathWithoutLastPart.includes('super-admin');

  const onAdminLogout = useSignOut();
  const onSuperAdminLogout = useSignOutSuperAdmin();

  const onLogout = isSuperAdminPage ? onSuperAdminLogout : onAdminLogout;

  return (
    <Box display={'flex'} justifyContent="flex-start" alignItems="center" gap={2} sx={{ p: 3 }}>
      <Box mt={2}>
        <StyledButton variant="text" color="primary" onClick={onLogout} sx={{ p: 1 }}>
          <IconLogout />
          {!hideMenu && (
            <Typography sx={{ paddingLeft: '16px' }}>
              {t('adminMainPage.menuItems.logout')}
            </Typography>
          )}
        </StyledButton>
      </Box>
    </Box>
  );
};
