import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { COLUMN_NAMES } from '../../constant/constant';
import { GetUsersListControllerGetUsersListApiResponse } from '@shared/services/apiService/apiService';
import PropTypes from 'prop-types';

interface UsersTabContentProps {
  users?: GetUsersListControllerGetUsersListApiResponse;
  isLoading: boolean;
  anchorEl: HTMLElement | null;
  handleMenuOpen: (event: React.MouseEvent<HTMLElement>, user: any) => void;
  handleMenuClose: () => void;
  handleEditClick: () => void;
}

export const UsersTabContent: React.FC<UsersTabContentProps> = ({
  users,
  isLoading,
  anchorEl,
  handleMenuOpen,
  handleMenuClose,
  handleEditClick,
}) => {
  if (isLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '400px',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {Object.values(COLUMN_NAMES).map((columnName) => (
              <TableCell key={columnName}>
                <Typography>{columnName}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((user) => (
            <TableRow key={user.id}>
              <TableCell>
                <Typography>{user.id}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{user.email}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{user.firstName}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{user.lastName}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{user.role}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{new Date(user.createdAt).toLocaleString()}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{new Date(user.updatedAt).toLocaleString()}</Typography>
              </TableCell>
              <TableCell>
                <IconButton onClick={(event) => handleMenuOpen(event, user)}>
                  <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                  <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

UsersTabContent.propTypes = {
  users: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.instanceOf(HTMLElement), PropTypes.oneOf([null])]),
  handleMenuOpen: PropTypes.func.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
};
