import { LeadListSimpleDto } from '@shared/services/apiService/apiService';
import { CreateCampaignFormValue } from '../model/type';

export const campaignRequestMapper = (
  data: CreateCampaignFormValue,
  leadList: LeadListSimpleDto[] | undefined,
) => {
  return {
    title: data.title,
    retryIntervalInMinutes: data.retryIntervalInMinutes,
    retryMaxNumber: data.retryMaxNumber,
    retriesPerDay: data.retriesPerDay,
    leadList: leadList,
    callSchedule: data.callSchedule,
    sendFollowUpMessage: data.sendFollowUpMessage,
    productDetails: data.productDetails,
    followUpTemplate: data.followUpTemplate,
  };
};
