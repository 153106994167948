import { IconButton, Box, AppBar, useMediaQuery, Toolbar, styled, Stack } from '@mui/material';

import Profile from './Profile';

import Navigation from './Navigation';
import { RootState, useAppSelector } from '@shared/store';
import BreadcrumbV2 from '../../shared/breadcrumb/BreadcrumbV2';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconBack } from '@shared/assets/images/svgs';
import CampaignStatusButtonV2 from '@pages/Admin/Main/Campaign/ui/CampaignStatusButton/CampaignStatusButtonV2';
import { useStartStopContext } from '@features/Admin/Campaign/contexts/useStartStop.context';

const Header = () => {
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { callStartStopFunction, campaignContext, isDisabledCampaignRun } = useStartStopContext();
  // drawer
  const BCrumb = useAppSelector((state: RootState) => state.breadcrumbs);
  const customizer = useAppSelector((state: RootState) => state.customizer);

  const AppBarStyled = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    justifyContent: 'center',
    backdropFilter: 'blur(4px)',
    [theme.breakpoints.up('lg')]: {
      minHeight: customizer.TopbarHeight,
    },
  }));
  const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
    width: '100%',
    color: theme.palette.text.secondary,
  }));

  const BackButtonStyled = styled(Box)(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    //TODO: move alfa-channeled color to palette
    boxShadow: `0px 2px 4px ${theme.palette.extendedGrey?.neutral}4B`,
    backgroundColor: 'transparent',
    border: `1px soled ${theme.palette.extendedGrey?.lighter}`,
    width: 30,
    height: 30,
    '&:hover': {
      backgroundColor: theme.palette.extendedGrey?.lightest,
    },
  }));

  return (
    <AppBarStyled position="sticky" color="default">
      <ToolbarStyled>
        {customizer.isEditCampaignView && (
          <IconButton size="small" onClick={() => navigate(-1)}>
            <BackButtonStyled>
              <IconBack />
            </BackButtonStyled>
          </IconButton>
        )}
        {BCrumb.iShown && (
          <BreadcrumbV2
            title={t(BCrumb.pageTitle, { defaultValue: '', ...BCrumb.titleValue })}
            campaignType={BCrumb.campaignType}
          />
        )}

        {/* ------------------------------------------- */}
        {/* Search Dropdown */}
        {/* ------------------------------------------- */}
        {/* <Search /> */}
        {lgUp ? (
          <>
            <Navigation />
          </>
        ) : null}

        <Box flexGrow={1} />
        <Stack spacing={1} direction="row" alignItems="center">
          {/* <Language /> */}
          {/* ------------------------------------------- */}
          {/* Ecommerce Dropdown */}
          {/* ------------------------------------------- */}
          {/* <Cart /> */}
          {/* ------------------------------------------- */}
          {/* End Ecommerce Dropdown */}
          {/* ------------------------------------------- */}
          {/* <Notifications /> */}
          {/* ------------------------------------------- */}
          {/* Toggle Right Sidebar for mobile */}
          {/* ------------------------------------------- */}
          {/* {lgDown ? <MobileRightSidebar /> : null} */}
          {!customizer.isEditCampaignView && <Profile />}
          {customizer.isEditCampaignView && (
            <CampaignStatusButtonV2
              minimized={false}
              onClick={callStartStopFunction}
              campaign={campaignContext}
              disabled={isDisabledCampaignRun}
            />
          )}
        </Stack>
      </ToolbarStyled>
    </AppBarStyled>
  );
};

export default Header;
