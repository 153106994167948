import { useGetRecipientConversationListControllerGetCampaignQuery } from '@shared/services/apiService/apiService';

export const useGetOutboundRecipient = (campaignId: string) => {
  const {
    data: outboundRecipientData,
    refetch: outboundRecipientRefetch,
    isLoading: outboundRecipientIsLoading,
    isError: isErrorOutboundRecipient,
  } = useGetRecipientConversationListControllerGetCampaignQuery(
    { id: campaignId! },
    { skip: !campaignId, refetchOnMountOrArgChange: true },
  );

  return {
    outboundRecipientData,
    isErrorRecipient: isErrorOutboundRecipient,
    recipientRefetch: outboundRecipientRefetch,
    recipientIsLoading: outboundRecipientIsLoading,
  };
};
