import { skipToken } from '@reduxjs/toolkit/query';
import {
  ConversationLogDto,
  CursorMetaDto,
  useGetConversationMessageControllerGetMessageQuery,
} from '@shared/services/conversationApiService/apiService';
import { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';

const WS_URL = process.env.REACT_APP_CONVERSATION_API_URL;
const MESSAGES_LIMIT_PER_REQUEST = 20;

interface WsException {
  status: string;
  message: string;
}

export const useWebSocketConversation = (
  conversationId: string,
  onError?: <T>(error: T) => void,
) => {
  const [isConnected, setIsConnected] = useState(false);
  const [accumulatedData, setAccumulatedData] = useState<ConversationLogDto[]>([]);
  const [meta, setMeta] = useState<CursorMetaDto | undefined>();
  const [cursor, setCursor] = useState<string | null>(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [socket, setSocket] = useState<Socket | null>(null);

  const resetConversationHistory = () => {
    setAccumulatedData([]);
  };

  const subscribeForConversationLogs = () => {
    socket?.emit('subscribe_to_conversation_message', {
      conversationId,
    });
  };

  const {
    data: oldMessages,
    refetch: conversationRefetch,
    error,
  } = useGetConversationMessageControllerGetMessageQuery(
    conversationId
      ? {
          conversationId: conversationId,
          limit: MESSAGES_LIMIT_PER_REQUEST,
          cursor: cursor!,
        }
      : skipToken,
  );

  useEffect(() => {
    if (oldMessages?.data) {
      setAccumulatedData((prev) => [...prev, ...oldMessages.data]);
      if (oldMessages.meta.hasMore) {
        setCursor(oldMessages.meta.cursor);
      } else {
        setInitialLoading(false);
        subscribeForConversationLogs();
      }
    }
  }, [oldMessages]);

  useEffect(() => {
    const token = localStorage.getItem('accessToken')!;
    conversationRefetch();

    const newSocket = io(WS_URL, {
      transports: ['websocket'],
      autoConnect: false,
      auth: {
        token,
      },
      query: {
        conversationId: conversationId,
        limit: MESSAGES_LIMIT_PER_REQUEST,
        cursor,
      },
    });

    setSocket(newSocket);

    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, [conversationId]);

  useEffect(() => {
    if (!socket) return;

    const onConnect = () => {
      setIsConnected(true);
      subscribeForConversationLogs();
    };
    const onDisconnect = () => setIsConnected(false);
    const onConversation = (value: {
      result: ConversationLogDto;
      meta: CursorMetaDto | undefined;
    }) => {
      setMeta(value.meta);

      setAccumulatedData((prev) => [...prev, value.result]);

      if (meta?.hasMore) {
        setCursor(meta.cursor);
      }
    };
    const onException = (error: WsException) => {
      socket.disconnect();

      if (onError) {
        onError(error);
      }
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('conversation_message', onConversation);
    socket.on('exception', (e) => onException(e));

    socket.connect();

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('conversation_message', onConversation);
      socket.off('exception', onException);
    };
  }, [socket]);

  useEffect(() => {
    if (error && onError) {
      onError(error);
    }
  }, [isConnected, onError]);

  return {
    isConnected,
    initialLoading,
    conversationData: { data: accumulatedData, meta: meta },
    resetConversationHistory,
  };
};
