import { baseApi as api } from '../../store/api/baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    appControllerGetHello: build.query<
      AppControllerGetHelloApiResponse,
      AppControllerGetHelloApiArg
    >({
      query: () => ({ url: `/` }),
    }),
    metricsControllerWebhook: build.query<
      MetricsControllerWebhookApiResponse,
      MetricsControllerWebhookApiArg
    >({
      query: () => ({ url: `/metrics` }),
    }),
    refreshTokensControllerRefreshTokenUser: build.mutation<
      RefreshTokensControllerRefreshTokenUserApiResponse,
      RefreshTokensControllerRefreshTokenUserApiArg
    >({
      query: () => ({ url: `/v1/user/refresh-token`, method: 'POST' }),
    }),
    refreshTokensControllerRefreshTokenAdmin: build.mutation<
      RefreshTokensControllerRefreshTokenAdminApiResponse,
      RefreshTokensControllerRefreshTokenAdminApiArg
    >({
      query: () => ({ url: `/v1/admin/refresh-token`, method: 'POST' }),
    }),
    signInControllerSignIn: build.mutation<
      SignInControllerSignInApiResponse,
      SignInControllerSignInApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/sign-in`,
        method: 'POST',
        body: queryArg.signInRequestDto,
      }),
    }),
    adminSignInControllerSignIn: build.mutation<
      AdminSignInControllerSignInApiResponse,
      AdminSignInControllerSignInApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/sign-in`,
        method: 'POST',
        body: queryArg.adminSignInRequestDto,
      }),
    }),
    forgotPasswordControllerForgotPassword: build.mutation<
      ForgotPasswordControllerForgotPasswordApiResponse,
      ForgotPasswordControllerForgotPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/forgot-password`,
        method: 'POST',
        body: queryArg.forgotPasswordRequestDto,
      }),
    }),
    resetPasswordControllerResetPassword: build.mutation<
      ResetPasswordControllerResetPasswordApiResponse,
      ResetPasswordControllerResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/reset-password`,
        method: 'POST',
        body: queryArg.resetPasswordRequestDto,
      }),
    }),
    userMeControllerUserMe: build.query<
      UserMeControllerUserMeApiResponse,
      UserMeControllerUserMeApiArg
    >({
      query: () => ({ url: `/v1/users/me` }),
    }),
    getUsersListControllerGetUsersList: build.query<
      GetUsersListControllerGetUsersListApiResponse,
      GetUsersListControllerGetUsersListApiArg
    >({
      query: (queryArg) => ({ url: `/v1/companies/${queryArg.id}/users-list` }),
    }),
    editUserControllerEditUser: build.mutation<
      EditUserControllerEditUserApiResponse,
      EditUserControllerEditUserApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.editUserRequestDto,
      }),
    }),
    adminMeControllerAdminMe: build.query<
      AdminMeControllerAdminMeApiResponse,
      AdminMeControllerAdminMeApiArg
    >({
      query: () => ({ url: `/v1/admins/me` }),
    }),
    outboundTestCallToWebControllerUserMe: build.mutation<
      OutboundTestCallToWebControllerUserMeApiResponse,
      OutboundTestCallToWebControllerUserMeApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/conversation/test/outbound-call-to-web`,
        method: 'POST',
        body: queryArg.outboundTestCallToWebV2RequestDto,
      }),
    }),
    hangupTestCallOnWebControllerHangup: build.mutation<
      HangupTestCallOnWebControllerHangupApiResponse,
      HangupTestCallOnWebControllerHangupApiArg
    >({
      query: () => ({ url: `/v1/conversation/test/hangup-call-on-web`, method: 'POST' }),
    }),
    outboundTestCallToPhoneControllerOutboundCall: build.mutation<
      OutboundTestCallToPhoneControllerOutboundCallApiResponse,
      OutboundTestCallToPhoneControllerOutboundCallApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/conversation/test/outbound-call-to-phone`,
        method: 'POST',
        body: queryArg.outboundTestCallToPhoneRequestDto,
      }),
    }),
    getOutboundTestCallToPhoneInfoControllerGetInfo: build.query<
      GetOutboundTestCallToPhoneInfoControllerGetInfoApiResponse,
      GetOutboundTestCallToPhoneInfoControllerGetInfoApiArg
    >({
      query: () => ({ url: `/v1/conversation/test/outbound-call-to-phone` }),
    }),
    startConversationRecordControllerStartConversationRecord: build.mutation<
      StartConversationRecordControllerStartConversationRecordApiResponse,
      StartConversationRecordControllerStartConversationRecordApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/system/recipient-conversation/start-record`,
        method: 'POST',
        body: queryArg.startConversationRecordRequestDto,
      }),
    }),
    hangupTestCallOnPhoneControllerUserMe: build.mutation<
      HangupTestCallOnPhoneControllerUserMeApiResponse,
      HangupTestCallOnPhoneControllerUserMeApiArg
    >({
      query: () => ({ url: `/v1/conversation/test/hangup-call-on-phone`, method: 'POST' }),
    }),
    loadTestAgentsControllerLoadTestAgents: build.mutation<
      LoadTestAgentsControllerLoadTestAgentsApiResponse,
      LoadTestAgentsControllerLoadTestAgentsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/system/recipient-conversation/load-test-agents`,
        method: 'POST',
        body: queryArg.loadTestAgentsRequestDto,
      }),
    }),
    outboundMultipleCallsToPhoneControllerOutboundCalls: build.mutation<
      OutboundMultipleCallsToPhoneControllerOutboundCallsApiResponse,
      OutboundMultipleCallsToPhoneControllerOutboundCallsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/conversation/test/outbound-multiple-calls-to-phone`,
        method: 'POST',
        body: queryArg.outboundMultipleCallsToPhoneRequestDto,
      }),
    }),
    createCampaignControllerCreateCampaign: build.mutation<
      CreateCampaignControllerCreateCampaignApiResponse,
      CreateCampaignControllerCreateCampaignApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/campaign`,
        method: 'POST',
        body: queryArg.createCampaignRequestDto,
      }),
    }),
    createCampaignRunControllerCreateCampaignRun: build.mutation<
      CreateCampaignRunControllerCreateCampaignRunApiResponse,
      CreateCampaignRunControllerCreateCampaignRunApiArg
    >({
      query: (queryArg) => ({ url: `/v1/campaign/${queryArg.id}/run`, method: 'POST' }),
    }),
    conversationSentimentCreateSystemControllerCreateSentiment: build.mutation<
      ConversationSentimentCreateSystemControllerCreateSentimentApiResponse,
      ConversationSentimentCreateSystemControllerCreateSentimentApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/system/conversation/${queryArg.id}/sentiment`,
        method: 'POST',
        body: queryArg.conversationSentimentRequestDto,
        headers: { 'conversation-type': queryArg['conversation-type'] },
      }),
    }),
    deleteCampaignControllerDeleteCampaign: build.mutation<
      DeleteCampaignControllerDeleteCampaignApiResponse,
      DeleteCampaignControllerDeleteCampaignApiArg
    >({
      query: (queryArg) => ({ url: `/v1/campaign/${queryArg.id}`, method: 'DELETE' }),
    }),
    editCampaignControllerEditCampaign: build.mutation<
      EditCampaignControllerEditCampaignApiResponse,
      EditCampaignControllerEditCampaignApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/campaign/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.editCampaignRequestDto,
      }),
    }),
    deleteLeadListControllerDeleteLeadList: build.mutation<
      DeleteLeadListControllerDeleteLeadListApiResponse,
      DeleteLeadListControllerDeleteLeadListApiArg
    >({
      query: (queryArg) => ({ url: `/v1/campaign/lead-list/${queryArg.id}`, method: 'DELETE' }),
    }),
    finishingCampaignControllerFinishingCampaigns: build.mutation<
      FinishingCampaignControllerFinishingCampaignsApiResponse,
      FinishingCampaignControllerFinishingCampaignsApiArg
    >({
      query: () => ({ url: `/v1/finishing-campaign`, method: 'POST' }),
    }),
    forceRunRecipientsControllerForceRunRecipients: build.mutation<
      ForceRunRecipientsControllerForceRunRecipientsApiResponse,
      ForceRunRecipientsControllerForceRunRecipientsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/system/campaign/force-run-recipients`,
        method: 'POST',
        body: queryArg.forceRunRecipientsRequestDto,
      }),
    }),
    getAllCampaignsControllerGetAllCampaigns: build.query<
      GetAllCampaignsControllerGetAllCampaignsApiResponse,
      GetAllCampaignsControllerGetAllCampaignsApiArg
    >({
      query: () => ({ url: `/v1/campaigns` }),
    }),
    campaignControllerCampaign: build.query<
      CampaignControllerCampaignApiResponse,
      CampaignControllerCampaignApiArg
    >({
      query: (queryArg) => ({ url: `/v2/campaign/${queryArg.id}` }),
    }),
    campaignUpdateControllerCampaignUpdate: build.mutation<
      CampaignUpdateControllerCampaignUpdateApiResponse,
      CampaignUpdateControllerCampaignUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/campaign/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.campaignUpdateRequestDto,
      }),
    }),
    campaignControllerSystemCampaign: build.query<
      CampaignControllerSystemCampaignApiResponse,
      CampaignControllerSystemCampaignApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/system/campaign/${queryArg.id}`,
        params: { relations: queryArg.relations },
      }),
    }),
    getMedianCallDurationControllerGetCampaign: build.query<
      GetMedianCallDurationControllerGetCampaignApiResponse,
      GetMedianCallDurationControllerGetCampaignApiArg
    >({
      query: (queryArg) => ({ url: `/v1/campaign/${queryArg.id}/median-call-duration` }),
    }),
    getCallsReportControllerGetCallsReport: build.query<
      GetCallsReportControllerGetCallsReportApiResponse,
      GetCallsReportControllerGetCallsReportApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/get-calls-report`,
        params: {
          campaignId: queryArg.campaignId,
          companyId: queryArg.companyId,
          start: queryArg.start,
          end: queryArg.end,
        },
      }),
    }),
    getRecipientConversationListControllerGetCampaign: build.query<
      GetRecipientConversationListControllerGetCampaignApiResponse,
      GetRecipientConversationListControllerGetCampaignApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/campaign/${queryArg.id}/recipient-conversations`,
        params: {
          statuses: queryArg.statuses,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
        },
      }),
    }),
    processCampaignControllerProcessCampaign: build.mutation<
      ProcessCampaignControllerProcessCampaignApiResponse,
      ProcessCampaignControllerProcessCampaignApiArg
    >({
      query: () => ({ url: `/v1/process-recipients`, method: 'POST' }),
    }),
    processFollowUpControllerProcessCampaigns: build.mutation<
      ProcessFollowUpControllerProcessCampaignsApiResponse,
      ProcessFollowUpControllerProcessCampaignsApiArg
    >({
      query: () => ({ url: `/v1/process-follow-up`, method: 'POST' }),
    }),
    processMessageResultControllerProcessMessageResult: build.mutation<
      ProcessMessageResultControllerProcessMessageResultApiResponse,
      ProcessMessageResultControllerProcessMessageResultApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/process-message-result`,
        method: 'POST',
        body: queryArg.processMessageResultRequestDto,
        params: { token: queryArg.token },
      }),
    }),
    stopCampaignRunControllerStopCampaignRun: build.mutation<
      StopCampaignRunControllerStopCampaignRunApiResponse,
      StopCampaignRunControllerStopCampaignRunApiArg
    >({
      query: (queryArg) => ({ url: `/v1/campaign/${queryArg.id}/stop`, method: 'POST' }),
    }),
    terminateConversationControllerTerminateConversation: build.mutation<
      TerminateConversationControllerTerminateConversationApiResponse,
      TerminateConversationControllerTerminateConversationApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/recipient-conversation/${queryArg.id}/terminate`,
        method: 'POST',
      }),
    }),
    updateLeadListOrderControllerUpdateLeadListOrder: build.mutation<
      UpdateLeadListOrderControllerUpdateLeadListOrderApiResponse,
      UpdateLeadListOrderControllerUpdateLeadListOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/campaign/lead-list/order`,
        method: 'PATCH',
        body: queryArg.updateLeadListOrderRequestDto,
      }),
    }),
    campaignCreateControllerCampaignCreate: build.mutation<
      CampaignCreateControllerCampaignCreateApiResponse,
      CampaignCreateControllerCampaignCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/campaign`,
        method: 'POST',
        body: queryArg.campaignCreateRequestDto,
      }),
    }),
    campaignRunControllerCampaignRun: build.mutation<
      CampaignRunControllerCampaignRunApiResponse,
      CampaignRunControllerCampaignRunApiArg
    >({
      query: (queryArg) => ({ url: `/v2/campaign/${queryArg.id}/run`, method: 'POST' }),
    }),
    campaignStopControllerCampaignStop: build.mutation<
      CampaignStopControllerCampaignStopApiResponse,
      CampaignStopControllerCampaignStopApiArg
    >({
      query: (queryArg) => ({ url: `/v2/campaign/${queryArg.id}/stop`, method: 'POST' }),
    }),
    campaignCompleteFinishingSystemControllerCompleteFinishing: build.mutation<
      CampaignCompleteFinishingSystemControllerCompleteFinishingApiResponse,
      CampaignCompleteFinishingSystemControllerCompleteFinishingApiArg
    >({
      query: () => ({ url: `/v2/system/campaign/complete-finishing`, method: 'POST' }),
    }),
    campaignCallControllerCampaignCall: build.mutation<
      CampaignCallControllerCampaignCallApiResponse,
      CampaignCallControllerCampaignCallApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/campaign/${queryArg.id}/call`,
        method: 'POST',
        body: queryArg.campaignCallRequestDto,
      }),
    }),
    campaignCallHangupControllerCampaignCallHangup: build.mutation<
      CampaignCallHangupControllerCampaignCallHangupApiResponse,
      CampaignCallHangupControllerCampaignCallHangupApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/campaign/${queryArg.id}/call/${queryArg.conversationId}/hangup`,
        method: 'POST',
      }),
    }),
    campaignSipTrunkCreateControllerCampaignCreate: build.mutation<
      CampaignSipTrunkCreateControllerCampaignCreateApiResponse,
      CampaignSipTrunkCreateControllerCampaignCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/campaign/${queryArg.id}/sip-trunk`,
        method: 'POST',
        body: queryArg.campaignSipTrunkCreateRequestDto,
      }),
    }),
    inboundConversationUpdateSystemControllerInboundConversationUpdate: build.mutation<
      InboundConversationUpdateSystemControllerInboundConversationUpdateApiResponse,
      InboundConversationUpdateSystemControllerInboundConversationUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/system/inbound-conversation/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.inboundConversationUpdateRequestDto,
      }),
    }),
    inboundConversationSystemControllerInboundConversation: build.query<
      InboundConversationSystemControllerInboundConversationApiResponse,
      InboundConversationSystemControllerInboundConversationApiArg
    >({
      query: (queryArg) => ({ url: `/v2/system/inbound-conversation/${queryArg.id}` }),
    }),
    recipientConversationProcessSystemControllerRecipientConversationProcess: build.mutation<
      RecipientConversationProcessSystemControllerRecipientConversationProcessApiResponse,
      RecipientConversationProcessSystemControllerRecipientConversationProcessApiArg
    >({
      query: () => ({ url: `/v2/system/recipient-conversation/process`, method: 'POST' }),
    }),
    recipientConversationUpdateSystemControllerRecipientConversationUpdate: build.mutation<
      RecipientConversationUpdateSystemControllerRecipientConversationUpdateApiResponse,
      RecipientConversationUpdateSystemControllerRecipientConversationUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/system/recipient-conversation/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.recipientConversationUpdateRequestDto,
        headers: { 'conversation-type': queryArg['conversation-type'] },
      }),
    }),
    recipientConversationSystemControllerRecipientConversation: build.query<
      RecipientConversationSystemControllerRecipientConversationApiResponse,
      RecipientConversationSystemControllerRecipientConversationApiArg
    >({
      query: (queryArg) => ({ url: `/v2/system/recipient-conversation/${queryArg.id}` }),
    }),
    conversationResultSaveSystemControllerSaveConversationResult: build.mutation<
      ConversationResultSaveSystemControllerSaveConversationResultApiResponse,
      ConversationResultSaveSystemControllerSaveConversationResultApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/system/conversation/${queryArg.id}/result`,
        method: 'POST',
        body: queryArg.conversationResultSaveRequestDto,
      }),
    }),
    campaignCallExternalControllerCampaignExternalCall: build.mutation<
      CampaignCallExternalControllerCampaignExternalCallApiResponse,
      CampaignCallExternalControllerCampaignExternalCallApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/external/campaign/${queryArg.id}/call`,
        method: 'POST',
        body: queryArg.campaignCallExternalRequestDto,
      }),
    }),
    campaignCallControllerCall: build.query<
      CampaignCallControllerCallApiResponse,
      CampaignCallControllerCallApiArg
    >({
      query: (queryArg) => ({ url: `/v2/campaign/${queryArg.id}/call/${queryArg.conversationId}` }),
    }),
    campaignRunSystemControllerCampaignRun: build.query<
      CampaignRunSystemControllerCampaignRunApiResponse,
      CampaignRunSystemControllerCampaignRunApiArg
    >({
      query: (queryArg) => ({ url: `/v2/system/campaign-run/${queryArg.id}` }),
    }),
    inboundConversationListControllerInboundConversation: build.query<
      InboundConversationListControllerInboundConversationApiResponse,
      InboundConversationListControllerInboundConversationApiArg
    >({
      query: (queryArg) => ({ url: `/v2/campaign/${queryArg.id}/inbound-conversations` }),
    }),
    getAgentControllerGetAgent: build.query<
      GetAgentControllerGetAgentApiResponse,
      GetAgentControllerGetAgentApiArg
    >({
      query: (queryArg) => ({ url: `/v1/agents/${queryArg.id}` }),
    }),
    deleteAgentControllerDeleteAgent: build.mutation<
      DeleteAgentControllerDeleteAgentApiResponse,
      DeleteAgentControllerDeleteAgentApiArg
    >({
      query: (queryArg) => ({ url: `/v1/agents/${queryArg.id}`, method: 'DELETE' }),
    }),
    editAgentControllerEditAgent: build.mutation<
      EditAgentControllerEditAgentApiResponse,
      EditAgentControllerEditAgentApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/agents/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.editAgentRequestDto,
      }),
    }),
    getAgentSystemControllerGetAgent: build.query<
      GetAgentSystemControllerGetAgentApiResponse,
      GetAgentSystemControllerGetAgentApiArg
    >({
      query: (queryArg) => ({ url: `/v1/system/agents/${queryArg.id}` }),
    }),
    getAllAgentsControllerGetAllAgents: build.query<
      GetAllAgentsControllerGetAllAgentsApiResponse,
      GetAllAgentsControllerGetAllAgentsApiArg
    >({
      query: () => ({ url: `/v1/agents` }),
    }),
    createAgentControllerCreateAgent: build.mutation<
      CreateAgentControllerCreateAgentApiResponse,
      CreateAgentControllerCreateAgentApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/agents`,
        method: 'POST',
        body: queryArg.createAgentRequestDto,
      }),
    }),
    getAllVoicesControllerGetAllVoices: build.query<
      GetAllVoicesControllerGetAllVoicesApiResponse,
      GetAllVoicesControllerGetAllVoicesApiArg
    >({
      query: () => ({ url: `/v1/voices` }),
    }),
    getAllVoicesSystemControllerGetAllVoices: build.query<
      GetAllVoicesSystemControllerGetAllVoicesApiResponse,
      GetAllVoicesSystemControllerGetAllVoicesApiArg
    >({
      query: () => ({ url: `/v1/system/voices` }),
    }),
    getUsageControllerGetUsage: build.query<
      GetUsageControllerGetUsageApiResponse,
      GetUsageControllerGetUsageApiArg
    >({
      query: () => ({ url: `/v1/admin/model-usage` }),
    }),
    getVoiceControllerGetVoice: build.query<
      GetVoiceControllerGetVoiceApiResponse,
      GetVoiceControllerGetVoiceApiArg
    >({
      query: (queryArg) => ({ url: `/v1/voices/${queryArg.id}` }),
    }),
    editVoiceControllerEditVoice: build.mutation<
      EditVoiceControllerEditVoiceApiResponse,
      EditVoiceControllerEditVoiceApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/voices/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.voiceRequestDto,
      }),
    }),
    getVoiceSystemControllerGetVoice: build.query<
      GetVoiceSystemControllerGetVoiceApiResponse,
      GetVoiceSystemControllerGetVoiceApiArg
    >({
      query: (queryArg) => ({ url: `/v1/system/voices/${queryArg.id}` }),
    }),
    editVoiceSystemControllerEditVoice: build.mutation<
      EditVoiceSystemControllerEditVoiceApiResponse,
      EditVoiceSystemControllerEditVoiceApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/system/voices/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.voiceRequestDto,
      }),
    }),
    createVoiceControllerCreateVoice: build.mutation<
      CreateVoiceControllerCreateVoiceApiResponse,
      CreateVoiceControllerCreateVoiceApiArg
    >({
      query: (queryArg) => ({ url: `/v1/voice`, method: 'POST', body: queryArg.voiceRequestDto }),
    }),
    createVoiceSystemControllerCreateVoice: build.mutation<
      CreateVoiceSystemControllerCreateVoiceApiResponse,
      CreateVoiceSystemControllerCreateVoiceApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/system/voice`,
        method: 'POST',
        body: queryArg.voiceRequestDto,
      }),
    }),
    deleteVoiceControllerDeleteVoice: build.mutation<
      DeleteVoiceControllerDeleteVoiceApiResponse,
      DeleteVoiceControllerDeleteVoiceApiArg
    >({
      query: (queryArg) => ({ url: `/v1/voice/${queryArg.id}`, method: 'DELETE' }),
    }),
    deleteVoiceSystemControllerDeleteVoice: build.mutation<
      DeleteVoiceSystemControllerDeleteVoiceApiResponse,
      DeleteVoiceSystemControllerDeleteVoiceApiArg
    >({
      query: (queryArg) => ({ url: `/v1/system/voice/${queryArg.id}`, method: 'DELETE' }),
    }),
    createCompanyControllerCreateCompany: build.mutation<
      CreateCompanyControllerCreateCompanyApiResponse,
      CreateCompanyControllerCreateCompanyApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/company`,
        method: 'POST',
        body: queryArg.createCompanyRequestDto,
      }),
    }),
    getAllCompaniesControllerGetAllCompanies: build.query<
      GetAllCompaniesControllerGetAllCompaniesApiResponse,
      GetAllCompaniesControllerGetAllCompaniesApiArg
    >({
      query: () => ({ url: `/v1/companies` }),
    }),
    getCompanyControllerGetCompany: build.query<
      GetCompanyControllerGetCompanyApiResponse,
      GetCompanyControllerGetCompanyApiArg
    >({
      query: (queryArg) => ({ url: `/v1/companies/${queryArg.id}` }),
    }),
    editCompanyControllerEditCompany: build.mutation<
      EditCompanyControllerEditCompanyApiResponse,
      EditCompanyControllerEditCompanyApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/company/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.editCompanyRequestDto,
      }),
    }),
    getAllCompanyIdsControllerGetAllCompanyIds: build.query<
      GetAllCompanyIdsControllerGetAllCompanyIdsApiResponse,
      GetAllCompanyIdsControllerGetAllCompanyIdsApiArg
    >({
      query: () => ({ url: `/v1/system/companies/only-ids` }),
    }),
    companyApiKeyCreateControllerApiKeyCreate: build.mutation<
      CompanyApiKeyCreateControllerApiKeyCreateApiResponse,
      CompanyApiKeyCreateControllerApiKeyCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/system/companies/${queryArg.id}/api-keys`,
        method: 'POST',
      }),
    }),
    companyApiKeyRevokeControllerApiKeyRevoke: build.mutation<
      CompanyApiKeyRevokeControllerApiKeyRevokeApiResponse,
      CompanyApiKeyRevokeControllerApiKeyRevokeApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/system/companies/${queryArg.id}/api-keys/${queryArg.apiKeyId}`,
        method: 'DELETE',
      }),
    }),
    agentProfileCreateControllerAgentProfileCreate: build.mutation<
      AgentProfileCreateControllerAgentProfileCreateApiResponse,
      AgentProfileCreateControllerAgentProfileCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/admin/companies/${queryArg.id}/agent-profiles`,
        method: 'POST',
        body: queryArg.agentProfileCreateRequestDto,
      }),
    }),
    agentProfileListControllerAgentProfileListForAdmins: build.query<
      AgentProfileListControllerAgentProfileListForAdminsApiResponse,
      AgentProfileListControllerAgentProfileListForAdminsApiArg
    >({
      query: (queryArg) => ({ url: `/v2/admin/companies/${queryArg.id}/agent-profiles` }),
    }),
    agentProfileEditControllerAgentProfileEdit: build.mutation<
      AgentProfileEditControllerAgentProfileEditApiResponse,
      AgentProfileEditControllerAgentProfileEditApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/admin/companies/${queryArg.id}/agent-profiles/${queryArg.profileId}`,
        method: 'PATCH',
        body: queryArg.agentProfileEditRequestDto,
      }),
    }),
    agentProfileDeleteControllerAgentProfileDelete: build.mutation<
      AgentProfileDeleteControllerAgentProfileDeleteApiResponse,
      AgentProfileDeleteControllerAgentProfileDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/admin/companies/${queryArg.id}/agent-profiles/${queryArg.profileId}`,
        method: 'DELETE',
      }),
    }),
    agentProfileListControllerAgentProfileListForUsers: build.query<
      AgentProfileListControllerAgentProfileListForUsersApiResponse,
      AgentProfileListControllerAgentProfileListForUsersApiArg
    >({
      query: (queryArg) => ({ url: `/v2/companies/${queryArg.id}/agent-profiles` }),
    }),
    livekitControllerWebhook: build.mutation<
      LivekitControllerWebhookApiResponse,
      LivekitControllerWebhookApiArg
    >({
      query: () => ({ url: `/v1/webhooks/livekit`, method: 'POST' }),
    }),
    healthControllerCheck: build.query<
      HealthControllerCheckApiResponse,
      HealthControllerCheckApiArg
    >({
      query: () => ({ url: `/health` }),
    }),
    textToSpeechControllerCreateCampaign: build.mutation<
      TextToSpeechControllerCreateCampaignApiResponse,
      TextToSpeechControllerCreateCampaignApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/voice-ai/text-to-speech`,
        method: 'POST',
        body: queryArg.textToSpeechRequestDto,
        params: { enhance: queryArg.enhance, debug: queryArg.debug },
      }),
    }),
    demoCompanyControllerGetCompany: build.query<
      DemoCompanyControllerGetCompanyApiResponse,
      DemoCompanyControllerGetCompanyApiArg
    >({
      query: (queryArg) => ({ url: `/v1/demo/companies/${queryArg.hash}` }),
    }),
    demoCompanyAgentListControllerGetCompanyAgentList: build.query<
      DemoCompanyAgentListControllerGetCompanyAgentListApiResponse,
      DemoCompanyAgentListControllerGetCompanyAgentListApiArg
    >({
      query: (queryArg) => ({ url: `/v1/demo/companies/${queryArg.hash}/agents` }),
    }),
    demoUuidToHashControllerUuidToHash: build.query<
      DemoUuidToHashControllerUuidToHashApiResponse,
      DemoUuidToHashControllerUuidToHashApiArg
    >({
      query: (queryArg) => ({ url: `/v1/demo/uuid-to-hash/${queryArg.uuid}` }),
    }),
    demoCallCreateControllerCreateCall: build.mutation<
      DemoCallCreateControllerCreateCallApiResponse,
      DemoCallCreateControllerCreateCallApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/demo/companies/${queryArg.hash}/call-phone`,
        method: 'POST',
        body: queryArg.demoCallCreateRequestDto,
      }),
    }),
    demoWebhookControllerWebhook: build.mutation<
      DemoWebhookControllerWebhookApiResponse,
      DemoWebhookControllerWebhookApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/demo/webhook`,
        method: 'POST',
        headers: { 'x-vapi-secret': queryArg['x-vapi-secret'] },
      }),
    }),
    demoCallsControllerGetCalls: build.query<
      DemoCallsControllerGetCallsApiResponse,
      DemoCallsControllerGetCallsApiArg
    >({
      query: (queryArg) => ({ url: `/v1/demo/companies/${queryArg.hash}/calls` }),
    }),
    demoSyncControllerSync: build.mutation<
      DemoSyncControllerSyncApiResponse,
      DemoSyncControllerSyncApiArg
    >({
      query: (queryArg) => ({ url: `/v1/demo/companies/${queryArg.hash}/sync`, method: 'PUT' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as apiService };
export type AppControllerGetHelloApiResponse = /** status 200  */ string;
export type AppControllerGetHelloApiArg = void;
export type MetricsControllerWebhookApiResponse = unknown;
export type MetricsControllerWebhookApiArg = void;
export type RefreshTokensControllerRefreshTokenUserApiResponse =
  /** status 201  */ TokenResponseDto;
export type RefreshTokensControllerRefreshTokenUserApiArg = void;
export type RefreshTokensControllerRefreshTokenAdminApiResponse =
  /** status 201  */ TokenResponseDto;
export type RefreshTokensControllerRefreshTokenAdminApiArg = void;
export type SignInControllerSignInApiResponse = /** status 201  */ TokenResponseDto;
export type SignInControllerSignInApiArg = {
  signInRequestDto: SignInRequestDto;
};
export type AdminSignInControllerSignInApiResponse = /** status 201  */ AdminSignInResponseDto;
export type AdminSignInControllerSignInApiArg = {
  adminSignInRequestDto: AdminSignInRequestDto;
};
export type ForgotPasswordControllerForgotPasswordApiResponse =
  /** status 201  */ ForgotPasswordResponseDto;
export type ForgotPasswordControllerForgotPasswordApiArg = {
  forgotPasswordRequestDto: ForgotPasswordRequestDto;
};
export type ResetPasswordControllerResetPasswordApiResponse =
  /** status 201  */ ResetPasswordResponseDto;
export type ResetPasswordControllerResetPasswordApiArg = {
  resetPasswordRequestDto: ResetPasswordRequestDto;
};
export type UserMeControllerUserMeApiResponse = /** status 200  */ UserDto;
export type UserMeControllerUserMeApiArg = void;
export type GetUsersListControllerGetUsersListApiResponse = /** status 200  */ UserDto[];
export type GetUsersListControllerGetUsersListApiArg = {
  id: string;
};
export type EditUserControllerEditUserApiResponse = /** status 200  */ UserDto;
export type EditUserControllerEditUserApiArg = {
  id: string;
  editUserRequestDto: EditUserRequestDto;
};
export type AdminMeControllerAdminMeApiResponse = /** status 200  */ AdminDto;
export type AdminMeControllerAdminMeApiArg = void;
export type OutboundTestCallToWebControllerUserMeApiResponse =
  /** status 201  */ OutboundTestCallToWebV2ResponseDto;
export type OutboundTestCallToWebControllerUserMeApiArg = {
  outboundTestCallToWebV2RequestDto: OutboundTestCallToWebV2RequestDto;
};
export type HangupTestCallOnWebControllerHangupApiResponse = /** status 201  */ BaseOkResponseDto;
export type HangupTestCallOnWebControllerHangupApiArg = void;
export type OutboundTestCallToPhoneControllerOutboundCallApiResponse =
  /** status 201  */ ConversationInfoDto;
export type OutboundTestCallToPhoneControllerOutboundCallApiArg = {
  outboundTestCallToPhoneRequestDto: OutboundTestCallToPhoneRequestDto;
};
export type GetOutboundTestCallToPhoneInfoControllerGetInfoApiResponse =
  /** status 200  */ ConversationInfoDto;
export type GetOutboundTestCallToPhoneInfoControllerGetInfoApiArg = void;
export type StartConversationRecordControllerStartConversationRecordApiResponse =
  /** status 201  */ StartConversationRecordResponseDto;
export type StartConversationRecordControllerStartConversationRecordApiArg = {
  startConversationRecordRequestDto: StartConversationRecordRequestDto;
};
export type HangupTestCallOnPhoneControllerUserMeApiResponse = /** status 201  */ BaseOkResponseDto;
export type HangupTestCallOnPhoneControllerUserMeApiArg = void;
export type LoadTestAgentsControllerLoadTestAgentsApiResponse =
  /** status 201  */ LoadTestAgentsResponseDto;
export type LoadTestAgentsControllerLoadTestAgentsApiArg = {
  loadTestAgentsRequestDto: LoadTestAgentsRequestDto;
};
export type OutboundMultipleCallsToPhoneControllerOutboundCallsApiResponse =
  /** status 201  */ ConversationInfoDto[];
export type OutboundMultipleCallsToPhoneControllerOutboundCallsApiArg = {
  outboundMultipleCallsToPhoneRequestDto: OutboundMultipleCallsToPhoneRequestDto;
};
export type CreateCampaignControllerCreateCampaignApiResponse = /** status 201  */ CampaignDto;
export type CreateCampaignControllerCreateCampaignApiArg = {
  createCampaignRequestDto: CreateCampaignRequestDto;
};
export type CreateCampaignRunControllerCreateCampaignRunApiResponse =
  /** status 201  */ CampaignRunDto;
export type CreateCampaignRunControllerCreateCampaignRunApiArg = {
  id: string;
};
export type ConversationSentimentCreateSystemControllerCreateSentimentApiResponse = unknown;
export type ConversationSentimentCreateSystemControllerCreateSentimentApiArg = {
  id: string;
  /** Conversation type */
  'conversation-type'?:
    | 'outbound-campaign-call'
    | 'test-outbound-campaign-call'
    | 'inbound-campaign-call';
  conversationSentimentRequestDto: ConversationSentimentRequestDto;
};
export type DeleteCampaignControllerDeleteCampaignApiResponse =
  /** status 200  */ BaseOkResponseDto;
export type DeleteCampaignControllerDeleteCampaignApiArg = {
  id: string;
};
export type EditCampaignControllerEditCampaignApiResponse = /** status 200  */ CampaignDto;
export type EditCampaignControllerEditCampaignApiArg = {
  id: string;
  editCampaignRequestDto: EditCampaignRequestDto;
};
export type DeleteLeadListControllerDeleteLeadListApiResponse =
  /** status 200  */ DeleteLeadListResponseDto | /** status 204  */ void;
export type DeleteLeadListControllerDeleteLeadListApiArg = {
  id: string;
};
export type FinishingCampaignControllerFinishingCampaignsApiResponse =
  /** status 201  */ CampaignRunDto[];
export type FinishingCampaignControllerFinishingCampaignsApiArg = void;
export type ForceRunRecipientsControllerForceRunRecipientsApiResponse =
  /** status 201  */ ForceRunRecipientsResponseDto[];
export type ForceRunRecipientsControllerForceRunRecipientsApiArg = {
  forceRunRecipientsRequestDto: ForceRunRecipientsRequestDto;
};
export type GetAllCampaignsControllerGetAllCampaignsApiResponse =
  /** status 200  */ CampaignWithDetailedInfoDto[];
export type GetAllCampaignsControllerGetAllCampaignsApiArg = void;
export type CampaignControllerCampaignApiResponse = /** status 200  */ CampaignDto;
export type CampaignControllerCampaignApiArg = {
  id: string;
};
export type CampaignUpdateControllerCampaignUpdateApiResponse = /** status 200  */ CampaignDto;
export type CampaignUpdateControllerCampaignUpdateApiArg = {
  id: string;
  campaignUpdateRequestDto: CampaignUpdateRequestDto;
};
export type CampaignControllerSystemCampaignApiResponse = /** status 200  */ CampaignDto;
export type CampaignControllerSystemCampaignApiArg = {
  id: string;
  relations?:
    | 'agent'
    | 'callSchedule'
    | 'campaignRun'
    | 'leadList'
    | 'leads'
    | 'sipTrunks'
    | 'analysis';
};
export type GetMedianCallDurationControllerGetCampaignApiResponse =
  /** status 200  */ MedianCallDurationDto;
export type GetMedianCallDurationControllerGetCampaignApiArg = {
  id: string;
};
export type GetCallsReportControllerGetCallsReportApiResponse = unknown;
export type GetCallsReportControllerGetCallsReportApiArg = {
  campaignId?: string;
  companyId?: string;
  start?: string;
  end?: string;
};
export type GetRecipientConversationListControllerGetCampaignApiResponse =
  /** status 200  */ RecipientConversationDto[];
export type GetRecipientConversationListControllerGetCampaignApiArg = {
  id: string;
  statuses?: string;
  sortBy?: string;
  sortOrder?: string;
};
export type ProcessCampaignControllerProcessCampaignApiResponse =
  /** status 201  */ RecipientConversationDto[];
export type ProcessCampaignControllerProcessCampaignApiArg = void;
export type ProcessFollowUpControllerProcessCampaignsApiResponse = /** status 201  */ string[];
export type ProcessFollowUpControllerProcessCampaignsApiArg = void;
export type ProcessMessageResultControllerProcessMessageResultApiResponse =
  /** status 201  */ string;
export type ProcessMessageResultControllerProcessMessageResultApiArg = {
  token: string;
  processMessageResultRequestDto: ProcessMessageResultRequestDto;
};
export type StopCampaignRunControllerStopCampaignRunApiResponse = /** status 201  */ CampaignRunDto;
export type StopCampaignRunControllerStopCampaignRunApiArg = {
  id: string;
};
export type TerminateConversationControllerTerminateConversationApiResponse =
  /** status 201  */ RecipientConversationDto;
export type TerminateConversationControllerTerminateConversationApiArg = {
  id: string;
};
export type UpdateLeadListOrderControllerUpdateLeadListOrderApiResponse =
  /** status 200  */ UpdateLeadListOrderResponseDto;
export type UpdateLeadListOrderControllerUpdateLeadListOrderApiArg = {
  updateLeadListOrderRequestDto: UpdateLeadListOrderRequestDto;
};
export type CampaignCreateControllerCampaignCreateApiResponse = /** status 201  */ CampaignDto;
export type CampaignCreateControllerCampaignCreateApiArg = {
  campaignCreateRequestDto: CampaignCreateRequestDto;
};
export type CampaignRunControllerCampaignRunApiResponse = /** status 201  */ CampaignRunDto;
export type CampaignRunControllerCampaignRunApiArg = {
  id: string;
};
export type CampaignStopControllerCampaignStopApiResponse = /** status 201  */ CampaignRunDto;
export type CampaignStopControllerCampaignStopApiArg = {
  id: string;
};
export type CampaignCompleteFinishingSystemControllerCompleteFinishingApiResponse = unknown;
export type CampaignCompleteFinishingSystemControllerCompleteFinishingApiArg = void;
export type CampaignCallControllerCampaignCallApiResponse =
  /** status 201  */ CampaignCallResponseDto;
export type CampaignCallControllerCampaignCallApiArg = {
  id: string;
  campaignCallRequestDto: CampaignCallRequestDto;
};
export type CampaignCallHangupControllerCampaignCallHangupApiResponse =
  /** status 201  */ CampaignCallResponseDto;
export type CampaignCallHangupControllerCampaignCallHangupApiArg = {
  id: string;
  conversationId: string;
};
export type CampaignSipTrunkCreateControllerCampaignCreateApiResponse =
  /** status 201  */ SipTrunkDto;
export type CampaignSipTrunkCreateControllerCampaignCreateApiArg = {
  id: string;
  campaignSipTrunkCreateRequestDto: CampaignSipTrunkCreateRequestDto;
};
export type InboundConversationUpdateSystemControllerInboundConversationUpdateApiResponse = unknown;
export type InboundConversationUpdateSystemControllerInboundConversationUpdateApiArg = {
  id: string;
  inboundConversationUpdateRequestDto: InboundConversationUpdateRequestDto;
};
export type InboundConversationSystemControllerInboundConversationApiResponse = unknown;
export type InboundConversationSystemControllerInboundConversationApiArg = {
  id: string;
};
export type RecipientConversationProcessSystemControllerRecipientConversationProcessApiResponse =
  unknown;
export type RecipientConversationProcessSystemControllerRecipientConversationProcessApiArg = void;
export type RecipientConversationUpdateSystemControllerRecipientConversationUpdateApiResponse =
  unknown;
export type RecipientConversationUpdateSystemControllerRecipientConversationUpdateApiArg = {
  id: string;
  /** Conversation type */
  'conversation-type'?:
    | 'outbound-campaign-call'
    | 'test-outbound-campaign-call'
    | 'inbound-campaign-call';
  recipientConversationUpdateRequestDto: RecipientConversationUpdateRequestDto;
};
export type RecipientConversationSystemControllerRecipientConversationApiResponse = unknown;
export type RecipientConversationSystemControllerRecipientConversationApiArg = {
  id: string;
};
export type ConversationResultSaveSystemControllerSaveConversationResultApiResponse = unknown;
export type ConversationResultSaveSystemControllerSaveConversationResultApiArg = {
  id: string;
  conversationResultSaveRequestDto: ConversationResultSaveRequestDto;
};
export type CampaignCallExternalControllerCampaignExternalCallApiResponse =
  /** status 201  */ CampaignLeadDto[];
export type CampaignCallExternalControllerCampaignExternalCallApiArg = {
  id: string;
  campaignCallExternalRequestDto: CampaignCallExternalRequestDto;
};
export type CampaignCallControllerCallApiResponse = /** status 200  */ CampaignCallResponseDto;
export type CampaignCallControllerCallApiArg = {
  id: string;
  conversationId: string;
};
export type CampaignRunSystemControllerCampaignRunApiResponse = /** status 200  */ CampaignRunDto;
export type CampaignRunSystemControllerCampaignRunApiArg = {
  id: string;
};
export type InboundConversationListControllerInboundConversationApiResponse =
  /** status 200  */ InboundConversationDto[];
export type InboundConversationListControllerInboundConversationApiArg = {
  id: string;
};
export type GetAgentControllerGetAgentApiResponse = /** status 200  */ AgentDto;
export type GetAgentControllerGetAgentApiArg = {
  id: string;
};
export type DeleteAgentControllerDeleteAgentApiResponse = /** status 200  */ BaseOkResponseDto;
export type DeleteAgentControllerDeleteAgentApiArg = {
  id: string;
};
export type EditAgentControllerEditAgentApiResponse = /** status 200  */ AgentDto;
export type EditAgentControllerEditAgentApiArg = {
  id: string;
  editAgentRequestDto: EditAgentRequestDto;
};
export type GetAgentSystemControllerGetAgentApiResponse = /** status 200  */ AgentDto;
export type GetAgentSystemControllerGetAgentApiArg = {
  id: string;
};
export type GetAllAgentsControllerGetAllAgentsApiResponse = /** status 200  */ AgentDto[];
export type GetAllAgentsControllerGetAllAgentsApiArg = void;
export type CreateAgentControllerCreateAgentApiResponse = /** status 201  */ AgentDto;
export type CreateAgentControllerCreateAgentApiArg = {
  createAgentRequestDto: CreateAgentRequestDto;
};
export type GetAllVoicesControllerGetAllVoicesApiResponse = /** status 200  */ VoiceDto[];
export type GetAllVoicesControllerGetAllVoicesApiArg = void;
export type GetAllVoicesSystemControllerGetAllVoicesApiResponse = unknown;
export type GetAllVoicesSystemControllerGetAllVoicesApiArg = void;
export type GetUsageControllerGetUsageApiResponse = /** status 200  */ GetUsageResponseDto;
export type GetUsageControllerGetUsageApiArg = void;
export type GetVoiceControllerGetVoiceApiResponse = /** status 200  */ VoiceDto;
export type GetVoiceControllerGetVoiceApiArg = {
  id: string;
};
export type EditVoiceControllerEditVoiceApiResponse = /** status 200  */ VoiceDto;
export type EditVoiceControllerEditVoiceApiArg = {
  id: string;
  voiceRequestDto: VoiceRequestDto;
};
export type GetVoiceSystemControllerGetVoiceApiResponse = unknown;
export type GetVoiceSystemControllerGetVoiceApiArg = {
  id: string;
};
export type EditVoiceSystemControllerEditVoiceApiResponse = unknown;
export type EditVoiceSystemControllerEditVoiceApiArg = {
  id: string;
  voiceRequestDto: VoiceRequestDto;
};
export type CreateVoiceControllerCreateVoiceApiResponse = /** status 201  */ VoiceDto;
export type CreateVoiceControllerCreateVoiceApiArg = {
  voiceRequestDto: VoiceRequestDto;
};
export type CreateVoiceSystemControllerCreateVoiceApiResponse = unknown;
export type CreateVoiceSystemControllerCreateVoiceApiArg = {
  voiceRequestDto: VoiceRequestDto;
};
export type DeleteVoiceControllerDeleteVoiceApiResponse = /** status 200  */ BaseOkResponseDto;
export type DeleteVoiceControllerDeleteVoiceApiArg = {
  id: string;
};
export type DeleteVoiceSystemControllerDeleteVoiceApiResponse = unknown;
export type DeleteVoiceSystemControllerDeleteVoiceApiArg = {
  id: string;
};
export type CreateCompanyControllerCreateCompanyApiResponse = /** status 201  */ CompanyDto;
export type CreateCompanyControllerCreateCompanyApiArg = {
  createCompanyRequestDto: CreateCompanyRequestDto;
};
export type GetAllCompaniesControllerGetAllCompaniesApiResponse = /** status 200  */ CompanyDto[];
export type GetAllCompaniesControllerGetAllCompaniesApiArg = void;
export type GetCompanyControllerGetCompanyApiResponse = /** status 200  */ CompanyDto;
export type GetCompanyControllerGetCompanyApiArg = {
  id: string;
};
export type EditCompanyControllerEditCompanyApiResponse = /** status 200  */ CompanyDto;
export type EditCompanyControllerEditCompanyApiArg = {
  id: string;
  editCompanyRequestDto: EditCompanyRequestDto;
};
export type GetAllCompanyIdsControllerGetAllCompanyIdsApiResponse = /** status 200  */ string[];
export type GetAllCompanyIdsControllerGetAllCompanyIdsApiArg = void;
export type CompanyApiKeyCreateControllerApiKeyCreateApiResponse =
  /** status 201  */ CompanyApiKeyCreateResponseDto;
export type CompanyApiKeyCreateControllerApiKeyCreateApiArg = {
  id: string;
};
export type CompanyApiKeyRevokeControllerApiKeyRevokeApiResponse =
  /** status 200  */ BaseOkResponseDto;
export type CompanyApiKeyRevokeControllerApiKeyRevokeApiArg = {
  id: string;
  apiKeyId: string;
};
export type AgentProfileCreateControllerAgentProfileCreateApiResponse =
  /** status 201  */ AgentProfileResponseDto;
export type AgentProfileCreateControllerAgentProfileCreateApiArg = {
  id: string;
  agentProfileCreateRequestDto: AgentProfileCreateRequestDto;
};
export type AgentProfileListControllerAgentProfileListForAdminsApiResponse =
  /** status 200  */ AgentProfileResponseDto[];
export type AgentProfileListControllerAgentProfileListForAdminsApiArg = {
  id: string;
};
export type AgentProfileEditControllerAgentProfileEditApiResponse =
  /** status 200  */ AgentProfileResponseDto;
export type AgentProfileEditControllerAgentProfileEditApiArg = {
  id: string;
  profileId: string;
  agentProfileEditRequestDto: AgentProfileEditRequestDto;
};
export type AgentProfileDeleteControllerAgentProfileDeleteApiResponse =
  /** status 200  */ BaseOkResponseDto;
export type AgentProfileDeleteControllerAgentProfileDeleteApiArg = {
  id: string;
  profileId: string;
};
export type AgentProfileListControllerAgentProfileListForUsersApiResponse =
  /** status 200  */ AgentProfileResponseDto[];
export type AgentProfileListControllerAgentProfileListForUsersApiArg = {
  id: string;
};
export type LivekitControllerWebhookApiResponse = unknown;
export type LivekitControllerWebhookApiArg = void;
export type HealthControllerCheckApiResponse = /** status 200 The Health Check is successful */ {
  status?: string;
  info?: {
    [key: string]: {
      status: string;
      [key: string]: any;
    };
  } | null;
  error?: {
    [key: string]: {
      status: string;
      [key: string]: any;
    };
  } | null;
  details?: {
    [key: string]: {
      status: string;
      [key: string]: any;
    };
  };
};
export type HealthControllerCheckApiArg = void;
export type TextToSpeechControllerCreateCampaignApiResponse = unknown;
export type TextToSpeechControllerCreateCampaignApiArg = {
  enhance?: boolean;
  debug?: boolean;
  textToSpeechRequestDto: TextToSpeechRequestDto;
};
export type DemoCompanyControllerGetCompanyApiResponse = /** status 200  */ DemoCompanyDto;
export type DemoCompanyControllerGetCompanyApiArg = {
  hash: string;
};
export type DemoCompanyAgentListControllerGetCompanyAgentListApiResponse =
  /** status 200  */ DemoAgentDto[];
export type DemoCompanyAgentListControllerGetCompanyAgentListApiArg = {
  hash: string;
};
export type DemoUuidToHashControllerUuidToHashApiResponse = /** status 200  */ string;
export type DemoUuidToHashControllerUuidToHashApiArg = {
  uuid: string;
};
export type DemoCallCreateControllerCreateCallApiResponse = /** status 201  */ DemoCallDto;
export type DemoCallCreateControllerCreateCallApiArg = {
  hash: string;
  demoCallCreateRequestDto: DemoCallCreateRequestDto;
};
export type DemoWebhookControllerWebhookApiResponse = unknown;
export type DemoWebhookControllerWebhookApiArg = {
  'x-vapi-secret': string;
};
export type DemoCallsControllerGetCallsApiResponse = /** status 200  */ DemoCallDto[];
export type DemoCallsControllerGetCallsApiArg = {
  hash: string;
};
export type DemoSyncControllerSyncApiResponse = /** status 200  */ BaseOkResponseDto;
export type DemoSyncControllerSyncApiArg = {
  hash: string;
};
export type TokenResponseDto = {
  accessToken: string;
  refreshToken: string;
};
export type SignInRequestDto = {
  login: string;
  password: string;
};
export type AdminSignInResponseDto = {
  accessToken: string;
};
export type AdminSignInRequestDto = {
  login: string;
  password: string;
};
export type ForgotPasswordResponseDto = {
  message: string;
};
export type ForgotPasswordRequestDto = {
  email: string;
};
export type ResetPasswordResponseDto = {
  message: string;
};
export type ResetPasswordRequestDto = {
  token: string;
  password: string;
};
export type UserRoles = 'admin';
export type UserDto = {
  firstName: string;
  lastName: string;
  email: string;
  role: UserRoles;
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  companyId: string;
};
export type EditUserRequestDto = {
  firstName: string;
  lastName: string;
  password?: string;
};
export type AdminRoles = 'manager';
export type AdminDto = {
  id: string;
  email: string;
  role: AdminRoles;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};
export type ConversationInfoDto = {
  id: string;
  creationTime: string;
  roomName: string;
  metadata: string;
};
export type OutboundTestCallToWebV2ResponseDto = {
  ok: boolean;
  callInfo: ConversationInfoDto;
  roomAccessToken: string;
};
export type AgentModelType = 'gpt-4o' | 'gpt-4-turbo' | 'gpt-4' | 'gpt-3.5-turbo' | 'gpt-4o-mini';
export type OutboundTestCallToWebV2RequestDto = {
  name: string;
  model?: AgentModelType;
  profileId?: string;
  prompt: string;
  languages?: string[];
  voiceId: string;
};
export type BaseOkResponseDto = {
  ok: boolean;
};
export type OutboundTestCallToPhoneRequestDto = {
  name: string;
  model?: AgentModelType;
  profileId?: string;
  prompt: string;
  languages?: string[];
  voiceId: string;
  calleePhone: string;
  trunkName?: string | null;
};
export type StartConversationRecordResponseDto = {
  egressId: string;
  roomId: string;
  roomName: string;
};
export type StartConversationRecordRequestDto = {
  roomName: string;
};
export type LoadTestAgentsResponseDto = {
  ok: boolean;
  agentsInfoRan: ConversationInfoDto[];
};
export type LoadTestAgentsRequestDto = {
  numberOfAgents?: number;
  agentId: string;
};
export type OutboundMultipleCallsToPhoneRequestDto = {
  agentId: string;
  calleePhones: string[];
  trunkName?: string | null;
};
export type CampaignType = 'inbound' | 'outbound';
export type CampaignLeadDto = {
  phone: string;
  id: string;
  name: string | null;
  email: string | null;
  leadListId: string;
  metadata: string | null;
  createdAt: string;
  updatedAt: string;
};
export type CampaignLeadListDto = {
  name: string;
  order: number;
  campaignId: string;
  leads: CampaignLeadDto[];
  createdAt: string;
  updatedAt: string;
  id: string;
};
export type CallScheduleDto = {
  mondayStart?: string | null;
  mondayEnd?: string | null;
  tuesdayStart?: string | null;
  tuesdayEnd?: string | null;
  wednesdayStart?: string | null;
  wednesdayEnd?: string | null;
  thursdayStart?: string | null;
  thursdayEnd?: string | null;
  fridayStart?: string | null;
  fridayEnd?: string | null;
  saturdayStart?: string | null;
  saturdayEnd?: string | null;
  sundayStart?: string | null;
  sundayEnd?: string | null;
  timezone?: string;
};
export type CampaignRunStatusType = 'active' | 'inactive' | 'finishing';
export type RecipientConversationStatusType =
  | 'pending'
  | 'queued'
  | 'ringing'
  | 'in-progress'
  | 'completed'
  | 'failed'
  | 'terminated';
export type RecipientInteractionResultType =
  | 'follow_up_completed'
  | 'follow_up_required'
  | 'follow_up_processing'
  | 'follow_up_failed'
  | 'callback_requested'
  | 'callback_completed'
  | 'do_not_call';
export type RecipientConversationSentimentType = 'positive' | 'negative' | 'neutral';
export type SentimentScoreDto = {
  label: RecipientConversationSentimentType;
  score: number;
};
export type ConversationSentimentDto = {
  sentimentScores: SentimentScoreDto[];
  createdAt: string;
  id: string;
  recipientConversationId?: string;
  inboundConversationId?: string;
  updatedAt: string;
};
export type RecipientConversationDto = {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: RecipientConversationStatusType;
  interactionResult?: RecipientInteractionResultType;
  leadId: string;
  campaignRunId: string;
  failReason?: string;
  startedAt?: string;
  answeredAt?: string;
  terminatedAt?: string;
  attemptsToday: number;
  totalAttempts: number;
  lastAttemptedAt?: string;
  callbackAt?: string;
  lead?: CampaignLeadDto | null;
  sentiments?: ConversationSentimentDto[];
};
export type CampaignRunDto = {
  id: string;
  status: CampaignRunStatusType;
  startTime: string;
  endTime?: string;
  campaignId: string;
  createdAt: string;
  updatedAt: string;
  recipientConversations?: RecipientConversationDto;
};
export type VoiceDto = {
  id: string;
  voiceId: string;
  name: string;
  previewUrl: string;
  gender?: string;
  accent?: string;
  /** Voice json settings */
  voiceSettings?: object;
  createdAt: string;
  updatedAt: string;
};
export type AgentProfileResponseDto = {
  id: string;
  name: string;
  prompt?: string;
};
export type CampaignAgentDto = {
  id: string;
  name: string;
  campaignId: string;
  voiceId: string;
  voice?: VoiceDto | null;
  profileId?: string;
  profile?: AgentProfileResponseDto | null;
  languages?: string[];
  createdAt: string;
  updatedAt: string;
};
export type SipTrunkDto = {
  id: string;
  createdAt: string;
  updatedAt: string;
  type: object;
  campaignId: string;
  sipTrunkId?: string | null;
};
export type CampaignAnalysisDto = {
  id: string;
  createdAt: string;
  updatedAt: string;
  prompt: string;
};
export type CampaignDto = {
  title: string;
  type: CampaignType;
  description?: string | null;
  retryIntervalInMinutes?: number | null;
  retryMaxNumber?: number | null;
  retriesPerDay?: number | null;
  productDetails?: string | null;
  followUpTemplate?: string | null;
  sendFollowUpMessage?: boolean;
  id: string;
  companyId: string;
  leadList?: CampaignLeadListDto[];
  callSchedule?: CallScheduleDto;
  campaignRun?: CampaignRunDto;
  agents?: CampaignAgentDto[];
  sipTrunks?: SipTrunkDto[];
  analysis?: CampaignAnalysisDto;
  createdAt: string;
  updatedAt: string;
};
export type LeadSimpleDto = {
  phone: string;
};
export type LeadListSimpleDto = {
  name: string;
  leads: LeadSimpleDto[];
};
export type CallScheduleRequestDto = {
  mondayStart?: string | null;
  mondayEnd?: string | null;
  tuesdayStart?: string | null;
  tuesdayEnd?: string | null;
  wednesdayStart?: string | null;
  wednesdayEnd?: string | null;
  thursdayStart?: string | null;
  thursdayEnd?: string | null;
  fridayStart?: string | null;
  fridayEnd?: string | null;
  saturdayStart?: string | null;
  saturdayEnd?: string | null;
  sundayStart?: string | null;
  sundayEnd?: string | null;
  timezone?: string;
};
export type CreateCampaignRequestDto = {
  title: string;
  type: CampaignType;
  description?: string | null;
  retryIntervalInMinutes?: number | null;
  retryMaxNumber?: number | null;
  retriesPerDay?: number | null;
  productDetails?: string | null;
  followUpTemplate?: string | null;
  sendFollowUpMessage?: boolean;
  leadList: LeadListSimpleDto[];
  callSchedule?: CallScheduleRequestDto;
};
export type ConversationSentimentRequestDto = {
  sentimentScores: SentimentScoreDto[];
  createdAt: string;
};
export type EditCampaignRequestDto = {
  title?: string;
  type?: CampaignType;
  description?: string | null;
  retryIntervalInMinutes?: number | null;
  retryMaxNumber?: number | null;
  retriesPerDay?: number | null;
  productDetails?: string | null;
  followUpTemplate?: string | null;
  sendFollowUpMessage?: boolean;
  leadList?: LeadListSimpleDto[];
  callSchedule?: CallScheduleRequestDto;
};
export type DeleteLeadListResponseDto = {
  deletedLeadsCount: number;
  leadListDeleted: boolean;
};
export type ForceRunRecipientsResponseDto = {
  ok: boolean;
  companyId: string;
  timezone: string;
  campaignId: string;
  conversationId: string;
  roomName: string;
  phone: string;
};
export type RecipientIdWithPriorityDto = {
  /** Conversation ID that will be forcibly pushed to the message broker queue. */
  recipientConversationId: string;
  priority?: number | null;
};
export type ForceRunRecipientsRequestDto = {
  recipients: RecipientIdWithPriorityDto[];
};
export type CampaignWithDetailedInfoDto = {
  title: string;
  type: CampaignType;
  description?: string | null;
  retryIntervalInMinutes?: number | null;
  retryMaxNumber?: number | null;
  retriesPerDay?: number | null;
  productDetails?: string | null;
  followUpTemplate?: string | null;
  sendFollowUpMessage?: boolean;
  id: string;
  companyId: string;
  leadList?: CampaignLeadListDto[];
  callSchedule?: CallScheduleDto;
  campaignRun?: CampaignRunDto;
  agents?: CampaignAgentDto[];
  sipTrunks?: SipTrunkDto[];
  analysis?: CampaignAnalysisDto;
  createdAt: string;
  updatedAt: string;
  pendingLeadsCount: number;
  reachedLeadsCount: number;
  unreachedLeadsCount: number;
  processingLeadCounts: number;
  ongoingCallCount: number;
  plannedRecallCount: number;
  activeRunsCount: number;
  totalLeads: number;
};
export type CampaignAgentRequestDto = {
  id?: string;
  model?: AgentModelType;
  name: string;
  profileId?: string;
  languages: string[];
  voiceId: string;
};
export type CampaignUpdateRequestDto = {
  title?: string;
  type?: CampaignType;
  description?: string | null;
  retryIntervalInMinutes?: number | null;
  retryMaxNumber?: number | null;
  retriesPerDay?: number | null;
  productDetails?: string | null;
  followUpTemplate?: string | null;
  sendFollowUpMessage?: boolean;
  leadList?: LeadListSimpleDto[];
  callSchedule?: CallScheduleRequestDto;
  agents?: CampaignAgentRequestDto[];
};
export type MedianCallDurationDto = {
  medianCallDuration: number | null;
};
export type DeviceSimpleDto = {
  id: string;
};
export type ContactSimpleDto = {
  wid: string;
};
export type ChatSimpleDto = {
  contact: ContactSimpleDto;
};
export type DataSimpleDto = {
  chat: ChatSimpleDto;
};
export type ProcessMessageResultRequestDto = {
  id: string;
  event: string;
  device: DeviceSimpleDto;
  data: DataSimpleDto;
};
export type OrderItemDto = {
  id: string;
  order: number;
};
export type UpdateLeadListOrderResponseDto = {
  changedOrder: OrderItemDto[];
};
export type ListOrderUpdate = {
  id: string;
  order: number;
};
export type UpdateLeadListOrderRequestDto = {
  updates: ListOrderUpdate[];
};
export type CampaignCreateRequestDto = {
  title: string;
  type: CampaignType;
  description?: string | null;
  retryIntervalInMinutes?: number | null;
  retryMaxNumber?: number | null;
  retriesPerDay?: number | null;
  productDetails?: string | null;
  followUpTemplate?: string | null;
  sendFollowUpMessage?: boolean;
  leadList?: LeadListSimpleDto[];
  callSchedule?: CallScheduleRequestDto;
  agents?: CampaignAgentRequestDto[];
};
export type CampaignCallType = 'web-call' | 'outbound-phone-call';
export type ConversationStatusType =
  | 'pending'
  | 'queued'
  | 'ringing'
  | 'in-progress'
  | 'completed'
  | 'failed'
  | 'terminated';
export type CampaignCallResponseDto = {
  ok: boolean;
  callType: CampaignCallType;
  roomAccessToken?: string;
  conversationId: string;
  status: ConversationStatusType;
  roomName: string;
  metadata: string;
};
export type CampaignCallRequestDto = {
  callType: CampaignCallType;
  agentId?: string;
  /** omit id field */
  agent?: CampaignAgentRequestDto;
  phoneNumber?: string;
  /** just ignor that field */
  dummyProperty?: object;
};
export type TrunkType = 'inbound' | 'outbound';
export type CampaignSipTrunkCreateRequestDto = {
  type: TrunkType;
  authUsername?: string | null;
  authPassword?: string | null;
  numbers: string[];
  allowedNumbers?: string[] | null;
  allowedAddresses?: string[] | null;
  address?: string | null;
};
export type InboundConversationStatusType =
  | 'ringing'
  | 'in-progress'
  | 'completed'
  | 'missed'
  | 'failed'
  | 'terminated';
export type InboundConversationUpdateRequestDto = {
  status?: InboundConversationStatusType;
  result?: object;
  endedReason?: string;
  startedAt?: string;
  answeredAt?: string;
  finishedAt?: string;
};
export type CampaignRunRecipientStatusType =
  | 'pending'
  | 'queued'
  | 'ringing'
  | 'in-progress'
  | 'completed'
  | 'failed'
  | 'terminated';
export type RecipientConversationUpdateRequestDto = {
  status?: CampaignRunRecipientStatusType;
  interactionResult?: RecipientInteractionResultType;
  failReason?: string;
  callbackAt?: string;
  attemptsToday?: number;
  totalAttempts?: number;
  lastAttemptedAt?: string;
  startedAt?: string;
  answeredAt?: string;
  terminatedAt?: string;
};
export type ConversationResultSaveRequestDto = {
  result: object;
  isFinal?: boolean | null;
};
export type CampaignCallExternalMetadataDto = {
  participantName?: string;
  previousAgentName?: string;
  previousAgentCallAt?: string;
  score?: number;
};
export type CampaignLeadsDto = {
  phoneNumber: string;
  timeToDeal?: string;
  metadata?: CampaignCallExternalMetadataDto;
};
export type CampaignCallExternalRequestDto = {
  leads: CampaignLeadsDto[];
};
export type CustomerDto = {
  id: string;
  createdAt: string;
  updatedAt: string;
  number: string;
  name?: string;
};
export type InboundConversationDto = {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: InboundConversationStatusType;
  result?: string;
  endedReason?: string;
  startedAt?: string;
  answeredAt?: string;
  finishedAt?: string;
  campaignRunId: string;
  sentiments?: ConversationSentimentDto[];
  customerId: string;
  customer?: CustomerDto;
};
export type AgentModels = 'gpt-4o' | 'gpt-4-turbo' | 'gpt-4' | 'gpt-3.5-turbo' | 'gpt-4o-mini';
export type MissionDto = {
  id: string;
  humanName: string;
  intro: string;
  goal: string;
  offerDetails: string;
  farewell: string;
  createdAt: string;
  updatedAt: string;
};
export type AgentDto = {
  id: string;
  name: string;
  model: AgentModels;
  profile?: string;
  voiceId: string;
  companyId: string;
  missionId: string;
  langfusePromptId: string;
  createdAt: string;
  updatedAt: string;
  voice?: VoiceDto | null;
  mission?: MissionDto | null;
};
export type EditMissionRequestDto = {
  humanName: string;
  intro: string;
  goal: string;
  offerDetails: string;
  farewell: string;
};
export type EditAgentRequestDto = {
  name: string;
  profile?: string;
  model: AgentModels;
  voiceId: string;
  mission: EditMissionRequestDto;
};
export type CreateMissionRequestDto = {
  humanName: string;
  intro: string;
  goal: string;
  offerDetails: string;
  farewell: string;
};
export type CreateAgentRequestDto = {
  name: string;
  profile?: string;
  model: AgentModels;
  voiceId: string;
  mission: CreateMissionRequestDto;
};
export type PeriodDto = {
  start: string;
  end: string;
};
export type AiUsageDto = {
  organizationId: string;
  organizationName: string;
  snapshotId: string;
  operation: string;
  totalRequests: number;
  totalContextTokens: number;
  totalGeneratedTokens: number;
  totalCachedContextTokens: number;
  projectId: ((string | null) | (null | null)) | null;
};
export type AiUsageResultDto = {
  period: PeriodDto;
  data: AiUsageDto[];
};
export type TtsUsageDto = {
  characterCount: number;
  characterLimit: number;
};
export type TtsUsageResultDto = {
  period: PeriodDto;
  data: TtsUsageDto;
};
export type SttUsageDto = {
  projectName: string;
  projectId: string;
  hours: number;
  totalHours: number;
};
export type SttUsageResultDto = {
  period: PeriodDto;
  data: SttUsageDto[];
};
export type GetUsageResponseDto = {
  ai: AiUsageResultDto;
  tts: TtsUsageResultDto;
  stt: SttUsageResultDto;
};
export type VoiceRequestDto = {
  voiceId: string;
  name: string;
  previewUrl: string;
  gender?: string;
  accent?: string;
  /** Voice json settings */
  voiceSettings?: object;
};
export type CompanyDto = {
  id: string;
  name: string;
  logo?: string | null;
  description?: string | null;
  industryType?: string | null;
  address?: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
  agents?: AgentDto[];
  users?: UserDto[];
};
export type UserRequestDto = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: UserRoles;
};
export type CreateCompanyRequestDto = {
  name: string;
  description?: string | null;
  logo?: string | null;
  users: UserRequestDto[];
};
export type EditCompanyRequestDto = {
  name: string;
  description: string | null;
  logo: string | null;
};
export type CompanyApiKeyCreateResponseDto = {
  ok: boolean;
  apiKey: string;
};
export type AgentProfileCreateRequestDto = {
  name: string;
  prompt: string;
};
export type AgentProfileEditRequestDto = {
  name?: string;
  prompt?: string;
};
export type ExternalVoiceSettingsDto = {
  /** Stability level of the voice */
  stability: number;
  /** Similarity boost for the voice */
  similarityBoost: number;
  /** Style intensity for the voice */
  style: number;
  /** Use speaker boost or not */
  useSpeakerBoost: boolean;
  /** External voice ID used to reference voices in a third-party service. */
  externalVoiceId: string;
};
export type TextToSpeechRequestDto = {
  text: string;
  /** Optional voice id for the text-to-speech conversion */
  voiceId?: string;
  /** Optional voice settings for customization */
  voiceSettings?: ExternalVoiceSettingsDto;
  tempPayload?: string;
};
export type DemoCompanyDto = {
  id: string;
  name: string;
  isAdmin: boolean;
  spentSeconds: number;
  maxAvailableSeconds: number;
  createdAt: string;
  updatedAt: string;
};
export type DemoAgentDto = {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
};
export type DemoCallStatus = 'queued' | 'ringing' | 'in-progress' | 'forwarding' | 'ended';
export type DemoCallType = 'inboundPhoneCall' | 'outboundPhoneCall' | 'webCall';
export type DemoCallArtifactsDto = {
  recordingUrl?: string;
};
export type CallEndedReason =
  | 'pipeline-error-openai-voice-failed'
  | 'pipeline-error-cartesia-voice-failed'
  | 'pipeline-error-deepgram-voice-failed'
  | 'pipeline-error-eleven-labs-voice-failed'
  | 'pipeline-error-playht-voice-failed'
  | 'pipeline-error-lmnt-voice-failed'
  | 'pipeline-error-azure-voice-failed'
  | 'pipeline-error-rime-ai-voice-failed'
  | 'pipeline-error-neets-voice-failed'
  | 'db-error'
  | 'assistant-not-found'
  | 'license-check-failed'
  | 'pipeline-error-vapi-llm-failed'
  | 'pipeline-error-vapi-400-bad-request-validation-failed'
  | 'pipeline-error-vapi-401-unauthorized'
  | 'pipeline-error-vapi-403-model-access-denied'
  | 'pipeline-error-vapi-429-exceeded-quota'
  | 'pipeline-error-vapi-500-server-error'
  | 'pipeline-no-available-model'
  | 'worker-shutdown'
  | 'unknown-error'
  | 'vonage-disconnected'
  | 'vonage-failed-to-connect-call'
  | 'phone-call-provider-bypass-enabled-but-no-call-received'
  | 'vapifault-phone-call-worker-setup-socket-error'
  | 'vapifault-phone-call-worker-worker-setup-socket-timeout'
  | 'vapifault-phone-call-worker-could-not-find-call'
  | 'vapifault-transport-never-connected'
  | 'vapifault-web-call-worker-setup-failed'
  | 'vapifault-transport-connected-but-call-not-active'
  | 'vapifault-call-started-but-connection-to-transport-missing'
  | 'pipeline-error-deepgram-transcriber-failed'
  | 'pipeline-error-gladia-transcriber-failed'
  | 'pipeline-error-assembly-ai-transcriber-failed'
  | 'pipeline-error-openai-llm-failed'
  | 'pipeline-error-azure-openai-llm-failed'
  | 'pipeline-error-groq-llm-failed'
  | 'pipeline-error-google-llm-failed'
  | 'pipeline-error-xai-llm-failed'
  | 'pipeline-error-inflection-ai-llm-failed'
  | 'assistant-not-invalid'
  | 'assistant-not-provided'
  | 'call-start-error-neither-assistant-nor-server-set'
  | 'assistant-request-failed'
  | 'assistant-request-returned-error'
  | 'assistant-request-returned-unspeakable-error'
  | 'assistant-request-returned-invalid-assistant'
  | 'assistant-request-returned-no-assistant'
  | 'assistant-request-returned-forwarding-phone-number'
  | 'assistant-ended-call'
  | 'assistant-said-end-call-phrase'
  | 'assistant-forwarded-call'
  | 'assistant-join-timed-out'
  | 'customer-busy'
  | 'customer-ended-call'
  | 'customer-did-not-answer'
  | 'customer-did-not-give-microphone-permission'
  | 'assistant-said-message-with-end-call-enabled'
  | 'exceeded-max-duration'
  | 'manually-canceled'
  | 'phone-call-provider-closed-websocket'
  | 'pipeline-error-openai-400-bad-request-validation-failed'
  | 'pipeline-error-openai-401-unauthorized'
  | 'pipeline-error-openai-403-model-access-denied'
  | 'pipeline-error-openai-429-exceeded-quota'
  | 'pipeline-error-openai-500-server-error'
  | 'pipeline-error-google-400-bad-request-validation-failed'
  | 'pipeline-error-google-401-unauthorized'
  | 'pipeline-error-google-403-model-access-denied'
  | 'pipeline-error-google-429-exceeded-quota'
  | 'pipeline-error-google-500-server-error'
  | 'pipeline-error-xai-400-bad-request-validation-failed'
  | 'pipeline-error-xai-401-unauthorized'
  | 'pipeline-error-xai-403-model-access-denied'
  | 'pipeline-error-xai-429-exceeded-quota'
  | 'pipeline-error-xai-500-server-error'
  | 'pipeline-error-inflection-ai-400-bad-request-validation-failed'
  | 'pipeline-error-inflection-ai-401-unauthorized'
  | 'pipeline-error-inflection-ai-403-model-access-denied'
  | 'pipeline-error-inflection-ai-429-exceeded-quota'
  | 'pipeline-error-inflection-ai-500-server-error'
  | 'pipeline-error-azure-openai-400-bad-request-validation-failed'
  | 'pipeline-error-azure-openai-401-unauthorized'
  | 'pipeline-error-azure-openai-403-model-access-denied'
  | 'pipeline-error-azure-openai-429-exceeded-quota'
  | 'pipeline-error-azure-openai-500-server-error'
  | 'pipeline-error-groq-400-bad-request-validation-failed'
  | 'pipeline-error-groq-401-unauthorized'
  | 'pipeline-error-groq-403-model-access-denied'
  | 'pipeline-error-groq-429-exceeded-quota'
  | 'pipeline-error-groq-500-server-error'
  | 'pipeline-error-anthropic-400-bad-request-validation-failed'
  | 'pipeline-error-anthropic-401-unauthorized'
  | 'pipeline-error-anthropic-403-model-access-denied'
  | 'pipeline-error-anthropic-429-exceeded-quota'
  | 'pipeline-error-anthropic-500-server-error'
  | 'pipeline-error-anthropic-llm-failed'
  | 'pipeline-error-together-ai-400-bad-request-validation-failed'
  | 'pipeline-error-together-ai-401-unauthorized'
  | 'pipeline-error-together-ai-403-model-access-denied'
  | 'pipeline-error-together-ai-429-exceeded-quota'
  | 'pipeline-error-together-ai-500-server-error'
  | 'pipeline-error-together-ai-llm-failed'
  | 'pipeline-error-anyscale-400-bad-request-validation-failed'
  | 'pipeline-error-anyscale-401-unauthorized'
  | 'pipeline-error-anyscale-403-model-access-denied'
  | 'pipeline-error-anyscale-429-exceeded-quota'
  | 'pipeline-error-anyscale-500-server-error'
  | 'pipeline-error-anyscale-llm-failed'
  | 'pipeline-error-openrouter-400-bad-request-validation-failed'
  | 'pipeline-error-openrouter-401-unauthorized'
  | 'pipeline-error-openrouter-403-model-access-denied'
  | 'pipeline-error-openrouter-429-exceeded-quota'
  | 'pipeline-error-openrouter-500-server-error'
  | 'pipeline-error-openrouter-llm-failed'
  | 'pipeline-error-perplexity-ai-400-bad-request-validation-failed'
  | 'pipeline-error-perplexity-ai-401-unauthorized'
  | 'pipeline-error-perplexity-ai-403-model-access-denied'
  | 'pipeline-error-perplexity-ai-429-exceeded-quota'
  | 'pipeline-error-perplexity-ai-500-server-error'
  | 'pipeline-error-perplexity-ai-llm-failed'
  | 'pipeline-error-deepinfra-400-bad-request-validation-failed'
  | 'pipeline-error-deepinfra-401-unauthorized'
  | 'pipeline-error-deepinfra-403-model-access-denied'
  | 'pipeline-error-deepinfra-429-exceeded-quota'
  | 'pipeline-error-deepinfra-500-server-error'
  | 'pipeline-error-deepinfra-llm-failed'
  | 'pipeline-error-runpod-400-bad-request-validation-failed'
  | 'pipeline-error-runpod-401-unauthorized'
  | 'pipeline-error-runpod-403-model-access-denied'
  | 'pipeline-error-runpod-429-exceeded-quota'
  | 'pipeline-error-runpod-500-server-error'
  | 'pipeline-error-runpod-llm-failed'
  | 'pipeline-error-custom-llm-400-bad-request-validation-failed'
  | 'pipeline-error-custom-llm-401-unauthorized'
  | 'pipeline-error-custom-llm-403-model-access-denied'
  | 'pipeline-error-custom-llm-429-exceeded-quota'
  | 'pipeline-error-custom-llm-500-server-error'
  | 'pipeline-error-custom-llm-llm-failed'
  | 'pipeline-error-custom-voice-failed'
  | 'pipeline-error-cartesia-socket-hang-up'
  | 'pipeline-error-cartesia-requested-payment'
  | 'pipeline-error-cartesia-500-server-error'
  | 'pipeline-error-cartesia-503-server-error'
  | 'pipeline-error-cartesia-522-server-error'
  | 'pipeline-error-eleven-labs-voice-not-found'
  | 'pipeline-error-eleven-labs-quota-exceeded'
  | 'pipeline-error-eleven-labs-unauthorized-access'
  | 'pipeline-error-eleven-labs-unauthorized-to-access-model'
  | 'pipeline-error-eleven-labs-professional-voices-only-for-creator-plus'
  | 'pipeline-error-eleven-labs-blocked-free-plan-and-requested-upgrade'
  | 'pipeline-error-eleven-labs-blocked-concurrent-requests-and-requested-upgrade'
  | 'pipeline-error-eleven-labs-blocked-using-instant-voice-clone-and-requested-upgrade'
  | 'pipeline-error-eleven-labs-system-busy-and-requested-upgrade'
  | 'pipeline-error-eleven-labs-voice-not-fine-tuned'
  | 'pipeline-error-eleven-labs-invalid-api-key'
  | 'pipeline-error-eleven-labs-invalid-voice-samples'
  | 'pipeline-error-eleven-labs-voice-disabled-by-owner'
  | 'pipeline-error-eleven-labs-blocked-account-in-probation'
  | 'pipeline-error-eleven-labs-blocked-content-against-their-policy'
  | 'pipeline-error-eleven-labs-missing-samples-for-voice-clone'
  | 'pipeline-error-eleven-labs-voice-not-fine-tuned-and-cannot-be-used'
  | 'pipeline-error-eleven-labs-voice-not-allowed-for-free-users'
  | 'pipeline-error-eleven-labs-500-server-error'
  | 'pipeline-error-eleven-labs-max-character-limit-exceeded'
  | 'pipeline-error-eleven-labs-blocked-voice-potentially-against-terms-of-service-and-awaiting-verification'
  | 'pipeline-error-playht-request-timed-out'
  | 'pipeline-error-playht-invalid-voice'
  | 'pipeline-error-playht-unexpected-error'
  | 'pipeline-error-playht-out-of-credits'
  | 'pipeline-error-playht-invalid-emotion'
  | 'pipeline-error-playht-voice-must-be-a-valid-voice-manifest-uri'
  | 'pipeline-error-playht-401-unauthorized'
  | 'pipeline-error-playht-403-forbidden-out-of-characters'
  | 'pipeline-error-playht-403-forbidden-api-access-not-available'
  | 'pipeline-error-playht-429-exceeded-quota'
  | 'pipeline-error-playht-502-gateway-error'
  | 'pipeline-error-playht-504-gateway-error'
  | 'pipeline-error-deepgram-returning-403-model-access-denied'
  | 'pipeline-error-deepgram-returning-401-invalid-credentials'
  | 'pipeline-error-deepgram-returning-404-not-found'
  | 'pipeline-error-deepgram-returning-400-no-such-model-language-tier-combination'
  | 'pipeline-error-deepgram-returning-500-invalid-json'
  | 'pipeline-error-deepgram-returning-502-network-error'
  | 'pipeline-error-deepgram-returning-502-bad-gateway-ehostunreach'
  | 'pipeline-error-tavus-video-failed'
  | 'pipeline-error-custom-transcriber-failed'
  | 'silence-timed-out'
  | 'sip-gateway-failed-to-connect-call'
  | 'twilio-failed-to-connect-call'
  | 'twilio-reported-customer-misdialed'
  | 'vonage-rejected'
  | 'voicemail';
export type DemoCallDto = {
  id: string;
  startedAt?: string;
  endedAt?: string;
  status: DemoCallStatus;
  type: DemoCallType;
  artifacts?: DemoCallArtifactsDto;
  endedReason?: CallEndedReason;
  customerPhone: string;
  agentId: string;
  companyId: string;
  company?: DemoCompanyDto;
  agent?: DemoAgentDto;
  createdAt: string;
  updatedAt: string;
};
export type DemoCallCreateRequestDto = {
  agentId: string;
  phone: string;
};
export const {
  useAppControllerGetHelloQuery,
  useMetricsControllerWebhookQuery,
  useRefreshTokensControllerRefreshTokenUserMutation,
  useRefreshTokensControllerRefreshTokenAdminMutation,
  useSignInControllerSignInMutation,
  useAdminSignInControllerSignInMutation,
  useForgotPasswordControllerForgotPasswordMutation,
  useResetPasswordControllerResetPasswordMutation,
  useUserMeControllerUserMeQuery,
  useGetUsersListControllerGetUsersListQuery,
  useEditUserControllerEditUserMutation,
  useAdminMeControllerAdminMeQuery,
  useOutboundTestCallToWebControllerUserMeMutation,
  useHangupTestCallOnWebControllerHangupMutation,
  useOutboundTestCallToPhoneControllerOutboundCallMutation,
  useGetOutboundTestCallToPhoneInfoControllerGetInfoQuery,
  useStartConversationRecordControllerStartConversationRecordMutation,
  useHangupTestCallOnPhoneControllerUserMeMutation,
  useLoadTestAgentsControllerLoadTestAgentsMutation,
  useOutboundMultipleCallsToPhoneControllerOutboundCallsMutation,
  useCreateCampaignControllerCreateCampaignMutation,
  useCreateCampaignRunControllerCreateCampaignRunMutation,
  useConversationSentimentCreateSystemControllerCreateSentimentMutation,
  useDeleteCampaignControllerDeleteCampaignMutation,
  useEditCampaignControllerEditCampaignMutation,
  useDeleteLeadListControllerDeleteLeadListMutation,
  useFinishingCampaignControllerFinishingCampaignsMutation,
  useForceRunRecipientsControllerForceRunRecipientsMutation,
  useGetAllCampaignsControllerGetAllCampaignsQuery,
  useCampaignControllerCampaignQuery,
  useCampaignUpdateControllerCampaignUpdateMutation,
  useCampaignControllerSystemCampaignQuery,
  useGetMedianCallDurationControllerGetCampaignQuery,
  useGetCallsReportControllerGetCallsReportQuery,
  useGetRecipientConversationListControllerGetCampaignQuery,
  useProcessCampaignControllerProcessCampaignMutation,
  useProcessFollowUpControllerProcessCampaignsMutation,
  useProcessMessageResultControllerProcessMessageResultMutation,
  useStopCampaignRunControllerStopCampaignRunMutation,
  useTerminateConversationControllerTerminateConversationMutation,
  useUpdateLeadListOrderControllerUpdateLeadListOrderMutation,
  useCampaignCreateControllerCampaignCreateMutation,
  useCampaignRunControllerCampaignRunMutation,
  useCampaignStopControllerCampaignStopMutation,
  useCampaignCompleteFinishingSystemControllerCompleteFinishingMutation,
  useCampaignCallControllerCampaignCallMutation,
  useCampaignCallHangupControllerCampaignCallHangupMutation,
  useCampaignSipTrunkCreateControllerCampaignCreateMutation,
  useInboundConversationUpdateSystemControllerInboundConversationUpdateMutation,
  useInboundConversationSystemControllerInboundConversationQuery,
  useRecipientConversationProcessSystemControllerRecipientConversationProcessMutation,
  useRecipientConversationUpdateSystemControllerRecipientConversationUpdateMutation,
  useRecipientConversationSystemControllerRecipientConversationQuery,
  useConversationResultSaveSystemControllerSaveConversationResultMutation,
  useCampaignCallExternalControllerCampaignExternalCallMutation,
  useCampaignCallControllerCallQuery,
  useCampaignRunSystemControllerCampaignRunQuery,
  useInboundConversationListControllerInboundConversationQuery,
  useGetAgentControllerGetAgentQuery,
  useDeleteAgentControllerDeleteAgentMutation,
  useEditAgentControllerEditAgentMutation,
  useGetAgentSystemControllerGetAgentQuery,
  useGetAllAgentsControllerGetAllAgentsQuery,
  useCreateAgentControllerCreateAgentMutation,
  useGetAllVoicesControllerGetAllVoicesQuery,
  useGetAllVoicesSystemControllerGetAllVoicesQuery,
  useGetUsageControllerGetUsageQuery,
  useGetVoiceControllerGetVoiceQuery,
  useEditVoiceControllerEditVoiceMutation,
  useGetVoiceSystemControllerGetVoiceQuery,
  useEditVoiceSystemControllerEditVoiceMutation,
  useCreateVoiceControllerCreateVoiceMutation,
  useCreateVoiceSystemControllerCreateVoiceMutation,
  useDeleteVoiceControllerDeleteVoiceMutation,
  useDeleteVoiceSystemControllerDeleteVoiceMutation,
  useCreateCompanyControllerCreateCompanyMutation,
  useGetAllCompaniesControllerGetAllCompaniesQuery,
  useGetCompanyControllerGetCompanyQuery,
  useEditCompanyControllerEditCompanyMutation,
  useGetAllCompanyIdsControllerGetAllCompanyIdsQuery,
  useCompanyApiKeyCreateControllerApiKeyCreateMutation,
  useCompanyApiKeyRevokeControllerApiKeyRevokeMutation,
  useAgentProfileCreateControllerAgentProfileCreateMutation,
  useAgentProfileListControllerAgentProfileListForAdminsQuery,
  useAgentProfileEditControllerAgentProfileEditMutation,
  useAgentProfileDeleteControllerAgentProfileDeleteMutation,
  useAgentProfileListControllerAgentProfileListForUsersQuery,
  useLivekitControllerWebhookMutation,
  useHealthControllerCheckQuery,
  useTextToSpeechControllerCreateCampaignMutation,
  useDemoCompanyControllerGetCompanyQuery,
  useDemoCompanyAgentListControllerGetCompanyAgentListQuery,
  useDemoUuidToHashControllerUuidToHashQuery,
  useDemoCallCreateControllerCreateCallMutation,
  useDemoWebhookControllerWebhookMutation,
  useDemoCallsControllerGetCallsQuery,
  useDemoSyncControllerSyncMutation,
} = injectedRtkApi;
