import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Chip,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import { capitalize } from 'lodash';

import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { IconTrash, IconExternalLink, IconArrowUp, IconArrowDown } from '@tabler/icons-react';

import {
  CampaignWithDetailedInfoDto,
  useDeleteCampaignControllerDeleteCampaignMutation,
  CampaignRunStatusType,
  useCampaignStopControllerCampaignStopMutation,
  useCampaignRunControllerCampaignRunMutation,
} from '@shared/services/apiService/apiService';
import BlankCard from '@shared/components/shared/BlankCard';
import { CampaignListProps, SortingCampaignCoulmns } from '@shared/types/admin/campaign';

import { useStyles } from './styles';
import CampaignStatusButton, { CampaignStatus } from './CampaignStatusButton/CampaignStatusButton';
import Spinner from '@shared/components/spinner/Spinner';
import { ConfirmDeleteDialog } from '@shared/components/confirmDeleteDialog/ConfirmDeleteDialog';
import { ConfirmDialog } from '@shared/components/confirmDialog/ConfirmDialog';
import { formatTime } from '@shared/utils/FormatTime';
import { getNextAvailableCallTimestamp, isOutOfSchedule } from '@shared/utils/DateUtil';
import { actions } from '@shared/store/ducks';
import { useAppDispatch } from '@shared/store';
import NewCampaignDialog from './NewCampaignDialog';
import { CampaignTypeEnum } from '@shared/constants/campaign.constants';
import { IconCircleArrowLeft, IconCircleArrowRight } from '@shared/assets/images/svgs';

export const CampaignList: React.FC<CampaignListProps> = ({
  setLoading,
  campaignList,
  onCampaignDeleteError,
  refetchCampaignsList,
}: CampaignListProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const [useDeleteCampaign] = useDeleteCampaignControllerDeleteCampaignMutation();

  const [useRunCampaign, { isLoading: isRunningLoading }] =
    useCampaignRunControllerCampaignRunMutation();
  const [useStopCampaign, { isLoading: isStoppingLoading }] =
    useCampaignStopControllerCampaignStopMutation();

  const [campaigns, setCampaigns] = useState<CampaignWithDetailedInfoDto[]>([]);

  const [sortConfig, setSortConfig] = useState({
    title: undefined,
    status: undefined,
    totalLeads: undefined,
    processedNumbers: undefined,
    pendingLeadsCount: undefined,
    reachedLeadsCount: undefined,
    unreachedLeadsCount: undefined,
    plannedRecallCount: undefined,
    type: undefined,
  });
  const [showNewCampaignDialog, setShowNewCampaignDialog] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showScheduleWarningDilaogFlag, setShowScheduleWarningDialogFlag] = useState(false);
  const [timeToStart, setTimeToStart] = useState<number>(new Date().getTime());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteId, setDeleteId] = useState<string | null>();
  const [campaignToRun, setCampaignToRun] = useState<CampaignWithDetailedInfoDto>();
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [responseLoadingFlag, setResponseLoadingFlag] = useState(false);

  useEffect(() => {
    dispatch(actions.breadcrumbs.setIsShownBreadcrumbs(true));
    dispatch(
      actions.breadcrumbs.setBreadcrumb({
        pageTitle: 'campaignPage.header.campaign',
        campaignType: null,
      }),
    );
    // TODO: hide page title until other designs are not done
    return () => {
      dispatch(actions.breadcrumbs.setIsShownBreadcrumbs(false));
    };
  }, [dispatch]);

  useEffect(() => {
    let sortedCampaigns = [...campaignList].sort((a, b) => {
      const dateA = a.updatedAt || a.createdAt;
      const dateB = b.updatedAt || b.createdAt;
      return dateB.localeCompare(dateA);
    });

    setCampaigns(sortedCampaigns);
  }, [campaignList]);

  const handleSort = (field: SortingCampaignCoulmns) => {
    // Toggle sort order for the specific field
    setSortConfig((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));

    const sortedCampaigns = sortArray(campaigns, field, sortConfig[field]);
    setCampaigns(sortedCampaigns);
  };

  const sortArray = (
    arr: CampaignWithDetailedInfoDto[],
    field: SortingCampaignCoulmns,
    ascending = true,
  ) => {
    if (field === 'status') {
      const statusOrder: CampaignRunStatusType[] = ['active', 'inactive', 'finishing'];

      return [...arr].sort((a: CampaignWithDetailedInfoDto, b: CampaignWithDetailedInfoDto) => {
        const statusA = a.campaignRun?.status || 'inactive';
        const statusB = b.campaignRun?.status || 'inactive';

        return ascending
          ? statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB)
          : statusOrder.indexOf(statusB) - statusOrder.indexOf(statusA);
      });
    }

    if (field === 'processedNumbers') {
      return [...arr].sort((a: CampaignWithDetailedInfoDto, b: CampaignWithDetailedInfoDto) => {
        const processedA = a.unreachedLeadsCount + a.reachedLeadsCount;
        const processedB = b.unreachedLeadsCount + b.reachedLeadsCount;
        if (processedA < processedB) return ascending ? -1 : 1;
        if (processedA > processedB) return ascending ? 1 : -1;
        return 0;
      });
    }

    return [...arr].sort((a: CampaignWithDetailedInfoDto, b: CampaignWithDetailedInfoDto) => {
      if (a[field] < b[field]) return ascending ? -1 : 1;
      if (a[field] > b[field]) return ascending ? 1 : -1;
      return 0;
    });
  };

  const statusBadge = (status?: CampaignRunStatusType) => {
    const colorMap = {
      active: theme.palette.success.light,
      inactive: theme.palette.extendedGrey?.lightest,
      finishing: theme.palette.extendedGrey?.lightest,
    };
    return colorMap?.[status!] || theme.palette.extendedGrey?.lightest;
  };

  const statusBadgeText = (status?: CampaignRunStatusType) => {
    const colorMap = {
      active: theme.palette.success.dark,
      inactive: theme.palette.extendedGrey?.dark,
      finishing: theme.palette.extendedGrey?.dark,
    };

    return colorMap?.[status!] || theme.palette.text.secondary;
  };

  const handleManagedStartStop = async (id: string, campaignStatus: CampaignStatus) => {
    if (CampaignStatus.NotStarted === campaignStatus) {
      const campaign = campaigns.find((campaign) => campaign.id === id);
      const schedule = campaign?.callSchedule;
      const canRun = schedule && !isOutOfSchedule(schedule);
      if (canRun) {
        await useRunCampaign({ id });
      } else {
        const nextAvailableCallTimestamp = getNextAvailableCallTimestamp(schedule);
        setCampaignToRun(campaign);
        if (nextAvailableCallTimestamp) {
          setTimeToStart(nextAvailableCallTimestamp);
          setShowScheduleWarningDialogFlag(true);
        }
      }
    } else if (CampaignStatus.Dialing === campaignStatus) {
      await useStopCampaign({ id });
    }
  };

  const handleDeleteBtn = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setShowDialog(true);

    if (selectedRowId) {
      setDeleteId(selectedRowId);
    }

    setAnchorEl(null);
  };

  const deleteCampaign = async () => {
    if (deleteId) {
      setShowDialog(false);
      setLoading(true);
      const response = await useDeleteCampaign({
        id: deleteId,
      });

      if ('error' in response) {
        const errorText = (response.error as { data: { message: string } }).data.message;

        onCampaignDeleteError({
          message: errorText,
          type: 'error',
        });
      } else {
        onCampaignDeleteError({
          message: t('adminMainPage.campaigns.successfullyDeleted'),
          type: 'success',
        });
      }
    }
    refetchCampaignsList();
    setDeleteId('');
    setLoading(false);
  };

  const handleMoreBtn = (event: any, rowId: string) => {
    setAnchorEl(event.currentTarget);

    setSelectedRowId(rowId);
  };

  const handlePopoverAction = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (selectedRowId) {
      handleRowClick(selectedRowId);
    }
    handleClosePopover();
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleRowClick = (rowId: string) => {
    navigator(`/admin/campaign/${rowId}/edit`);
  };

  const runCampaign = async () => {
    setShowScheduleWarningDialogFlag(false);

    if (campaignToRun?.id) {
      await useRunCampaign({ id: campaignToRun?.id });
    }
  };

  if (responseLoadingFlag) {
    return <Spinner />;
  }

  return (
    <BlankCard className={classes.campaign}>
      <Box className={classes.campaign_header}>
        <Box
          className={classes.campaign_header_title}
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}>
          <Typography fontSize="18px">{t('adminMainPage.campaigns.title')}</Typography>
          <Typography fontSize="18px" color={theme.palette.text.secondary}>
            ({campaigns.length})
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignContent: 'start', alignItems: 'start' }}>
          <Tooltip title={t('adminMainPage.operation.addCampaign')} arrow>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ backgroundColor: theme.palette.primary.main }}
              onClick={() => {
                setShowNewCampaignDialog(true);
              }}>
              {t('adminMainPage.operation.addCampaign')}
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <TableContainer>
        <Table className={classes.campaign_table}>
          <TableHead
            sx={{
              backgroundColor: theme.palette.extendedGrey?.lighter,
              height: '48px',
              borderTop: `1px solid ${theme.palette.divider}`,
            }}>
            <TableRow>
              <TableCell onClick={() => handleSort('type')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography
                    color={theme.palette.text.secondary}
                    variant="h6"
                    className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.type')}
                  </Typography>
                  {sortConfig.status !== undefined ? (
                    sortConfig.status ? (
                      <IconArrowDown size="12" />
                    ) : (
                      <IconArrowUp size="12" />
                    )
                  ) : null}
                </Box>
              </TableCell>

              <TableCell
                onClick={() => handleSort('title')}
                className={classes.campaign_table_first_cell}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography variant="h6" className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.campaignName')}
                  </Typography>
                  {sortConfig.title !== undefined ? (
                    sortConfig.title ? (
                      <IconArrowDown size="12" />
                    ) : (
                      <IconArrowUp size="12" />
                    )
                  ) : null}
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('status')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography
                    color={theme.palette.text.secondary}
                    variant="h6"
                    className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.status')}
                  </Typography>
                  {sortConfig.status !== undefined ? (
                    sortConfig.status ? (
                      <IconArrowDown size="12" />
                    ) : (
                      <IconArrowUp size="12" />
                    )
                  ) : null}
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('totalLeads')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography
                    color={theme.palette.text.secondary}
                    variant="h6"
                    className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.totalNumber')}
                  </Typography>
                  {sortConfig.totalLeads !== undefined ? (
                    sortConfig.totalLeads ? (
                      <IconArrowDown size="12" />
                    ) : (
                      <IconArrowUp size="12" />
                    )
                  ) : null}
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('processedNumbers')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography
                    color={theme.palette.text.secondary}
                    variant="h6"
                    className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.processedNumber')}
                  </Typography>
                  {sortConfig.processedNumbers !== undefined ? (
                    sortConfig.processedNumbers ? (
                      <IconArrowDown size="12" />
                    ) : (
                      <IconArrowUp size="12" />
                    )
                  ) : null}
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('pendingLeadsCount')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography
                    color={theme.palette.text.secondary}
                    variant="h6"
                    className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.ongoingCalls')}
                  </Typography>
                  {sortConfig.pendingLeadsCount !== undefined ? (
                    sortConfig.pendingLeadsCount ? (
                      <IconArrowDown size="12" />
                    ) : (
                      <IconArrowUp size="12" />
                    )
                  ) : null}
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('reachedLeadsCount')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography
                    variant="h6"
                    color={theme.palette.text.secondary}
                    className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.reachedCall')}
                  </Typography>
                  {sortConfig.reachedLeadsCount !== undefined ? (
                    sortConfig.reachedLeadsCount ? (
                      <IconArrowDown size="12" />
                    ) : (
                      <IconArrowUp size="12" />
                    )
                  ) : null}
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('unreachedLeadsCount')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography
                    color={theme.palette.text.secondary}
                    variant="h6"
                    className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.unreachedCall')}
                  </Typography>
                  {sortConfig.unreachedLeadsCount !== undefined ? (
                    sortConfig.unreachedLeadsCount ? (
                      <IconArrowDown size="12" />
                    ) : (
                      <IconArrowUp size="12" />
                    )
                  ) : null}
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('plannedRecallCount')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography
                    color={theme.palette.text.secondary}
                    variant="h6"
                    className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.plannedRetries')}
                  </Typography>
                  {sortConfig.plannedRecallCount !== undefined ? (
                    sortConfig.plannedRecallCount ? (
                      <IconArrowDown size="12" />
                    ) : (
                      <IconArrowUp size="12" />
                    )
                  ) : null}
                </Box>
              </TableCell>

              <TableCell>
                <Typography
                  color={theme.palette.text.secondary}
                  variant="h6"
                  className={classes.campagin_table_th}
                  align="center">
                  {t('adminMainPage.operation.startStop')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.campagin_table_th} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campaigns.map((row) => (
              <TableRow
                className={classes.campaign_table_row}
                key={row.id}
                hover
                onClick={(e) => {
                  e.stopPropagation();
                  handleRowClick(row.id);
                }}>
                <TableCell>
                  {row.type === CampaignTypeEnum.OUTBOUND ? (
                    <IconCircleArrowLeft />
                  ) : (
                    <IconCircleArrowRight />
                  )}
                </TableCell>

                <TableCell className={classes.campaign_table_first_cell}>
                  <Typography variant="subtitle1">{row.title}</Typography>
                </TableCell>
                <TableCell>
                  <Chip
                    sx={{
                      backgroundColor: statusBadge(row.campaignRun?.status),
                      color: statusBadgeText(row.campaignRun?.status),
                    }}
                    size="small"
                    label={capitalize(row.campaignRun?.status || 'inactive')}
                  />
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{row.totalLeads}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">
                    {row.unreachedLeadsCount + row.reachedLeadsCount}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{row.pendingLeadsCount}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{row.reachedLeadsCount}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{row.unreachedLeadsCount}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{row.plannedRecallCount}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Tooltip title="Status Campaign">
                    <CampaignStatusButton
                      onClick={handleManagedStartStop}
                      disabled={isRunningLoading || isStoppingLoading || !row.leadList?.length}
                      campaign={row}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <Tooltip title="More actions">
                    <IconButton
                      aria-describedby={row.id.toString()}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleMoreBtn(event, row.id);
                      }}>
                      <MoreVertIcon sx={{ color: theme.palette.text.secondary }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                {selectedRowId === row.id && (
                  <Popover
                    id={row.id.toString()}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}>
                    <Box className={classes.agent_table_popover}>
                      <IconButton
                        className={classes.agent_table_popover_button}
                        onClick={handlePopoverAction}>
                        <IconExternalLink size="24px" />
                        <Typography>{t('adminMainPage.operation.open')}</Typography>
                      </IconButton>
                      <IconButton
                        className={classes.agent_table_popover_button}
                        onClick={handleDeleteBtn}>
                        <IconTrash size="24px" color="#FA896B" />
                        <Typography color="#FA896B">
                          {t('adminMainPage.operation.delete')}
                        </Typography>
                      </IconButton>
                    </Box>
                  </Popover>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <NewCampaignDialog
        showDialogFlag={showNewCampaignDialog}
        handleClose={setShowNewCampaignDialog}
        showDialog={setShowNewCampaignDialog}
      />
      <ConfirmDeleteDialog
        open={showDialog}
        onClose={setShowDialog}
        dialogTitle={'dialog.title3'}
        dialogContentText={'dialog.content3'}
        showDialog={setShowDialog}
        confirmDelete={deleteCampaign}
      />
      <ConfirmDialog
        showDialogFlag={showScheduleWarningDilaogFlag}
        handleClose={setShowScheduleWarningDialogFlag}
        dialogTitle={'dialog.title4'}
        dialogContentText={t('dialog.content4', {
          timeToStart: formatTime((timeToStart - new Date().getTime()) / 1000, false),
        })}
        showDialog={setShowScheduleWarningDialogFlag}
        confirmDialog={runCampaign}
      />
    </BlankCard>
  );
};
