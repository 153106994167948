import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/system';

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 167,
    fontSize: '12px',
    lineHeight: '16.8px',
    padding: '6px 12px',
    backgroundColor: theme.palette.extendedGrey.darker,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.extendedGrey.darker,
  },
}));
