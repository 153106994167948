import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  IconEdit,
  IconInfo,
  iconOngoingCalls,
  IconSubMenuPath1,
  IconSubMenuPath2,
  IconSubMenuPath3,
  IconSubMenuPath4,
} from '@shared/assets/images/svgs';
import { useTheme } from '@mui/material';
import { SidebarTabNames } from '../constants';
import { useNavigate } from 'react-router-dom';
import { SidebarTabNamesType } from '../types';
import { t } from 'i18next';
import { CustomTooltip } from '@shared/components/customTooltip';
import { CampaignType } from '@shared/services/apiService/apiService';

interface MenuItem {
  title: string;
  icon?: React.FunctionComponent;
  subMenuItems?: MenuItem[];
}

interface IconPathProps {
  children?: React.ReactNode;
  isActive: boolean;
}

interface EditCampaignSidebarProps {
  activeTab: SidebarTabNamesType;
  campaignType?: CampaignType;
  issuedTabs: SidebarTabNamesType[];
}

const menuItems: MenuItem[] = [
  {
    title: SidebarTabNames.EDIT_CAMPAIGN,
    icon: IconEdit,
    subMenuItems: [
      { title: SidebarTabNames.GENERAL },
      { title: SidebarTabNames.AGENT },
      { title: SidebarTabNames.PHONE_NUMBERS },
      { title: SidebarTabNames.END_CALL },
    ],
  },
  {
    title: SidebarTabNames.ONGOING_CALLS,
    icon: iconOngoingCalls,
  },
];

const IconPath = (props: IconPathProps) => {
  const { children, isActive, ...other } = props;

  return (
    <Box
      sx={{
        '> svg': {
          position: 'absolute',
          zIndex: +isActive,
          '> path': {
            stroke: isActive ? '#1875FF' : '#DFE5EF',
          },
        },
      }}
      {...other}>
      {children}
    </Box>
  );
};

const EditCampaignSidebar: React.FC<EditCampaignSidebarProps> = ({
  activeTab,
  issuedTabs,
  campaignType,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const findIndexes = (
    activeTab: string,
  ): {
    initMenuIndex: number;
    initSubmenuIndex: number;
  } => {
    for (let i = 0; i < menuItems.length; i++) {
      if (menuItems[i].title === activeTab) {
        return { initMenuIndex: i, initSubmenuIndex: -1 };
      }

      if (menuItems[i].subMenuItems) {
        const subIndex = menuItems[i].subMenuItems!.findIndex((sub) => sub.title === activeTab);
        if (subIndex !== -1) {
          return { initMenuIndex: i, initSubmenuIndex: subIndex };
        }
      }
    }
    return { initMenuIndex: 0, initSubmenuIndex: 0 };
  };

  const { initMenuIndex, initSubmenuIndex } = findIndexes(activeTab);

  const [activeMenuIndex, setActiveMenuIndex] = useState<number>(0);
  const [activeSubmenuIndex, setActiveSubmenuIndex] = useState<number>(0);

  useEffect(() => {
    setActiveMenuIndex(initMenuIndex);
    setActiveSubmenuIndex(initSubmenuIndex);
  }, [initMenuIndex, initSubmenuIndex]);

  const navigateToTab = (tabName: string) =>
    navigate(`${location.pathname}?tab=${encodeURIComponent(tabName)}`);

  const onMenuItemSelect = (index: number) => {
    setActiveMenuIndex(index);

    const menuItem = menuItems[index];

    if (menuItem.subMenuItems) {
      setActiveSubmenuIndex(0);
      navigateToTab(menuItem.subMenuItems[0].title);
    } else {
      setActiveSubmenuIndex(-1);
      navigateToTab(menuItem.title);
    }
  };

  const onSubMenuItemSelect = (index: number, parentIndex?: number) => {
    setActiveSubmenuIndex(index);

    if (parentIndex !== undefined) {
      setActiveMenuIndex(parentIndex);

      const submenu = menuItems[parentIndex].subMenuItems;
      if (submenu && submenu[index]) {
        navigateToTab(submenu[index].title);
      } else {
        navigateToTab(menuItems[parentIndex].title);
      }
    }
  };

  const getMenuItemColor = (index: number) => {
    return index === activeMenuIndex
      ? theme.palette.primary.main
      : theme.palette.extendedGrey?.neutral;
  };

  const filteredMenuItems = menuItems.map((item) => {
    if (
      item.title === SidebarTabNames.EDIT_CAMPAIGN &&
      campaignType === 'inbound' &&
      item.subMenuItems
    ) {
      return {
        ...item,
        subMenuItems: item.subMenuItems.filter(
          (subItem) => subItem.title !== SidebarTabNames.PHONE_NUMBERS,
        ),
      };
    }
    return item;
  });

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: 200,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            position: 'relative',
            zIndex: 0,
          },
        }}
        variant="permanent"
        anchor="left">
        <List>
          {filteredMenuItems.map((item, menu_index) => (
            <React.Fragment key={'menu-' + menu_index}>
              <ListItem
                sx={{
                  paddingBottom: 0,
                  paddingLeft: 0,
                }}>
                <ListItemButton
                  onClick={() => onMenuItemSelect(menu_index)}
                  sx={{ paddingLeft: '6px' }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 'unset',
                      '> svg > path': {
                        stroke: getMenuItemColor(menu_index),
                      },
                    }}>
                    {item.icon ? <item.icon /> : null}
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      paddingLeft: '16px',
                      color: getMenuItemColor(menu_index),
                    }}
                    primary={t(`campaignPage.editCampaign.${item.title}`)}
                  />
                </ListItemButton>
              </ListItem>
              {item.subMenuItems ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '18px' }}>
                  <Box>
                    <IconPath isActive={activeSubmenuIndex === 0}>
                      <IconSubMenuPath1 />
                    </IconPath>
                    <IconPath isActive={activeSubmenuIndex === 1}>
                      <IconSubMenuPath2 />
                    </IconPath>
                    <IconPath isActive={activeSubmenuIndex === 2}>
                      <IconSubMenuPath3 />
                    </IconPath>
                    <IconPath isActive={activeSubmenuIndex === 3}>
                      <IconSubMenuPath4 />
                    </IconPath>
                  </Box>
                  {item.subMenuItems.map((subItem, sub_menu_index) => (
                    <List key={'sub-menu' + sub_menu_index} sx={{ padding: '0' }}>
                      <ListItem key={subItem.title} sx={{ padding: '0 16px' }}>
                        <ListItemButton
                          sx={{ padding: '0 16px' }}
                          onClick={() => onSubMenuItemSelect(sub_menu_index, menu_index)}>
                          <ListItemText
                            sx={{
                              color:
                                sub_menu_index === activeSubmenuIndex
                                  ? theme.palette.primary.main
                                  : theme.palette.extendedGrey?.neutral,
                            }}
                            primary={t(`campaignPage.editCampaign.${subItem.title}`)}
                          />
                          {issuedTabs.includes(subItem.title) && (
                            <CustomTooltip
                              title={t('adminMainPage.errors.requiredFieldsNotFilled')}
                              placement="top"
                              arrow>
                              <IconInfo />
                            </CustomTooltip>
                          )}
                        </ListItemButton>
                      </ListItem>
                    </List>
                  ))}
                </Box>
              ) : null}
            </React.Fragment>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default EditCampaignSidebar;
