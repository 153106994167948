import React from 'react';
import { Typography, IconButton, Stack, Box, styled, Button } from '@mui/material';
import { CallScheduleDto, CampaignDto } from '@shared/services/apiService/apiService';
import { IconActionPlay, IconActionStop } from '@shared/assets/images/svgs';
import { getCampaignStatus } from './utils';
import i18n from '@shared/utils/i18n';

export enum CampaignStatus {
  NotStarted = 'not-started',
  Dialing = 'dialing',
  Stopping = 'stopping',
}

interface CampaignStatusButtonProps {
  campaign?: CampaignDto | null;
  disabled?: boolean;
  onClick?: (
    id: string,
    callSchedule: CallScheduleDto | undefined,
    campaignStatus: CampaignStatus,
  ) => void;
  minimized?: boolean;
}

const DEFAULT_STATUS_MAPPINGS: Record<string, string> = {
  stopText: i18n.t('adminMainPage.operation.stop'),
  startText: i18n.t('adminMainPage.operation.start'),
  stopping: i18n.t('adminMainPage.operation.stopping'),
};

const StyledIconBox = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
  boxShadow: `0px 10px 20px ${theme.palette.extendedGrey?.neutral}1A`,
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.extendedGrey?.lighter}`,
  width: 30,
  height: 30,
  '&:hover': {
    backgroundColor: theme.palette.extendedGrey?.lightest,
  },
}));

const StyledIconButton = styled(Button)(({ theme }) => ({
  textWrap: 'nowrap',
  '& path, & rect': {
    fill: theme.palette.primary.main,
  },
  '&:hover': {
    '& path, & rect': {
      fill: 'white',
    },
  },
  '&[aria-disabled="true"]': {
    cursor: 'default',
    borderColor: theme.palette.extendedGrey?.neutral,
    color: theme.palette.extendedGrey?.neutral,
    '& path, & rect': {
      fill: theme.palette.extendedGrey?.neutral,
    },
    '&:hover': {
      backgroundColor: 'white',
      '& path, & rect': {
        fill: theme.palette.extendedGrey?.neutral,
      },
    },
  },
}));

const getCampaignStatusFragment = (
  campaignStatus: CampaignStatus,
  minimized: boolean = true,
  disabled: boolean = false,
) => {
  const { startText, stopText, stoppingText } = DEFAULT_STATUS_MAPPINGS;

  if (campaignStatus === CampaignStatus.Dialing) {
    if (minimized) {
      return (
        <StyledIconBox aria-disabled={disabled}>
          <IconActionStop />
        </StyledIconBox>
      );
    }
    return (
      <StyledIconButton aria-disabled={disabled} variant="outlined" startIcon={<IconActionStop />}>
        {stopText}
      </StyledIconButton>
    );
  } else if (campaignStatus === CampaignStatus.NotStarted) {
    if (minimized) {
      return (
        <StyledIconBox>
          <IconActionStop />
        </StyledIconBox>
      );
    }
    return (
      <StyledIconButton aria-disabled={disabled} variant="outlined" startIcon={<IconActionPlay />}>
        {startText}
      </StyledIconButton>
    );
  } else if (campaignStatus === CampaignStatus.Stopping) {
    return <Typography fontWeight="bold">{stoppingText}</Typography>;
  }
};

const CampaignStatusButtonV2: React.FC<CampaignStatusButtonProps> = ({
  campaign,
  disabled,
  onClick,
  minimized,
}) => {
  const campaignStatus = getCampaignStatus(campaign?.campaignRun);
  const handleOnClick = (e: React.MouseEvent) => {
    if (!disabled) {
      e.stopPropagation();
      onClick?.(campaign?.id as string, campaign?.callSchedule, campaignStatus);
    }
  };
  if (!minimized) {
    return (
      <Box onClick={(e) => handleOnClick(e)}>
        <Stack alignItems="center" direction="row">
          {getCampaignStatusFragment(campaignStatus, minimized, disabled)}
        </Stack>
      </Box>
    );
  }
  return (
    <IconButton size="small" disabled={disabled} onClick={(e) => handleOnClick(e)}>
      <Stack alignItems="center" direction="row" gap={1}>
        {getCampaignStatusFragment(campaignStatus, minimized, disabled)}
      </Stack>
    </IconButton>
  );
};

export default CampaignStatusButtonV2;
