import { CreateCampaignFormValue } from '@features/Admin/Campaign/model/type';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { CampaignTextField } from '@shared/components/campaignTextField/campaignTextField';
import { t } from 'i18next';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface EndCallTabProps {
  campaignName: string;
  agentName: string;
  companyName: string;
  defaultFollowUpTemplate: string | null | undefined;
}

const EndCallTab: React.FC<EndCallTabProps> = ({
  defaultFollowUpTemplate,
  campaignName,
  agentName,
  companyName,
}: EndCallTabProps) => {
  const [templateTextFieldValue, setTemplateTextFieldValue] = useState(
    defaultFollowUpTemplate || '',
  );
  const [requireTemplateTextField, setRequireTemplateTextField] = useState(false);
  let mastecheValues = {
    agentName,
    templateTextFieldValue,
    campaignName,
    companyName: companyName,
  };
  const { control } = useFormContext<CreateCampaignFormValue>();

  return (
    <Box
      sx={{
        padding: '30px',
        width: '100%',
      }}>
      <Box
        gap={'20px'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Typography fontSize="18px">{t('campaignPage.endCall')}</Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Controller
                name={'sendFollowUpMessage'}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={Boolean(field.value)}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      setRequireTemplateTextField(e.target.checked);
                    }}
                  />
                )}
              />
            }
            label={t('campaignPage.whatsAppMessageCheckbox')}
          />
        </FormGroup>
      </Box>
      <Box>
        <CampaignTextField
          requireTemplateTextField={requireTemplateTextField}
          mastecheValues={mastecheValues}
          setTemplateTextFieldValue={setTemplateTextFieldValue}
        />
      </Box>
    </Box>
  );
};

export default EndCallTab;
