import { tss } from 'tss-react/mui';

export const useStyles = tss.create(() => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    letterSpacing: '-2%',
  },
}));
