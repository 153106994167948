import {
  CampaignWithDetailedInfoDto,
  useGetAllCampaignsControllerGetAllCampaignsQuery,
} from '@shared/services/apiService/apiService';
import { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';

const WS_URL = process.env.REACT_APP_API_URL;

interface WsException {
  status: string;
  message: string;
}

export const useWebSocketGetCampaigns = (onError?: <T>(error: T) => void) => {
  const [isConnected, setIsConnected] = useState(false);
  const [campaigns, setCampaigns] = useState<CampaignWithDetailedInfoDto[]>([]);
  const [socket, setSocket] = useState<Socket | null>(null);

  const subscribeForCampaignsList = (companyId: string) => {
    socket?.emit('subscribe_to_get_campaigns_list', {
      companyId,
    });
  };

  const {
    data: campaignList,
    isLoading,
    isSuccess,
    refetch: refetchCampaignsList,
    error,
  } = useGetAllCampaignsControllerGetAllCampaignsQuery();

  useEffect(() => {
    if (campaignList) {
      setCampaigns(campaignList);
      subscribeForCampaignsList(campaignList[0]?.companyId);
    }
  }, [campaignList]);

  useEffect(() => {
    const token = localStorage.getItem('accessToken')!;
    refetchCampaignsList();
    const newSocket = io(`${WS_URL}/campaigns`, {
      transports: ['websocket'],
      autoConnect: true,
      auth: { token },
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
      setSocket(null);
    };
  }, []);

  useEffect(() => {
    if (!socket) return;

    const onConnect = () => {
      setIsConnected(true);
    };
    const onDisconnect = () => setIsConnected(false);

    const onGetCampaignsList = (value: { result: CampaignWithDetailedInfoDto }) => {
      setCampaigns((prev) => {
        const index = prev.findIndex((campaign) => campaign.id === value.result.id);
        if (index === -1) return [...prev, value.result];
        const updatedList = [...prev];
        updatedList[index] = value.result;
        return updatedList;
      });
    };

    const onException = (error: WsException) => {
      socket.disconnect();
      console.error(error);
      onError?.(error);
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('get_campaigns_list', onGetCampaignsList);
    socket.on('exception', onException);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('get_campaigns_list', onGetCampaignsList);
      socket.off('exception', onException);
    };
  }, [socket]);

  useEffect(() => {
    if (error && onError) {
      onError(error);
    }
  }, [isConnected, onError]);

  return {
    isConnected,
    isLoading,
    isSuccess,
    data: campaigns,
    refetch: refetchCampaignsList,
  };
};
