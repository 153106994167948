import {
  CampaignLeadListDto,
  useCampaignUpdateControllerCampaignUpdateMutation,
  useGetAllCampaignsControllerGetAllCampaignsQuery,
} from '@shared/services/apiService/apiService';
import { ResponseMsg } from '@shared/types/admin/agent';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { CreateCampaignFormValue } from '../model/type';
import { extractLeadErrors } from '../utils/extract-lead-errors';
import { CampaignFieldsError } from '@shared/types/admin/campaign';
import { removeDuplicatePhones } from '../utils/remove-duplicates-phone';
import { useTranslation } from 'react-i18next';
import { campaignRequestMapper } from '../utils/campaign-mapper';

interface UseEditCampaignProps {
  setCampaignFieldError: React.Dispatch<React.SetStateAction<CampaignFieldsError>>;
  leadList: CampaignLeadListDto[];
  leadListChanged: React.MutableRefObject<boolean>;
}

export const useEditCampaign = ({
  setCampaignFieldError,
  leadList: previousLeadList,
  leadListChanged,
}: UseEditCampaignProps) => {
  const [editCampaign] = useCampaignUpdateControllerCampaignUpdateMutation();
  const [responseMsg, setResponseMsg] = useState<ResponseMsg>({ message: '', type: 'success' });
  const [responseLoadingFlag, setResponseLoadingFlag] = useState(false);
  const { t } = useTranslation();
  const { refetch } = useGetAllCampaignsControllerGetAllCampaignsQuery();

  const formMethods = useForm<CreateCampaignFormValue>({
    mode: 'all',
    defaultValues: {
      id: '',
      title: '',
    },
  });

  const handleResponseMessage = (message: string, type: 'success' | 'error') => {
    setResponseMsg({ message, type });
  };

  const processLeadList = (data: CreateCampaignFormValue) => {
    const leadList = leadListChanged.current ? data.leadList.filter((item) => !item.id) : [];
    const previousPhones = previousLeadList.flatMap((list) => list.leads).map((lead) => lead.phone);
    const newLeads = leadList.map((list) => ({
      ...list,
      leads: list.leads.filter((lead) => !previousPhones.includes(lead.phone)),
    }));

    const uniqueLeads = removeDuplicatePhones(newLeads.filter((list) => list.leads.length > 0));

    const filesToRemove = newLeads
      .filter(
        (list) =>
          list.leads.length === 0 &&
          !previousLeadList.some((prevList) => prevList.name === list.name),
      )
      .map((list) => list.name);

    if (!uniqueLeads.length && filesToRemove.length && previousLeadList.length) {
      setCampaignFieldError((prevState) => ({
        ...prevState,
        leadErrors: t('adminMainPage.campaigns.leadsExistFilesError', {
          files: filesToRemove.join(', '),
        }),
      }));
      return undefined;
    }

    return uniqueLeads.length || leadListChanged.current ? uniqueLeads : previousLeadList;
  };

  const onSubmit = formMethods.handleSubmit(
    async (data) => {
      setResponseLoadingFlag(true);
      setResponseMsg({ message: '', type: 'success' });

      try {
        const leadList = leadListChanged.current ? processLeadList(data) : undefined;
        const campaignUpdateRequestDto = campaignRequestMapper(data, leadList);

        await editCampaign({ campaignUpdateRequestDto, id: data.id }).unwrap();
        await refetch();
        handleResponseMessage('adminMainPage.campaigns.successfullyEdited', 'success');
      } catch (error) {
        const leadErrors = extractLeadErrors((error as any)?.data || {});

        setCampaignFieldError((prevState) => ({
          ...prevState,
          editErrorMessage: (error as any).error,
          leadErrors: (error as any).status === 400 ? leadErrors : prevState.leadErrors,
        }));
        handleResponseMessage('adminMainPage.campaigns.somethingWentWrong', 'error');
      } finally {
        setResponseLoadingFlag(false);
      }
    },
    // tweak to store data even if we have some invalid data
    async () => {
      const data = formMethods.getValues();
      const leadList = leadListChanged.current ? processLeadList(data) : undefined;
      const campaignUpdateRequestDto = campaignRequestMapper(data, leadList);

      await editCampaign({ campaignUpdateRequestDto, id: data.id }).unwrap();
    },
  );

  return {
    onSubmit,
    setResponseMsg,
    responseMsg,
    responseLoadingFlag,
    formMethods,
  };
};
