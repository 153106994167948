import { Button, Container, Grid, Typography, Card, CardContent, CardActions } from '@mui/material';
import { SuperAdminAuthenticatedPath } from '@shared/constants/routes.constants';
import React from 'react';
import { Link } from 'react-router-dom';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(({ theme }) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
  },
  header: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  card: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: theme.shadows[3],
  },
  button: {
    marginTop: theme.spacing(2),
  },
  cardActions: {
    justifyContent: 'center',
  },
}));

export const HomePage: React.FC = () => {
  const { classes } = useStyles();

  const formatRouteName = (name: string) => {
    return name
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <Container className={classes.root}>
      <Typography variant="h3" component="h1" className={classes.header}>
        Welcome to the Super Admin Home Page
      </Typography>
      <Grid container spacing={3}>
        {Object.entries(SuperAdminAuthenticatedPath)
          .filter(([name]) => name !== 'EDIT_COMPANY')
          .map(([name, path]) => (
            <Grid item xs={12} sm={6} md={4} key={name}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography variant="h6">{formatRouteName(name)}</Typography>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Button variant="contained" color="primary" component={Link} to={path}>
                    Go to {formatRouteName(name)}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
};
